// material
import { Container } from '@mui/material';
// components
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import MessageTemplateForm from './components/MessageTemplateForm';

// ----------------------------------------------------------------------

export default function MessageTemplateCreatePage() {
  return (
    <Page title="Create email template">
      <Container>
        <HeaderBreadcrumbs
          heading="Create email template"
          links={[
            { name: 'Email templates', href: PATH_DASHBOARD.messageTemplate.list },
            { name: 'Create email template' }
          ]}
        />
        <MessageTemplateForm />
      </Container>
    </Page>
  );
}
