// material
import {
  Alert,
  Button,
  Card,
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import { Icon } from '@iconify/react';
import plusFill from '@iconify-icons/eva/plus-fill';
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { Link as RouterLink } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { useCallback, useEffect, useState } from 'react';
import { IUserProfileList } from 'src/interfaces/user-profile.interface';
import Scrollbar from 'src/components/Scrollbar';
import UserProfileListHead from './components/UserProfileListHead';
import editFill from '@iconify-icons/eva/edit-fill';
import { customLabelDisplayedRows } from 'src/utils/common';
import UserProfileService from 'src/services/user-profile.service';
import { Link } from 'react-router-dom';
import { getErrorMessage } from 'src/utils/request';
import LoadingData from 'src/components/LoadingData';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'options', label: 'Settings', alignRight: true },
];

// ----------------------------------------------------------------------

export default function UserProfilePage() {
  const [userProfiles, setUserProfiles] = useState<IUserProfileList>();
  const [loadingUserProfiles, setLoadingUserProfiles] = useState<boolean>(true);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState(0);
  const [requestError, setRequestError] = useState<string>();

  const loadProfiles = useCallback(() => {
    setLoadingUserProfiles(true);
    UserProfileService.getUserProfiles(page, rowsPerPage)
      .then(({ data }) => {
        setUserProfiles(data);
      })
      .catch((error) => {
        setRequestError(
          getErrorMessage(
            'There was a problem loading the user profile list. Please notify an administrator',
            error
          )
        );
      })
      .finally(() => setLoadingUserProfiles(false));
  }, [page, rowsPerPage]);

  useEffect(() => {
    loadProfiles();
  }, [loadProfiles]);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Page title="User profiles">
      <Container>
        <HeaderBreadcrumbs
          heading="User profiles"
          links={[]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.userProfile.create}
              startIcon={<Icon icon={plusFill} />}
            >
              New user profile
            </Button>
          }
        />
        {requestError && (
          <Alert style={{ marginBottom: '1em' }} severity="error">
            {requestError}
          </Alert>
        )}
        <LoadingData show={loadingUserProfiles} />
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table style={{ marginTop: 15 }}>
                <UserProfileListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {userProfiles?.data.map((row) => {
                    const { id, name } = row;
                    return (
                      <TableRow hover key={id}>
                        <TableCell align="left">{name}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            title="Editar"
                            component={Link}
                            to={`${PATH_DASHBOARD.userProfile.root}/edit/${id}`}
                          >
                            <Icon icon={editFill} width={18} height={18} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {userProfiles?.total === 0 && (
                    <TableRow style={{ height: 53 }}>
                      <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                        We couldn't find any user profile. Please create a new one using the "New
                        user profile" button.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            labelDisplayedRows={customLabelDisplayedRows}
            labelRowsPerPage="Profiles per page:"
            rowsPerPageOptions={[10, 50, 100]}
            component="div"
            count={userProfiles?.total || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
