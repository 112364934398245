import {
  Alert,
  Button,
  Card,
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import { Icon } from '@iconify/react';
import plusFill from '@iconify-icons/eva/plus-fill';
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { PATH_DASHBOARD } from '../../../routes/paths';
import { useCallback, useEffect, useState } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { customLabelDisplayedRows } from 'src/utils/common';
import { getErrorMessage } from 'src/utils/request';
import SectionService from 'src/services/section.service';
import ListHead from './components/ListHead';
import { useSnackbar } from 'notistack';
import DeleteElementModal from 'src/components/DeleteElementModal';
import LoadingData from 'src/components/LoadingData';
import editOutline from '@iconify-icons/eva/edit-outline';
import bookOpenOutline from '@iconify-icons/eva/book-open-outline';
import { ISection, ISectionList } from 'src/interfaces/course.interface';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'sectionNumber', label: 'Number', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'paymentRequired', label: 'Payment required', alignRight: false },
  { id: 'options', label: 'Settings', alignRight: true },
];

// ----------------------------------------------------------------------

export default function SectionListPage() {
  const { enqueueSnackbar } = useSnackbar();
  const { projectId, chapterId } = useParams();
  const [sections, setSections] = useState<ISectionList>();
  const [loadingSections, setLoadingSections] = useState<boolean>(true);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState(0);
  const [requestError, setRequestError] = useState<string>();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);
  const [selectedSection, setSelectedSection] = useState<ISection>();
  const [deletingSection, setDeletingSection] = useState<boolean>(false);

  const loadSections = useCallback(() => {
    setLoadingSections(true);
    SectionService.getSections(page, rowsPerPage, chapterId || '')
      .then(({ data }) => {
        setSections(data);
      })
      .catch((error) => {
        setRequestError(
          getErrorMessage(
            'There was a problem while loading the sections. Please notify an administrator.',
            error
          )
        );
      })
      .finally(() => setLoadingSections(false));
  }, [page, rowsPerPage]);

  useEffect(() => {
    loadSections();
  }, [loadSections]);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDeleteConfirmationModal = (section: ISection) => {
    setSelectedSection(section);
    setOpenDeleteConfirmation(true);
  };

  const handleCloseDeleteConfirmationModal = () => {
    setSelectedSection(undefined);
    setOpenDeleteConfirmation(false);
  };

  const deleteSection = () => {
    if (!selectedSection) return;
    setDeletingSection(true);
    SectionService.deleteSection(selectedSection.id || '')
      .then(() => {
        enqueueSnackbar('The section has been deleted succesfully.', {
          variant: 'success',
        });
        if (!sections) return;
        const currentList = { ...sections };
        const updatedList = currentList.data.filter((x) => x.id !== selectedSection.id);
        currentList.data = updatedList;
        setSections(currentList);
        handleCloseDeleteConfirmationModal();
      })
      .catch((error) => {
        enqueueSnackbar('It was not possible to delete the section.', { variant: 'error' });
      })
      .finally(() => setDeletingSection(false));
  };

  return (
    <Page title="Sections">
      <Container>
        <HeaderBreadcrumbs
          heading="Sections"
          links={[
            { name: 'Courses', href: PATH_DASHBOARD.course.list },
            { name: 'Chapters', href: `${PATH_DASHBOARD.course.root}/${projectId}/chapters` },
            { name: 'Sections' },
          ]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={`${PATH_DASHBOARD.course.root}/${projectId}/chapters/${chapterId}/sections/create?lastSectionNumber=${sections?.lastPageNumber}`}
              startIcon={<Icon icon={plusFill} />}
            >
              New section
            </Button>
          }
        />
        {requestError && (
          <Alert style={{ marginBottom: '1em' }} severity="error">
            {requestError}
          </Alert>
        )}
        <LoadingData show={loadingSections} />
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table style={{ marginTop: 15 }}>
                <ListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {sections?.data.map((row) => {
                    const { id, sectionNumber, paymentRequired, sectionName } = row;
                    return (
                      <TableRow hover key={id}>
                        <TableCell align="left">{sectionNumber}</TableCell>
                        <TableCell align="left">{sectionName}</TableCell>
                        <TableCell align="left">{paymentRequired ? 'Yes' : 'No'}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            title="Edit section"
                            href={`${PATH_DASHBOARD.course.root}/${projectId}/chapters/${chapterId}/sections/edit/${id}`}
                          >
                            <Icon icon={editOutline} width={24} height={24} />
                          </IconButton>
                          <IconButton
                            title="View pages"
                            href={`${PATH_DASHBOARD.course.root}/${projectId}/chapters/${chapterId}/sections/${id}/pages`}
                          >
                            <Icon icon={bookOpenOutline} width={24} height={24} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {sections?.data.length === 0 && (
                    <TableRow style={{ height: 53 }}>
                      <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                        We couldn't find any section. Please create a new one using the "New
                        section" button.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            labelDisplayedRows={customLabelDisplayedRows}
            labelRowsPerPage="Sections per page:"
            rowsPerPageOptions={[10, 50, 100]}
            component="div"
            count={sections?.total || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <DeleteElementModal
        openDeleteConfirmation={openDeleteConfirmation}
        handleCloseDeleteConfirmationModal={handleCloseDeleteConfirmationModal}
        selectedElementName={selectedSection?.sectionName}
        deletingElement={deletingSection}
        deleteElementHandler={deleteSection}
      />
    </Page>
  );
}
