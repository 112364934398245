export function getErrorMessage(text: string, error: any): string {
  let result = text;
  if (error.response?.data?.errors)
    result = result + `: ${JSON.stringify(error.response.data.errors)}.`;
  else if (error.message) result = result + `: ${error.message}.`;
  return result;
}

export function getUrlParamValue(param: string) {
  let url = new URL(window.location.href);
  return url.searchParams.get(param);
}
