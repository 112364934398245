// material
import { Container } from '@mui/material';
// components
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import EmailAccountForm from './components/EmailAccountForm';

// ----------------------------------------------------------------------

export default function EmailAccountCreatePage() {
  return (
    <Page title="Create email account">
      <Container>
        <HeaderBreadcrumbs
          heading="Create email account"
          links={[
            { name: 'Email accounts', href: PATH_DASHBOARD.emailAccount.list },
            { name: 'Create email account' },
          ]}
        />
        <EmailAccountForm />
      </Container>
    </Page>
  );
}
