// material
import {
  Alert,
  Card,
  Chip,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';

import { useCallback, useEffect, useState } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { customLabelDisplayedRows } from 'src/utils/common';
import { getErrorMessage } from 'src/utils/request';
import { ILogList } from 'src/interfaces/log.interface';
import LogService from 'src/services/log.service';
import LogListHead from './components/LogListHead';
import { format } from 'date-fns';
import LoadingData from 'src/components/LoadingData';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'message', label: 'Description', alignRight: false },
  { id: 'level', label: 'Type', alignRight: false },
];

const generateLevelElement = (level: string) => {
  switch (level) {
    case 'Error':
      return <Chip label="Error" color="error" />;
    case 'Warning':
      return <Chip label="Warning" color="warning" />;
    default:
      return <Chip label={level} color="info" />;
  }
};

// ----------------------------------------------------------------------

export default function LogListPage() {
  const [logs, setLogs] = useState<ILogList>();
  const [loadingLogs, setLoadingLogs] = useState<boolean>(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [requestError, setRequestError] = useState<string>();

  const loadLogs = useCallback(() => {
    setLoadingLogs(true);
    LogService.getLogs(page, rowsPerPage)
      .then(({ data }) => {
        setLogs(data);
      })
      .catch((error) => {
        setRequestError(
          getErrorMessage(
            'There was a problem loading the logs. Please contact an administrator.',
            error
          )
        );
      })
      .finally(() => setLoadingLogs(false));
  }, [page, rowsPerPage]);

  useEffect(() => {
    loadLogs();
  }, [loadLogs]);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Page title="Log">
      <Container>
        <HeaderBreadcrumbs heading="Log" links={[]} />
        {requestError && (
          <Alert style={{ marginBottom: '1em' }} severity="error">
            {requestError}
          </Alert>
        )}
        <LoadingData show={loadingLogs} />
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table style={{ marginTop: 15 }}>
                <LogListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {logs?.data.map((row) => {
                    const { id, message, level, timestamp } = row;
                    return (
                      <TableRow hover key={id}>
                        <TableCell align="left">
                          {format(new Date(timestamp), 'dd-MM-yyyy hh:mm:ss a')}
                        </TableCell>
                        <TableCell align="left">{message}</TableCell>
                        <TableCell align="center">{generateLevelElement(level)}</TableCell>
                      </TableRow>
                    );
                  })}
                  {logs?.data.length === 0 && (
                    <TableRow style={{ height: 53 }}>
                      <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                        This list is empty.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            labelDisplayedRows={customLabelDisplayedRows}
            labelRowsPerPage="Elements per page:"
            rowsPerPageOptions={[10, 50, 100]}
            component="div"
            count={logs?.total || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
