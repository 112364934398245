// material
import { Alert, Card, Checkbox, Container, FormControlLabel, Grid, Stack, TextField, Typography } from '@mui/material';
// components
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
// routes
import { useEffect, useState } from 'react';

import { Configuration, CreateCompletionResponse, OpenAIApi } from "openai";
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

const aiConfiguration = new Configuration({
    //organization: "org-g96GtQS8F7Ss6GoWDBAYqaRb", // jileananez
    //apiKey: "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", // jileananez
    organization: "org-Ieu7yblLvm22QPJSVj13VTru", // cmartinez
    apiKey: "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", // cmartinez
});
const openai = new OpenAIApi(aiConfiguration);
let openAiCompletationConfig = {
    model: 'text-davinci-003',
    temperature: 0.8,
    frequency_penalty: 0.5,
    presence_penalty: 0.5,
}

export default function PlaygroundPage() {
    const [generatingResult, setGeneratingResult] = useState<boolean>(false);
    const [requestError, setRequestError] = useState<string>();
    const [prompt, setPrompt] = useState<string>('');
    const [result, setResult] = useState<string>('');

    useEffect(() => console.log('openAiCompletationConfig', openAiCompletationConfig), []);

    const generateResult = () => {
        if (generatingResult || !prompt) return;
        setGeneratingResult(true);
        openai.createCompletion({
            ...openAiCompletationConfig,
            max_tokens: 4000,
            prompt
        }).then(({ data }) => {
            console.log("========== GENERATING RESULT ==========");
            console.log(data);
            console.log("========== GENERATING RESULT ==========");
            if (data && data.choices.length > 0) {
                let reqResult = data.choices[0].text || "";
                if (reqResult) {
                    setResult(reqResult.replace("\n\n", ""));
                    console.log("========== FINAL RESULT ==========");
                    console.log(reqResult);
                    console.log("========== FINAL RESULT ==========");
                }
            }
        })
            .catch((error) => {
                setRequestError("ERROR GENERATING RESULT");
                console.log("ERROR GENERATING RESULT:", error);
            })
            .finally(() => setGeneratingResult(false));
    };

    return (
        <Page title="Playground">
            <Container>
                <HeaderBreadcrumbs heading="Ai Playground" links={[]} />
                {requestError && (
                    <Alert style={{ marginBottom: '1em' }} severity="error">
                        {requestError}
                    </Alert>
                )}
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Card sx={{ p: 3 }}>
                            <Typography variant="body2" paragraph>
                                This tool allows you to generate a result from a prompt using artifitial intelligence. This cost money, so use it with caution.
                            </Typography>
                            <Stack>
                                <Typography variant="h6" paragraph mt={2} mb={0}>
                                    Enter an instruction and watch the API respond with a completion that attempts to match the context or pattern you provided.
                                </Typography>
                                <Typography variant="caption" paragraph>
                                    Ex. Generate 5 ideas of posts for a travel blog.
                                </Typography>
                                <Stack
                                    direction={{ xs: 'column', sm: 'row' }}
                                    spacing={{ xs: 3, sm: 2 }}
                                >
                                    <TextField
                                        multiline
                                        onChange={(e) => setPrompt(e.target.value)}
                                        fullWidth
                                        label="Prompt"
                                    />
                                </Stack>
                            </Stack>
                            <Stack>
                                <LoadingButton
                                    disabled={!prompt || prompt.length < 4}
                                    style={{ width: '30%', marginTop: '2em' }}
                                    onClick={generateResult}
                                    variant="contained"
                                    loading={generatingResult}>
                                    Submit
                                </LoadingButton>
                            </Stack>
                            {(result && !generatingResult) &&
                                <Stack borderTop={1} mt={4}>
                                    <Typography variant="h4" paragraph mt={2}>
                                        Congratulations! Here is your result:
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        multiline
                                        maxRows={9999}
                                        label="Result (HTML)"
                                        value={result}
                                    />
                                </Stack>
                            }
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
