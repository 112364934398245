import { useState } from 'react';
import { Button, Container } from '@mui/material';
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../routes/paths';
import ChapterForm from './components/ChapterForm';
import { useNavigate, useParams } from 'react-router-dom';

import trash2Fill from '@iconify-icons/eva/trash-2-fill';
import { Icon } from '@iconify/react';
import DeleteElementModal from 'src/components/DeleteElementModal';
import ChapterService from 'src/services/chapter.service';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

export default function ChapterEditPage() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { chapterId, projectId } = useParams();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);
  const [deletingChapter, setDeletingChapter] = useState<boolean>(false);

  const handleCloseDeleteConfirmationModal = () => {
    setOpenDeleteConfirmation(false);
  };

  const handleOpenDeleteConfirmationModal = () => {
    setOpenDeleteConfirmation(true);
  };

  const deleteChapter = () => {
    setDeletingChapter(true);
    ChapterService.deleteChapter(chapterId || '')
      .then(() => {
        enqueueSnackbar('The chapter has been deleted successfully.', {
          variant: 'success',
        });
        handleCloseDeleteConfirmationModal();
        navigate(`${PATH_DASHBOARD.course.root}/${projectId}/chapters`);
      })
      .catch((error) => {
        enqueueSnackbar("We weren't able to delete the chapter. Please notify an administrator.", {
          variant: 'error',
        });
      })
      .finally(() => setDeletingChapter(false));
  };

  return (
    <Page title="Edit chapter">
      <Container>
        <HeaderBreadcrumbs
          heading="Edit chapter"
          links={[
            { name: 'Courses', href: PATH_DASHBOARD.course.list },
            { name: 'Chapters', href: `${PATH_DASHBOARD.course.root}/${projectId}/chapters` },
            { name: 'Edit chapter' },
          ]}
          action={
            <Button
              onClick={handleOpenDeleteConfirmationModal}
              color="error"
              variant="contained"
              startIcon={<Icon icon={trash2Fill} />}
            >
              Delete chapter
            </Button>
          }
        />
        <ChapterForm chapterId={chapterId} projectId={projectId || ''} />
      </Container>
      <DeleteElementModal
        openDeleteConfirmation={openDeleteConfirmation}
        handleCloseDeleteConfirmationModal={handleCloseDeleteConfirmationModal}
        deletingElement={deletingChapter}
        deleteElementHandler={deleteChapter}
      />
    </Page>
  );
}
