import { IEmailAccount, IEmailAccountList } from 'src/interfaces/email-account.interface';
import axiosInstance from 'src/utils/axios';

const getEmailAccountsUrl: string = '/EmailAccount/GetAll';
const createEmailAccountUrl: string = '/EmailAccount/Create';
const updateEmailAccountUrl: string = '/EmailAccount/Update';
const getEmailAccountByIdUrl: string = '/EmailAccount/GetById?id=';
const deleteEmailAccountUrl: string = '/EmailAccount/Delete?id=';

const EmailAccountService = {
  getEmailAccounts: (page: number, elementsPerPage: number) =>
    axiosInstance.get<IEmailAccountList>(
      `${getEmailAccountsUrl}?page=${page}&pageSize=${elementsPerPage}`
    ),

  createEmailAccount: (messageTemplate: IEmailAccount) => {
    return axiosInstance.post(createEmailAccountUrl, messageTemplate);
  },

  updateEmailAccount: (messageTemplate: IEmailAccount) => {
    return axiosInstance.put(updateEmailAccountUrl, messageTemplate);
  },

  getEmailAccountById: (messageTemplateId: string) => {
    return axiosInstance.get<IEmailAccount>(getEmailAccountByIdUrl + messageTemplateId);
  },

  deleteEmailAccount: (messageTemplateId: string) => {
    return axiosInstance.delete(deleteEmailAccountUrl + messageTemplateId);
  }
};

export default EmailAccountService;
