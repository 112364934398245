// material
import { Alert, Button, Card, Container, Grid, Typography } from '@mui/material';
// components
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
// routes
import { VERSION } from 'src/app.config';
import { useEffect, useState } from 'react';
import LoadingScreen from 'src/components/LoadingScreen';
import { ISystemInfo } from 'src/interfaces/system-info.interface';
import React from 'react';
import { format } from 'date-fns';
import SystemInfoService from 'src/services/system-info.service';
import { getErrorMessage } from 'src/utils/request';
import Scrollbar from 'src/components/Scrollbar';

// ----------------------------------------------------------------------

export default function SystemInfoPage() {
  const [loadingSystemInfo, setLoadingSystemInfo] = useState<boolean>(true);
  const [systemInfoData, setSystemInfoData] = useState<ISystemInfo>();
  const [showHeaders, setShowHeaders] = useState<boolean>(false);
  const [requestError, setRequestError] = useState<string>();

  useEffect(() => {
    SystemInfoService.getData()
      .then(({ data }) => {
        setSystemInfoData(data);
      })
      .catch((error) => {
        setRequestError(
          getErrorMessage(
            'There was a problem loading the system info. Please contact an administrator.',
            error
          )
        );
      })
      .finally(() => setLoadingSystemInfo(false));
  }, []);

  return (
    <Page title="System info">
      <Container>
        <HeaderBreadcrumbs heading="System info" links={[]} />
        {requestError && (
          <Alert style={{ marginBottom: '1em' }} severity="error">
            {requestError}
          </Alert>
        )}
        {loadingSystemInfo && (
          <Container>
            <LoadingScreen
              sx={{
                ...{ width: '50%', margin: '10% auto 0 auto' },
              }}
            />
          </Container>
        )}
        {systemInfoData && (
          <Card sx={{ padding: '20px' }}>
            <Scrollbar>
              <Grid container spacing={3}>
                <Grid item sm={2} xs={12}>
                  <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                    ASP.NET info
                  </Typography>
                </Grid>
                <Grid item sm={10} xs={12}>
                  <Typography variant="body1">{systemInfoData.aspNetInfo}</Typography>
                </Grid>
                <Grid item sm={2} xs={12}>
                  <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                    Is full trust level
                  </Typography>
                </Grid>
                <Grid item sm={10} xs={12}>
                  <Typography variant="body1">{systemInfoData.isFullTrust}</Typography>
                </Grid>
                <Grid item sm={2} xs={12}>
                  <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                    App version
                  </Typography>
                </Grid>
                <Grid item sm={10} xs={12}>
                  <Typography variant="body1">{VERSION}</Typography>
                </Grid>
                <Grid item sm={2} xs={12}>
                  <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                    React version
                  </Typography>
                </Grid>
                <Grid item sm={10} xs={12}>
                  <Typography variant="body1">{React.version}</Typography>
                </Grid>
                <Grid item sm={2} xs={12}>
                  <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                    Operating system
                  </Typography>
                </Grid>
                <Grid item sm={10} xs={12}>
                  <Typography variant="body1">{systemInfoData.operatingSystem}</Typography>
                </Grid>
                <Grid item sm={2} xs={12}>
                  <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                    Server local time
                  </Typography>
                </Grid>
                <Grid item sm={10} xs={12}>
                  <Typography variant="body1">
                    {format(new Date(systemInfoData.serverLocalTime), 'dd-MM-yyyy hh:mm:ss a')}
                  </Typography>
                </Grid>
                <Grid item sm={2} xs={12}>
                  <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                    Server time zone
                  </Typography>
                </Grid>
                <Grid item sm={10} xs={12}>
                  <Typography variant="body1">{systemInfoData.serverTimeZone}</Typography>
                </Grid>
                <Grid item sm={2} xs={12}>
                  <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                    Coordinated Universal Time (UTC)
                  </Typography>
                </Grid>
                <Grid item sm={10} xs={12}>
                  <Typography variant="body1">{systemInfoData.utcTime}</Typography>
                </Grid>
                <Grid item sm={2} xs={12}>
                  <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                    HTTP_HOST
                  </Typography>
                </Grid>
                <Grid item sm={10} xs={12}>
                  <Typography variant="body1">{systemInfoData.httpHost}</Typography>
                </Grid>
                <Grid item sm={2} xs={12}>
                  <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                    Headers
                  </Typography>
                </Grid>
                <Grid item sm={10} xs={12}>
                  <Button
                    onClick={() => {
                      setShowHeaders(!showHeaders);
                    }}
                    color="primary"
                    style={{ backgroundColor: 'transparent' }}
                  >
                    {showHeaders ? 'Ocultar' : 'Mostrar'}
                  </Button>

                  {showHeaders ? (
                    <ul>
                      {systemInfoData.headers.map((element) => (
                        <li key={element.name}>
                          <strong>{element.name}:</strong> <span>{element.value}</span>
                        </li>
                      ))}
                    </ul>
                  ) : null}
                </Grid>
              </Grid>
            </Scrollbar>
          </Card>
        )}
      </Container>
    </Page>
  );
}
