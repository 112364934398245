// material
import { Container } from '@mui/material';
// components
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import QuestionForm from './components/QuestionForm';

// ----------------------------------------------------------------------

export default function QuestionCreatePage() {
  return (
    <Page title="Create question">
      <Container>
        <HeaderBreadcrumbs
          heading="Create question"
          links={[
            { name: 'Questions', href: PATH_DASHBOARD.question.list },
            { name: 'Create question' },
          ]}
        />
        <QuestionForm />
      </Container>
    </Page>
  );
}
