// material
import { TableRow, TableCell, TableHead } from '@mui/material';

// ----------------------------------------------------------------------

type MessageTemplateListHeadProps = {
  headLabel: any[];
};

export default function MessageTemplateListHead({ headLabel }: MessageTemplateListHeadProps) {
  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell align={headCell.alignRight ? 'right' : 'left'} key={headCell.id}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
