import { useState } from 'react';
import { Button, Container } from '@mui/material';
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../routes/paths';
import PageForm from './components/PageForm';
import { useNavigate, useParams } from 'react-router-dom';

import trash2Fill from '@iconify-icons/eva/trash-2-fill';
import { Icon } from '@iconify/react';
import DeleteElementModal from 'src/components/DeleteElementModal';
import PageService from 'src/services/page.service';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

export default function PageEditPage() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { pageId, projectId, chapterId, sectionId } = useParams();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);
  const [deletingPage, setDeletingPage] = useState<boolean>(false);

  const handleCloseDeleteConfirmationModal = () => {
    setOpenDeleteConfirmation(false);
  };

  const handleOpenDeleteConfirmationModal = () => {
    setOpenDeleteConfirmation(true);
  };

  const deletePage = () => {
    setDeletingPage(true);
    PageService.deletePage(pageId || '')
      .then(() => {
        enqueueSnackbar('The page has been deleted successfully.', {
          variant: 'success',
        });
        handleCloseDeleteConfirmationModal();
        navigate(
          `${PATH_DASHBOARD.course.root}/${projectId}/chapters/${chapterId}/sections/${sectionId}/pages`
        );
      })
      .catch((error) => {
        enqueueSnackbar("We weren't able to delete the page. Please notify an administrator.", {
          variant: 'error',
        });
      })
      .finally(() => setDeletingPage(false));
  };

  return (
    <Page title="Edit page">
      <Container>
        <HeaderBreadcrumbs
          heading="Edit page"
          links={[
            { name: 'Courses', href: PATH_DASHBOARD.course.list },
            { name: 'Chapters', href: `${PATH_DASHBOARD.course.root}/${projectId}/chapters` },
            {
              name: 'Sections',
              href: `${PATH_DASHBOARD.course.root}/${projectId}/chapters/${chapterId}/sections`,
            },
            {
              name: 'Pages',
              href: `${PATH_DASHBOARD.course.root}/${projectId}/chapters/${chapterId}/sections/${sectionId}/pages`,
            },
            { name: 'Edit page' },
          ]}
          action={
            <Button
              onClick={handleOpenDeleteConfirmationModal}
              color="error"
              variant="contained"
              startIcon={<Icon icon={trash2Fill} />}
            >
              Delete page
            </Button>
          }
        />
        <PageForm
          pageId={pageId}
          projectId={projectId || ''}
          chapterId={chapterId || ''}
          sectionId={sectionId || ''}
        />
      </Container>
      <DeleteElementModal
        openDeleteConfirmation={openDeleteConfirmation}
        handleCloseDeleteConfirmationModal={handleCloseDeleteConfirmationModal}
        deletingElement={deletingPage}
        deleteElementHandler={deletePage}
      />
    </Page>
  );
}
