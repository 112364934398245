import {
  Alert,
  Button,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow
} from '@mui/material';
import { Icon } from '@iconify/react';
import plusFill from '@iconify-icons/eva/plus-fill';
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { Link as RouterLink } from 'react-router-dom';

import { PATH_DASHBOARD } from '../../../routes/paths';
import { useCallback, useEffect, useState } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { customLabelDisplayedRows } from 'src/utils/common';
import { getErrorMessage } from 'src/utils/request';
import { IEmailAccountList, IEmailAccount } from 'src/interfaces/email-account.interface';
import EmailAccountService from 'src/services/email-account.service';
import EmailAccountListHead from './components/EmailAccountListHead';
import EmailAccountMoreMenu from './components/EmailAccountMoreMenu';
import { useSnackbar } from 'notistack';
import DeleteElementModal from 'src/components/DeleteElementModal';
import LoadingData from 'src/components/LoadingData';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'email', label: 'Email address', alignRight: false },
  { id: 'displayName', label: 'Display name', alignRight: false },
  { id: 'options', label: 'Settings', alignRight: true }
];

// ----------------------------------------------------------------------

export default function EmailAccountListPage() {
  const { enqueueSnackbar } = useSnackbar();
  const [emailAccounts, setEmailAccounts] = useState<IEmailAccountList>();
  const [loadingEmailAccounts, setLoadingEmailAccounts] = useState<boolean>(true);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState(0);
  const [requestError, setRequestError] = useState<string>();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);
  const [selectedEmailAccount, setSelectedEmailAccount] = useState<IEmailAccount>();
  const [deletingEmailAccount, setDeletingEmailAccount] = useState<boolean>(false);

  const loadEmailAccounts = useCallback(() => {
    setLoadingEmailAccounts(true);
    EmailAccountService.getEmailAccounts(page, rowsPerPage)
      .then(({ data }) => {
        setEmailAccounts(data);
      })
      .catch((error) => {
        setRequestError(
          getErrorMessage('There was a problem while loading the email accounts. Please notify an administrator.', error)
        );
      })
      .finally(() => setLoadingEmailAccounts(false));
  }, [page, rowsPerPage]);

  useEffect(() => {
    loadEmailAccounts();
  }, [loadEmailAccounts]);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDeleteConfirmationModal = (emailAccount: IEmailAccount) => {
    setSelectedEmailAccount(emailAccount);
    setOpenDeleteConfirmation(true);
  };

  const handleCloseDeleteConfirmationModal = () => {
    setSelectedEmailAccount(undefined);
    setOpenDeleteConfirmation(false);
  };

  const deleteEmailAccount = () => {
    if (!selectedEmailAccount) return;
    setDeletingEmailAccount(true);
    EmailAccountService.deleteEmailAccount(selectedEmailAccount.id || '')
      .then(() => {
        enqueueSnackbar('The email account has been deleted succesfully.', {
          variant: 'success'
        });
        if (!emailAccounts) return;
        const currentList = { ...emailAccounts };
        const updatedList = currentList.data.filter((x) => x.id !== selectedEmailAccount.id);
        currentList.data = updatedList;
        setEmailAccounts(currentList);
        handleCloseDeleteConfirmationModal();
      })
      .catch((error) => {
        enqueueSnackbar('It was not possible to delete the email account.', { variant: 'error' });
      })
      .finally(() => setDeletingEmailAccount(false));
  };

  return (
    <Page title="Email accounts">
      <Container>
        <HeaderBreadcrumbs
          heading="Email account"
          links={[]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.emailAccount.create}
              startIcon={<Icon icon={plusFill} />}
            >
              New email account
            </Button>
          }
        />
        {requestError && (
          <Alert style={{ marginBottom: '1em' }} severity="error">
            {requestError}
          </Alert>
        )}
        <LoadingData show={loadingEmailAccounts} />
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table style={{ marginTop: 15 }}>
                <EmailAccountListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {emailAccounts?.data.map((row) => {
                    const { id, email, displayName } = row;
                    return (
                      <TableRow hover key={id}>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">{displayName}</TableCell>
                        <TableCell align="right">
                          <EmailAccountMoreMenu
                            onDelete={() => handleOpenDeleteConfirmationModal(row)}
                            emailAccountId={id || ''}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emailAccounts?.data.length === 0 && (
                    <TableRow style={{ height: 53 }}>
                      <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                        We couldn't find any email account. Please create a new one using the "New email account" button.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            labelDisplayedRows={customLabelDisplayedRows}
            labelRowsPerPage="Accounts per page:"
            rowsPerPageOptions={[10, 50, 100]}
            component="div"
            count={emailAccounts?.total || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <DeleteElementModal
        openDeleteConfirmation={openDeleteConfirmation}
        handleCloseDeleteConfirmationModal={handleCloseDeleteConfirmationModal}
        selectedElementName={selectedEmailAccount?.email}
        deletingElement={deletingEmailAccount}
        deleteElementHandler={deleteEmailAccount}
      />
    </Page>
  );
}
