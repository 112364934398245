import { ISystemInfo } from 'src/interfaces/system-info.interface';
import axiosInstance from 'src/utils/axios';

const getSystemInfosUrl: string = '/SystemInfo/Get';

const SystemInfoService = {
  getData: () => axiosInstance.get<ISystemInfo>(getSystemInfosUrl),
};

export default SystemInfoService;
