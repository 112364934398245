import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Button, Container, IconButton, Typography } from '@mui/material';
import { PATH_AUTH } from './../../../routes/paths';
import LogoOnlyLayout from './../../../layouts/LogoOnlyLayout';
import ResetPasswordForm from './components/ResetPasswordForm';
import Page from './../../../components/Page';
import useSettings from './../../../hooks/useSettings';
import { Icon } from '@iconify/react';
import sunFill from '@iconify-icons/eva/sun-fill';
import moonFill from '@iconify-icons/eva/moon-fill';
import { APP_NAME } from './../../../app.config';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const ThemeButtonStyle = styled(IconButton)(() => ({
  position: 'absolute',
  top: 15,
  right: 15,
  zIndex: 10,
}));

// ----------------------------------------------------------------------

export default function ResetPasswordPage() {
  const { themeMode, onToggleMode } = useSettings();

  return (
    <Page title="New Password">
      <LogoOnlyLayout />
      <Container>
        <ContentStyle sx={{ textAlign: 'center' }}>
          <Typography variant="h3" paragraph>
            Change your password
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 5 }}>
            Please fill the form to update your password.
          </Typography>
          <ResetPasswordForm />
          <Button fullWidth size="large" component={RouterLink} to={PATH_AUTH.login} sx={{ mt: 1 }}>
            Back
          </Button>
        </ContentStyle>
        <ThemeButtonStyle aria-label="mode" onClick={onToggleMode}>
          <Icon icon={themeMode === 'dark' ? sunFill : moonFill} width={24} height={24} />
        </ThemeButtonStyle>
      </Container>
    </Page>
  );
}
