// material
import { Alert, Card, Checkbox, Container, FormControlLabel, Grid, Stack, TextField, Typography } from '@mui/material';
// components
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
// routes
import { useEffect, useState } from 'react';

import { Configuration, CreateCompletionResponse, OpenAIApi } from "openai";
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

const aiConfiguration = new Configuration({
    //organization: "org-g96GtQS8F7Ss6GoWDBAYqaRb", // jileananez
    //apiKey: "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", // jileananez
    organization: "org-Ieu7yblLvm22QPJSVj13VTru", // cmartinez
    apiKey: "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", // cmartinez
});
const openai = new OpenAIApi(aiConfiguration);
let openAiCompletationConfig = {
    model: 'text-davinci-003',
    temperature: 0.8,
    frequency_penalty: 0.5,
    presence_penalty: 0.5,
}

export default function PostGeneratorPage() {
    const [generatingSections, setGeneratingSections] = useState<boolean>(false);
    const [generatingTitleIdeas, setGeneratingTitleIdeas] = useState<boolean>(false);
    const [generatingPost, setGeneratingPost] = useState<boolean>(false);
    const [optimizeRecipe, setOptimizeRecipe] = useState<boolean>(false);
    const [generatingPostProgress, setGeneratingPostProgress] = useState<number>(0);
    const [requestError, setRequestError] = useState<string>();

    const [postTitle, setPostTitle] = useState<string>('');
    const [postTopic, setPostTopic] = useState<string>('');
    const [postKeywords, setPostKeywords] = useState<string>('');
    const [selectedLang, setSelectedLang] = useState<string>('');
    const [generatedPost, setGeneratedPost] = useState<string>('');
    const [generatedSections, setGeneratedSections] = useState<string[]>([]);
    const [generatedTitleIdeas, setGeneratedTitleIdeas] = useState<string[]>([]);
    const [selectedSections, setSelectedSections] = useState<string[]>([]);
    const [withoutSections, setWithoutSections] = useState<boolean>(true);

    const [generatingPostFinished, setGeneratingPostFinished] = useState<boolean>(false);

    useEffect(() => console.log('openAiCompletationConfig', openAiCompletationConfig), []);

    const generateTitleIdeas = () => {
        if (generatingTitleIdeas) return;
        setGeneratingTitleIdeas(true);
        openai.createCompletion({
            ...openAiCompletationConfig,
            max_tokens: 100,
            prompt: generateSectionsPrompt(postTopic)
        })
            .then(({ data }) => {
                console.log("========== GENERATING IDEAS RESULT ==========");
                console.log(data);
                console.log("========== GENERATING IDEAS RESULT ==========");
                if (data && data.choices.length > 0) {
                    let result = data.choices[0].text || "";
                    if (result) {
                        let resultArray = result.split("\n-").filter(x => x.length > 5);
                        setGeneratedTitleIdeas(resultArray);
                    }
                }
            })
            .catch((error) => {
                setRequestError("ERROR GENERATING SECTIONS");
                console.log("ERROR GENERATING SECTIONS:", error);
            })
            .finally(() => setGeneratingTitleIdeas(false));
    };

    const generateSections = () => {
        if (generatingSections) return;
        setGeneratingSections(true);
        openai.createCompletion({
            ...openAiCompletationConfig,
            max_tokens: 100,
            prompt: generateSectionsPrompt(postTitle)
        })
            .then(({ data }) => {
                console.log("========== GENERATING SECTIONS RESULT ==========");
                console.log(data);
                console.log("========== GENERATING SECTIONS RESULT ==========");
                if (data && data.choices.length > 0) {
                    let result = data.choices[0].text || "";
                    if (result) {
                        let resultArray = result.split("\n-").filter(x => x.length > 5);
                        setGeneratedSections(resultArray);
                    }
                }
            })
            .catch((error) => {
                setRequestError("ERROR GENERATING SECTIONS");
                console.log("ERROR GENERATING SECTIONS:", error);
            })
            .finally(() => setGeneratingSections(false));
    };

    const handleSectionSelected = (value: string, checked: boolean) => {
        let index = selectedSections.indexOf(value);
        let update = [...selectedSections];
        if (checked) {
            update.push(value);
        } else if (index > -1) {
            update.splice(index, 1);
        }
        setSelectedSections(update);
    };

    const generateBlogPost = () => {
        if (generatingPost) return;
        setGeneratedPost('');
        setGeneratingPost(true);
        setGeneratingPostFinished(false);
        if (selectedSections.length > 0 && !withoutSections) {
            generatePostWithSections();
        }
        else {
            generatePostWithoutSections();
        }
    };

    const generatePostWithSections = () => {
        var progressInc = Math.ceil(100 / (selectedSections.length + 2));
        generatePostIntroduction()
            .then(async ({ data }) => {
                console.log("========== GENERATING POST INTRODUCTION ==========");
                console.log(data);
                console.log("========== GENERATING POST INTRODUCTION ==========");
                setGeneratingPostProgress(prev => prev + progressInc);
                updateFinalPost(data);
                for (const section of selectedSections) {
                    try {
                        setGeneratedPost(prev => prev + ("\n*" + section + "*\n"));
                        let aiResult = await generatePostSection(section);
                        setGeneratingPostProgress(prev => prev + progressInc);
                        let sectionData = aiResult.data;
                        console.log("========== GENERATING POST SECTION DETAILS ==========");
                        console.log(sectionData);
                        console.log("========== GENERATING POST SECTION DETAILS ==========");
                        updateFinalPost(sectionData);
                    } catch (error) {
                        setRequestError("ERROR GENERATING POST SECTION DETAILS");
                        console.log("ERROR GENERATING POST SECTION DETAILS:", error);
                    }
                }
                generatePostConclusion()
                    .then(({ data }) => {
                        console.log("========== GENERATING POST CONCLUSION ==========");
                        console.log(data);
                        console.log("========== GENERATING POST CONCLUSION ==========");
                        setGeneratingPostProgress(100);
                        updateFinalPost(data);
                        setGeneratingPostFinished(true);
                        setGeneratingPost(false);
                        console.log("========== FINAL POST ==========");
                        console.log(generatedPost);
                        console.log("========== FINAL POST ==========");
                    })
                    .catch((error) => {
                        setRequestError("ERROR GENERATING POST CONCLUSION");
                        console.log("ERROR GENERATING POST CONCLUSION:", error);
                    });
            })
            .catch((error) => {
                setRequestError("ERROR GENERATING POST INTRODUCTION");
                console.log("ERROR GENERATING POST INTRODUCTION:", error);
            });
    };

    const generatePostIntroduction = () => {
        return openai.createCompletion({
            ...openAiCompletationConfig,
            max_tokens: 250,
            prompt: generatePostIntroductionPrompt()
        });
    };

    const generatePostSection = (section: string) => {
        return openai.createCompletion({
            ...openAiCompletationConfig,
            max_tokens: 300,
            prompt: generatePostSectionPrompt(section)
        });
    };

    const generatePostConclusion = () => {
        return openai.createCompletion({
            ...openAiCompletationConfig,
            max_tokens: 250,
            prompt: generatePostConclusionPrompt()
        });
    }

    const generatePostWithoutSections = () => {
        setGeneratingPostProgress(10);
        openai.createCompletion({
            ...openAiCompletationConfig,
            max_tokens: 1000,
            prompt: generatePostWithoutSectionsPrompt()
        }).then(({ data }) => {
            console.log("========== GENERATING POST RESULT ==========");
            console.log(data);
            console.log("========== GENERATING POST RESULT ==========");
            if (data && data.choices.length > 0) {
                let result = data.choices[0].text || "";
                if (result) {
                    setGeneratedPost(result.replace("\n\n", ""));
                    setGeneratingPostFinished(true);
                    setGeneratingPost(false);
                    console.log("========== FINAL POST ==========");
                    console.log(generatedPost);
                    console.log("========== FINAL POST ==========");
                }
            }
        })
            .catch((error) => {
                setRequestError("ERROR GENERATING POST WO SECTIONS");
                console.log("ERROR GENERATING POST WO SECTIONS:", error);
            })
            .finally(() => { setGeneratingPostProgress(100); setGeneratingSections(false); });
    };

    const generateSectionsPrompt = (value: string) => {
        let promt = "Generate blog section ideas ";
        if (selectedLang == "es") {
            promt += "in spanish ";
        }
        promt += "from the following title: \"" + value + "\"";
        return promt;
    };

    const generatePostWithoutSectionsPrompt = () => {
        let promt = "";

        if (optimizeRecipe)
            promt = "Create a detailed recipe ";
        else
            promt = "Write a long and detailed blog post ";

        if (selectedLang == "es")
            promt += "in spanish ";

        if (optimizeRecipe)
            promt += `for ${postTitle}, including an introduction and conclusion`;
        else
            promt += "in a professional witty tone about the topic: \"" + postTitle + "\"";

        if (postKeywords)
            promt += ", including the following keywords: " + postKeywords;

        if (optimizeRecipe)
            promt += ". The recipe must have 600 words minimum.";
        else
            promt += ". The post must have 600 words minimum.";

        return promt;
    };

    const generatePostIntroductionPrompt = () => {
        let promt = "Write a detailed blog introduction ";
        if (selectedLang == "es") {
            promt += "in spanish ";
        }
        promt += "in a professional, witty tone: \"" + postTitle + "\"";
        if (postKeywords) {
            promt += ", including the following keywords: " + postKeywords;
        }
        return promt + ".";
    };

    const generatePostSectionPrompt = (section: string) => {
        let promt = "Write a detailed blog section ";
        if (selectedLang == "es") {
            promt += "in spanish ";
        }
        promt += "in a professional, witty tone: \"" + section + "\"";
        // if (postKeywords) {
        //     promt += ", including the following keywords: " + postKeywords;
        // }
        return promt + ".";
    };

    const generatePostConclusionPrompt = () => {
        let promt = "Write a detailed blog conclusion ";
        if (selectedLang == "es") {
            promt += "in spanish ";
        }
        promt += "in a professional, witty tone: \"" + postTitle + "\"";
        if (postKeywords) {
            promt += ", including the following keywords: " + postKeywords;
        }
        return promt + ".";
    };

    const updateFinalPost = (data: CreateCompletionResponse) => {
        if (data && data.choices.length > 0) {
            let result = data.choices[0].text || "";
            if (result) setGeneratedPost(prev => prev + result);
        }
    };

    return (
        <Page title="Post generator">
            <Container>
                <HeaderBreadcrumbs heading="Post generator" links={[]} />
                {requestError && (
                    <Alert style={{ marginBottom: '1em' }} severity="error">
                        {requestError}
                    </Alert>
                )}
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Card sx={{ p: 3 }}>
                            <Typography variant="body2" paragraph>
                                This tool allows you to generate a blog post using artifitial intelligence. It is recommended to review the final result before posting it online. Please consider that every post and section generation cost money, so use it with caution.
                            </Typography>
                            <Stack>
                                <Typography variant="h6" paragraph mb={0}>
                                    Step 1: Select the post language
                                </Typography>
                                <Typography variant="caption" paragraph>
                                    Consider this tool works better in english.
                                </Typography>
                                <TextField
                                    select
                                    fullWidth
                                    label="Language"
                                    placeholder="Language"
                                    SelectProps={{ native: true }}
                                    InputLabelProps={{ shrink: true }}
                                    value={selectedLang}
                                    onChange={(e) => {
                                        setSelectedLang(e.target.value);
                                    }}
                                >
                                    <option value="en">English</option>
                                    <option value="es">Spanish</option>
                                </TextField>
                            </Stack>
                            <Stack>
                                <Typography variant="h6" paragraph mt={2} mb={0}>
                                    Step 2: Generate post ideas (optional)
                                </Typography>
                                <Typography variant="caption" paragraph>
                                    Generate ideas for a post. It is recommended to use a single keyword or a long tail keyword.
                                </Typography>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                    <TextField
                                        onChange={(e) => setPostTopic(e.target.value)}
                                        fullWidth
                                        label="Blog topic and/or keywords"
                                    />
                                    <LoadingButton
                                        disabled={generatingPost || postTopic.length <= 3}
                                        style={{ width: '40%' }}
                                        onClick={generateTitleIdeas}
                                        variant="contained"
                                        loading={generatingTitleIdeas}>
                                        Generate ideas
                                    </LoadingButton>
                                </Stack>
                                {generatedTitleIdeas &&
                                    <Stack mt={2} ml={3}>
                                        {generatedTitleIdeas.length > 0 &&
                                            <ul>
                                                {
                                                    generatedTitleIdeas.map(x => <li key={x}>{x}</li>)
                                                }
                                            </ul>
                                        }
                                    </Stack>
                                }
                            </Stack>
                            <Stack>
                                <Typography variant="h6" paragraph mt={2}>
                                    Step 3: Write the post title
                                </Typography>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                    <TextField
                                        onChange={(e) => setPostTitle(e.target.value)}
                                        fullWidth
                                        label="Post title"
                                    />
                                </Stack>
                            </Stack>
                            {postTitle && postTitle.length > 3 &&
                                <>
                                    {/* <Stack>
                                        <Typography variant="h6" paragraph mt={2} mb={0}>
                                            Step 3: Generate post sections
                                        </Typography>
                                        <Typography variant="caption" paragraph>
                                            Sections are subtitles inside the post.
                                        </Typography>
                                        <LoadingButton
                                            disabled={generatingPostFinished || generatingPost}
                                            style={{ width: '40%' }}
                                            onClick={generateSections}
                                            variant="contained"
                                            loading={generatingSections}>
                                            Generate sections
                                        </LoadingButton>
                                        {(generatedSections.length == 0 && !generatingSections && !generatingPost && !generatingPostFinished) &&
                                            <Button
                                                style={{ width: '40%', marginTop: 10 }}
                                                variant="contained"
                                                onClick={() => setWithoutSections(true)}
                                                color='error'
                                            >
                                                Don't use sections
                                            </Button>
                                        }
                                    </Stack> */}
                                    {/* {generatedSections.length > 0 &&
                                        <Stack>
                                            <Typography variant="h6" paragraph mt={2} mb={0}>
                                                Step 4: Select sections
                                            </Typography>
                                            <Typography variant="caption" paragraph>
                                                Select the generate sections (subtitles) you want to include in your post. If you don't select any section, the post will be generated only using the title.
                                            </Typography>
                                            {generatedSections.map(x =>
                                                <FormControlLabel
                                                    key={x}
                                                    label={x}
                                                    control={
                                                        <Checkbox
                                                            onChange={(event) => handleSectionSelected(x, event.target.checked)}
                                                            checked={selectedSections.indexOf(x) > -1}
                                                        />
                                                    }
                                                />)
                                            }
                                        </Stack>
                                    } */}
                                    {(generatedSections.length > 0 || withoutSections) &&
                                        <>
                                            <Stack>
                                                <Typography variant="h6" paragraph mt={2} mb={0}>
                                                    Step 4: Add keywords (optional)
                                                </Typography>
                                                <Typography variant="caption" paragraph>
                                                    Add keywords separating them with a comma (,)
                                                </Typography>

                                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                                    <TextField
                                                        onChange={(e) => setPostKeywords(e.target.value)}
                                                        multiline
                                                        fullWidth
                                                        label="Keywords"
                                                    />
                                                </Stack>
                                            </Stack>
                                            <Stack>
                                                <Typography variant="h6" paragraph mt={2}>
                                                    Step 5: Generate blog post
                                                </Typography>
                                                <FormControlLabel
                                                    label="Optimize for recipes"
                                                    control={
                                                        <Checkbox
                                                            disabled={generatingPost}
                                                            onChange={(event) => setOptimizeRecipe(event.target.checked)}
                                                            checked={optimizeRecipe}
                                                        />
                                                    }
                                                />
                                                <LoadingButton
                                                    style={{ width: '30%', marginTop: '2em' }}
                                                    onClick={generateBlogPost}
                                                    variant="contained"
                                                    loading={generatingPost}>
                                                    Generate blog post
                                                </LoadingButton>
                                                {/* {generatingPost &&
                                                    <LinearProgress color='secondary' style={{ marginTop: 30 }} variant="determinate" value={generatingPostProgress} />
                                                } */}
                                            </Stack>
                                            {generatingPostFinished &&
                                                <Stack borderTop={1} mt={4}>
                                                    <Typography variant="h4" paragraph mt={2}>
                                                        Congratulations! Here is your new post:
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        multiline
                                                        maxRows={9999}
                                                        label="Result (HTML)"
                                                        value={generatedPost}
                                                    />
                                                </Stack>
                                            }
                                        </>
                                    }
                                </>
                            }
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
