import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Grid,
  Stack,
  TextField,
  Alert,
  Container,
  InputAdornment,
  Tooltip,
  IconButton,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import LoadingScreen from 'src/components/LoadingScreen';
import { getErrorMessage } from 'src/utils/request';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { IChapter, IChapterFormFields } from 'src/interfaces/course.interface';
import { Icon } from '@iconify/react';
import questionMarkCircleFill from '@iconify-icons/eva/question-mark-circle-fill';
import copyOutline from '@iconify-icons/eva/copy-outline';
import ChapterService from 'src/services/chapter.service';
import { getNextPageDefaultValue } from 'src/utils/common';

// ----------------------------------------------------------------------

type ChapterFormProps = {
  projectId: string;
  chapterId?: string;
};

const formValidationSchema: Yup.SchemaOf<IChapterFormFields> = Yup.object().shape({
  chapterName: Yup.string().required('The chapter name is required.'),
  chapterNumber: Yup.number().required('The chapter number is required.'),
  paymentRequired: Yup.boolean().required(),
});

export default function ChapterForm({ chapterId, projectId }: ChapterFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loadingChapter, setLoadingChapter] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [copyTooltipTitle, setCopyTooltipTitle] = useState<string>();
  const [requestError, setRequestError] = useState<string>();
  const [formInitialValues, setFormInitialValues] = useState<IChapterFormFields>({
    chapterName: '',
    chapterNumber: getNextPageDefaultValue(parseInt(searchParams.get('lastChapterNumber') || '0')),
    paymentRequired: false,
  });
  const loadChapter = useCallback(() => {
    if (!chapterId) return;
    setLoadingChapter(true);
    ChapterService.getChapterById(chapterId)
      .then(({ data }) => {
        setFormInitialValues((prevState) => ({
          ...prevState,
          chapterName: data.chapterName,
          chapterNumber: data.chapterNumber,
          paymentRequired: data.paymentRequired,
        }));
      })
      .catch((error) => {
        setDisableSubmit(true);
        setRequestError(
          getErrorMessage(
            "There was a problem and we weren't able to load the chapter. Please notify an administrator.",
            error
          )
        );
      })
      .finally(() => setLoadingChapter(false));
  }, [chapterId]);

  useEffect(() => {
    loadChapter();
  }, [loadChapter]);

  const onSubmit = (
    values: IChapterFormFields,
    formikHelpers: FormikHelpers<IChapterFormFields>
  ) => {
    if (!chapterId) createChapter(values, formikHelpers);
    else updateChapter(values, formikHelpers);
  };

  const createChapter = (
    values: IChapterFormFields,
    formikHelpers: FormikHelpers<IChapterFormFields>
  ) => {
    const chapter: IChapter = {
      projectId,
      chapterName: values.chapterName,
      chapterNumber: values.chapterNumber,
      paymentRequired: values.paymentRequired,
    };

    ChapterService.createChapter(chapter)
      .then(() => {
        formikHelpers.resetForm();
        enqueueSnackbar('The chapter has been created successfully.', { variant: 'success' });
        navigate(`${PATH_DASHBOARD.course.root}/${projectId}/chapters`);
      })
      .catch((error) => {
        setRequestError(getErrorMessage("We weren't able to create the chapter", error));
      })
      .finally(() => formikHelpers.setSubmitting(false));
  };

  const updateChapter = (
    values: IChapterFormFields,
    formikHelpers: FormikHelpers<IChapterFormFields>
  ) => {
    if (!chapterId) return;

    const chapter: IChapter = {
      id: chapterId,
      chapterName: values.chapterName,
      chapterNumber: values.chapterNumber,
      projectId,
      paymentRequired: values.paymentRequired,
    };

    ChapterService.updateChapter(chapter)
      .then(() => {
        formikHelpers.resetForm();
        enqueueSnackbar('The chapter has been updated successfully.', {
          variant: 'success',
        });
        navigate(`${PATH_DASHBOARD.course.root}/${projectId}/chapters`);
      })
      .catch((error) => {
        setRequestError(getErrorMessage("We weren't able to update the chapter", error));
      })
      .finally(() => formikHelpers.setSubmitting(false));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    onSubmit,
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
      {loadingChapter ? (
        <Container>
          <LoadingScreen
            sx={{
              ...{ width: '50%', margin: '10% auto 0 auto' },
            }}
          />
        </Container>
      ) : (
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        label="Name"
                        {...getFieldProps('chapterName')}
                        error={Boolean(touched.chapterName && errors.chapterName)}
                        helperText={touched.chapterName && errors.chapterName}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="The name of the chapter.">
                                <IconButton>
                                  <Icon icon={questionMarkCircleFill} width={24} height={24} />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        fullWidth
                        label="Chapter number"
                        {...getFieldProps('chapterNumber')}
                        error={Boolean(touched.chapterNumber && errors.chapterNumber)}
                        helperText={touched.chapterNumber && errors.chapterNumber}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="The chapter's number. This field is prefilled with the next available number.">
                                <IconButton>
                                  <Icon icon={questionMarkCircleFill} width={24} height={24} />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <FormControlLabel
                        style={{ width: '100%' }}
                        control={
                          <Checkbox
                            {...getFieldProps('paymentRequired')}
                            checked={formik.values.paymentRequired}
                          />
                        }
                        label="Payment is required to access this chapter?"
                      />
                    </Stack>
                    {requestError && <Alert severity="error">{requestError}</Alert>}
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        disabled={disableSubmit}
                      >
                        {!chapterId ? 'Create chapter' : 'Save changes'}
                      </LoadingButton>
                    </Box>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      )}
    </>
  );
}
