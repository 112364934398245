// material
import { Container } from '@mui/material';
// components
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import ChapterForm from './components/ChapterForm';
import { useParams } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function ChapterCreatePage() {
  const { projectId } = useParams();
  return (
    <Page title="Create chapter">
      <Container>
        <HeaderBreadcrumbs
          heading="Create chapter"
          links={[
            { name: 'Courses', href: PATH_DASHBOARD.course.list },
            { name: 'Chapters', href: `${PATH_DASHBOARD.course.root}/${projectId}/chapters` },
            { name: 'Create chapter' },
          ]}
        />
        <ChapterForm projectId={projectId || ''} />
      </Container>
    </Page>
  );
}
