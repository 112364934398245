import * as Yup from 'yup';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Grid,
  Stack,
  TextField,
  Alert,
  Container,
  InputAdornment,
  Tooltip,
  IconButton,
  FormControlLabel,
  Checkbox,
  Typography,
  Button,
} from '@mui/material';
import LoadingScreen from 'src/components/LoadingScreen';
import { getErrorMessage } from 'src/utils/request';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { IPage, IPageFormFields } from 'src/interfaces/course.interface';
import { Icon } from '@iconify/react';
import questionMarkCircleFill from '@iconify-icons/eva/question-mark-circle-fill';
import PageService from 'src/services/page.service';
import { Editor } from '@tinymce/tinymce-react';
import { API_URL } from 'src/app.config';
import { getNextPageDefaultValue } from 'src/utils/common';

// ----------------------------------------------------------------------

type PageFormProps = {
  chapterId: string;
  projectId: string;
  sectionId: string;
  pageId?: string;
};

const formValidationSchema: Yup.SchemaOf<IPageFormFields> = Yup.object().shape({
  pageNumber: Yup.number().required('The page number is required.'),
  content: Yup.string(),
  option1: Yup.string(),
  option2: Yup.string(),
  option3: Yup.string(),
  option4: Yup.string(),
  question: Yup.string(),
});

export default function PageForm({ pageId, chapterId, projectId, sectionId }: PageFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const editorRef = useRef<any>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loadingPage, setLoadingPage] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [requestError, setRequestError] = useState<string>();
  const [isQuiz, setIsQuiz] = useState<boolean>(false);
  const [newPicture, setNewPicture] = useState<File>();
  const [currentPictureId, setCurrentPictureId] = useState<string>();
  const [formInitialValues, setFormInitialValues] = useState<IPageFormFields>({
    pageNumber: getNextPageDefaultValue(parseInt(searchParams.get('lastPageNumber') || '0')),
    content: '',
    question: '',
    option1: '',
    option2: '',
    option3: '',
    option4: '',
  });

  const loadPage = useCallback(() => {
    if (!pageId) return;
    setLoadingPage(true);
    PageService.getPageById(pageId)
      .then(({ data }) => {
        setFormInitialValues((prevState) => ({
          ...prevState,
          pageNumber: data.pageNumber,
          content: data.content || '',
          question: data.question || undefined,
          option1: data.option1 || undefined,
          option2: data.option2 || undefined,
          option3: data.option3 || undefined,
          option4: data.option4 || undefined,
        }));
        setIsQuiz(data.question ? true : false);
        setCurrentPictureId(data.pictureId);
      })
      .catch((error) => {
        setDisableSubmit(true);
        setRequestError(
          getErrorMessage(
            "There was a problem and we weren't able to load the page. Please notify an administrator.",
            error
          )
        );
      })
      .finally(() => setLoadingPage(false));
  }, [pageId]);

  useEffect(() => {
    loadPage();
  }, [loadPage]);

  const onSubmit = (values: IPageFormFields, formikHelpers: FormikHelpers<IPageFormFields>) => {
    if (!pageId) createPage(values, formikHelpers);
    else updatePage(values, formikHelpers);
  };

  const createPage = (values: IPageFormFields, formikHelpers: FormikHelpers<IPageFormFields>) => {
    var data = new FormData();
    data.set('sectionId', sectionId);
    data.set('question', isQuiz ? values.question || '' : '');
    data.set('option1', isQuiz ? values.option1 || '' : '');
    data.set('option2', isQuiz ? values.option2 || '' : '');
    data.set('option3', isQuiz ? values.option3 || '' : '');
    data.set('option4', isQuiz ? values.option4 || '' : '');
    data.set('content', values.content || '');
    data.set('pageNumber', values.pageNumber.toString());
    if (newPicture) data.append('pictureFile', newPicture);

    PageService.createPage(data)
      .then(() => {
        formikHelpers.resetForm();
        enqueueSnackbar('The page has been created successfully.', { variant: 'success' });
        navigate(
          `${PATH_DASHBOARD.course.root}/${projectId}/chapters/${chapterId}/sections/${sectionId}/pages`
        );
      })
      .catch((error) => {
        setRequestError(getErrorMessage("We weren't able to create the page", error));
      })
      .finally(() => formikHelpers.setSubmitting(false));
  };

  const updatePage = (values: IPageFormFields, formikHelpers: FormikHelpers<IPageFormFields>) => {
    if (!pageId) return;

    var data = new FormData();
    data.set('id', pageId);
    data.set('sectionId', sectionId);
    data.set('question', isQuiz ? values.question || '' : '');
    data.set('option1', isQuiz ? values.option1 || '' : '');
    data.set('option2', isQuiz ? values.option2 || '' : '');
    data.set('option3', isQuiz ? values.option3 || '' : '');
    data.set('option4', isQuiz ? values.option4 || '' : '');
    data.set('content', values.content || '');
    data.set('pageNumber', values.pageNumber.toString());
    if (newPicture) data.append('pictureFile', newPicture);

    PageService.updatePage(data)
      .then(() => {
        formikHelpers.resetForm();
        enqueueSnackbar('The page has been updated successfully.', {
          variant: 'success',
        });
        navigate(
          `${PATH_DASHBOARD.course.root}/${projectId}/chapters/${chapterId}/sections/${sectionId}/pages`
        );
      })
      .catch((error) => {
        setRequestError(getErrorMessage("We weren't able to update the page", error));
      })
      .finally(() => formikHelpers.setSubmitting(false));
  };

  const handleImageSelection = async (file: File) => {
    try {
      var form = new FormData();
      form.append('file', file);
      form.append('projectId', projectId);
      let { data } = await PageService.uploadPicture(form);
      return API_URL + data;
    } catch (error) {
      console.log(getErrorMessage('There was a problem while uploading the image', error));
      return 'ERROR UPLOAD';
    }
  };

  const handleSelectedPicture = (value: any) => {
    if (!value || !value[0]) return;
    setNewPicture(value[0]);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    onSubmit,
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
      {loadingPage ? (
        <Container>
          <LoadingScreen
            sx={{
              ...{ width: '50%', margin: '10% auto 0 auto' },
            }}
          />
        </Container>
      ) : (
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        label="Page number"
                        {...getFieldProps('pageNumber')}
                        error={Boolean(touched.pageNumber && errors.pageNumber)}
                        helperText={touched.pageNumber && errors.pageNumber}
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="The page's number. This field is prefilled with the next available number.">
                                <IconButton>
                                  <Icon icon={questionMarkCircleFill} width={24} height={24} />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <FormControlLabel
                        label="is this page a Quiz?"
                        control={
                          <Checkbox
                            disabled={formik.isSubmitting}
                            onChange={(event) => setIsQuiz(event.target.checked)}
                            checked={isQuiz}
                          />
                        }
                      />
                    </Stack>
                    {isQuiz ? (
                      <>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                          <TextField
                            fullWidth
                            label="Question"
                            {...getFieldProps('question')}
                            error={Boolean(touched.question && errors.question)}
                            helperText={touched.question && errors.question}
                            type="text"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Tooltip title="The question for the quiz.">
                                    <IconButton>
                                      <Icon icon={questionMarkCircleFill} width={24} height={24} />
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Stack>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                          <TextField
                            fullWidth
                            label="Option 1 (answer)"
                            {...getFieldProps('option1')}
                            error={Boolean(touched.option1 && errors.option1)}
                            helperText={touched.option1 && errors.option1}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Tooltip title="If the page is a quiz, write the option 1 of the question (answer).">
                                    <IconButton>
                                      <Icon icon={questionMarkCircleFill} width={24} height={24} />
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <TextField
                            fullWidth
                            label="Option 2"
                            {...getFieldProps('option2')}
                            error={Boolean(touched.option2 && errors.option2)}
                            helperText={touched.option2 && errors.option2}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Tooltip title="If the page is a quiz, write the option 2 of the question.">
                                    <IconButton>
                                      <Icon icon={questionMarkCircleFill} width={24} height={24} />
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Stack>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                          <TextField
                            fullWidth
                            label="Option 3"
                            {...getFieldProps('option3')}
                            error={Boolean(touched.option3 && errors.option3)}
                            helperText={touched.option3 && errors.option3}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Tooltip title="If the page is a quiz, write the option 3 of the question.">
                                    <IconButton>
                                      <Icon icon={questionMarkCircleFill} width={24} height={24} />
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <TextField
                            fullWidth
                            label="Option 4"
                            {...getFieldProps('option4')}
                            error={Boolean(touched.option4 && errors.option4)}
                            helperText={touched.option4 && errors.option4}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Tooltip title="If the page is a quiz, write the option 4 of the question.">
                                    <IconButton>
                                      <Icon icon={questionMarkCircleFill} width={24} height={24} />
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Stack>
                        <Stack spacing={2}>
                          <input
                            accept="image/*"
                            id="contained-button-image-file"
                            type="file"
                            onChange={(event) => handleSelectedPicture(event.target.files)}
                            style={{ display: 'none' }}
                          />
                          <Box component="div">
                            <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                              Select the question picture (optional)
                            </Typography>
                            {newPicture ? (
                              <>
                                <img
                                  width={100}
                                  height={100}
                                  src={URL.createObjectURL(newPicture)}
                                  alt="question"
                                />
                                <br />
                              </>
                            ) : currentPictureId ? (
                              <>
                                <img
                                  width={100}
                                  height={100}
                                  src={`${API_URL}File/GetPicture?id=${currentPictureId}`}
                                  alt="question"
                                />
                                <br />
                              </>
                            ) : null}
                            <label htmlFor="contained-button-image-file">
                              <Button variant="contained" component="span">
                                Select picture
                              </Button>
                            </label>
                            <Typography variant="caption" sx={{ mt: 0.5 }}>
                              <br />
                              Recommended size: 500x300 pixels
                            </Typography>
                          </Box>
                        </Stack>
                      </>
                    ) : (
                      <Stack mt={4}>
                        <Typography variant="h4" paragraph mt={2}>
                          Content
                        </Typography>
                        <Editor
                          tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                          onInit={(evt, editor) => (editorRef.current = editor)}
                          value={formik.values.content}
                          onEditorChange={(newValue) => formik.setFieldValue('content', newValue)}
                          init={{
                            height: 500,
                            menubar: false,
                            plugins: [
                              'advlist',
                              'autolink',
                              'lists',
                              'link',
                              'image',
                              'charmap',
                              'anchor',
                              'searchreplace',
                              'visualblocks',
                              'code',
                              'fullscreen',
                              'insertdatetime',
                              'media',
                              'table',
                              'preview',
                              'help',
                              'wordcount',
                              'codesample',
                            ],
                            toolbar:
                              'undo redo | blocks | ' +
                              'bold italic forecolor | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist outdent indent | ' +
                              'removeformat | image | help code | codesample',
                            content_style:
                              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px } img { max-width: 100% }',
                            file_picker_types: 'image',
                            image_dimensions: false,
                            image_description: false,
                            file_picker_callback: function (callback, value, meta) {
                              var input = document.createElement('input');
                              input.setAttribute('type', 'file');
                              input.setAttribute('accept', 'image/*');

                              input.onchange = function (e) {
                                var file: any = input.files ? input.files[0] : {};
                                if (meta.filetype == 'image') {
                                  handleImageSelection(file).then((result) => {
                                    callback(result);
                                  });
                                }
                              };

                              input.click();
                            },
                          }}
                        />
                        {Boolean(errors.content) && (
                          <Typography variant="body1" paragraph mt={1} color="error">
                            {errors.content}
                          </Typography>
                        )}
                      </Stack>
                    )}
                    {requestError && <Alert severity="error">{requestError}</Alert>}
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        disabled={disableSubmit}
                      >
                        {!pageId ? 'Create page' : 'Save changes'}
                      </LoadingButton>
                    </Box>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      )}
    </>
  );
}
