// material
import { Container } from '@mui/material';
// components
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import SectionForm from './components/SectionForm';
import { useParams } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function SectionCreatePage() {
  const { projectId, chapterId } = useParams();
  return (
    <Page title="Create section">
      <Container>
        <HeaderBreadcrumbs
          heading="Create section"
          links={[
            { name: 'Courses', href: PATH_DASHBOARD.course.list },
            { name: 'Chapters', href: `${PATH_DASHBOARD.course.root}/${projectId}/chapters` },
            {
              name: 'Sections',
              href: `${PATH_DASHBOARD.course.root}/${projectId}/chapters/${chapterId}/sections`,
            },
            { name: 'Create section' },
          ]}
        />
        <SectionForm projectId={projectId || ''} chapterId={chapterId || ''} />
      </Container>
    </Page>
  );
}
