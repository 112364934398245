import { IPublishPost } from 'src/interfaces/ai.interface';
import { ISelectList } from 'src/interfaces/common.interface';
import { IProject } from 'src/interfaces/project.interface';
import axiosInstance from 'src/utils/axios';

const getWordpressProjectsUrl: string = '/Project/getWordpressProjects';
const getWordpressCategoriesUrl: string = '/Ai/getWordpressCategories?projectId=';
const publishToWordpressUrl: string = '/Ai/publishToWordpress';

const WordpressService = {
  getWordpressProjects: () => axiosInstance.get<IProject[]>(getWordpressProjectsUrl),
  getWordpressCategories: (projectId: string) => axiosInstance.get<ISelectList[]>(getWordpressCategoriesUrl + projectId),
  publishToWordpress: (body: IPublishPost) => axiosInstance.post(publishToWordpressUrl, body),
};

export default WordpressService;
