import { ReactNode } from 'react';
import { Container, Alert, AlertTitle } from '@mui/material';
import { userIsInRole } from '../utils/guard';

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  accessibleRoles: string[];
  children: ReactNode | string;
};

export const APP_ROLES = {
  Dashboard: 'Dashboard',
  Users: 'Users',
  UserProfiles: 'UserProfiles',
  MessageTemplates: 'MessageTemplates',
  CmsPages: 'CmsPages',
  GeneralSettings: 'GeneralSettings',
  EmailAccounts: 'EmailAccounts',
  SystemInfo: 'SystemInfo',
  Log: 'Log',
  Maintenance: 'Maintenance',
  ScheduleTasks: 'ScheduleTasks',
  Projects: 'Projects',
  Questions: 'TriviaQuestions',
  PostGenerator: 'PostGenerator',
  Chapters: 'Chapters',
  Sections: 'Sections',
  Pages: 'Pages',
};

// ----------------------------------------------------------------------

export default function RoleBasedGuard({ accessibleRoles, children }: RoleBasedGuardProp) {
  if (!userIsInRole(accessibleRoles)) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>No permission</AlertTitle>
          The page you're trying access has restricted access.
          <br />
          Please refer to your system administrator
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
