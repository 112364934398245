import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, Container, IconButton, Typography } from '@mui/material';
import { SentIcon } from './../../../assets';
import { PATH_AUTH } from './../../../routes/paths';
import LogoOnlyLayout from './../../../layouts/LogoOnlyLayout';
import PasswordRecoveryForm from './components/PasswordRecoveryForm';
import Page from './../../../components/Page';
import useSettings from './../../../hooks/useSettings';
import { Icon } from '@iconify/react';
import sunFill from '@iconify-icons/eva/sun-fill';
import moonFill from '@iconify-icons/eva/moon-fill';
import { APP_NAME } from './../../../app.config';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const ThemeButtonStyle = styled(IconButton)(() => ({
  position: 'absolute',
  top: 15,
  right: 15,
  zIndex: 10,
}));

// ----------------------------------------------------------------------

export default function PasswordRecoveryPage() {
  const { themeMode, onToggleMode } = useSettings();
  const [email, setEmail] = useState<string>('cmartinez@teed.com.mx');
  const [sent, setSent] = useState<boolean>(false);

  return (
    <Page title="Forgot password?">
      <LogoOnlyLayout />
      <Container>
        <ContentStyle sx={{ textAlign: 'center' }}>
          {!sent ? (
            <>
              <Typography variant="h3" paragraph>
                Forgot your password?
              </Typography>

              <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                Please enter the email address associated with your account and we will email you a
                link to reset your password.
              </Typography>

              <PasswordRecoveryForm
                onSent={() => setSent(true)}
                onGetEmail={(value) => setEmail(value)}
              />

              <Button
                fullWidth
                size="large"
                component={RouterLink}
                to={PATH_AUTH.login}
                sx={{ mt: 1 }}
              >
                Back
              </Button>
            </>
          ) : (
            <Box sx={{ textAlign: 'center' }}>
              <SentIcon sx={{ mb: 5, mx: 'auto', height: 160 }} />
              <Typography variant="h3" gutterBottom>
                Your request has been sent
              </Typography>
              <Typography>
                We sent an email to&nbsp;
                <strong>{email}</strong>
                <br />
                <br />
                Please check your inbox and follow the instructions to generate a new password.
              </Typography>
              <Button
                size="large"
                variant="contained"
                component={RouterLink}
                to={PATH_AUTH.login}
                sx={{ mt: 5 }}
              >
                Back
              </Button>
            </Box>
          )}
          <ThemeButtonStyle aria-label="mode" onClick={onToggleMode}>
            <Icon icon={themeMode === 'dark' ? sunFill : moonFill} width={24} height={24} />
          </ThemeButtonStyle>
        </ContentStyle>
      </Container>
    </Page>
  );
}
