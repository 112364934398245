import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import CourseService from 'src/services/course.service';
import { MIconButton } from '../components/@material-extend';
import { Icon } from '@iconify/react';
import closeFill from '@iconify-icons/eva/close-fill';

type ImportModalProps = {
  projectId: string;
  openImportModal: boolean;
  handleCloseImportModal: () => void;
};

export default function ImportCourseModal({
  openImportModal,
  projectId,
  handleCloseImportModal,
}: ImportModalProps) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [importing, setImporting] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [requestError, setRequestError] = useState<string>('');

  const onFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.item(0);
    if (file) setSelectedFile(file);
  };

  const handleImportCourse = () => {
    if (importing || !selectedFile) return;
    setImporting(true);
    setRequestError('');
    selectedFile.text().then((result) => {
      let body = {
        content: result,
        projectId,
      };

      CourseService.importCourse(body)
        .then(() => {
          handleCloseImportModal();
          enqueueSnackbar('Couse content imported.', {
            variant: 'success',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            ),
          });
        })
        .catch((error) => {
          setRequestError('ERROR IMPORTING COURSE');
          console.log('ERROR IMPORTING COURSE:', error);
        })
        .finally(() => setImporting(false));
    });
  };

  return (
    <Dialog open={openImportModal} onClose={handleCloseImportModal}>
      <DialogTitle>Import course</DialogTitle>
      <DialogContent style={{ textAlign: 'center', marginTop: '25px' }}>
        {requestError && (
          <Alert style={{ marginBottom: '1em' }} severity="error">
            {requestError}
          </Alert>
        )}
        <DialogContentText>
          Select a JSON file to import the course. This will create a new course content.
        </DialogContentText>
        <DialogContentText>
          <br />
          <input
            onChange={onFileSelected}
            accept=".json"
            type="file"
            name="import"
            id="import-course"
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseImportModal}>Cancel</Button>
        <LoadingButton onClick={handleImportCourse} autoFocus loading={importing}>
          Import
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
