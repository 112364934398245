import { ISection, ISectionList } from 'src/interfaces/course.interface';
import axiosInstance from 'src/utils/axios';

const getSectionsUrl: string = '/Section/GetAll';
const deleteProjectUrl: string = '/Section/Delete?id=';
const getSectionByIdUrl: string = '/Section/getById?id=';
const createSectionUrl: string = '/Section/Create';
const updateSectionUrl: string = '/Section/Update';

const SectionService = {
  getSections: (page: number, elementsPerPage: number, chapterId: string) =>
    axiosInstance.get<ISectionList>(
      `${getSectionsUrl}?page=${page}&pageSize=${elementsPerPage}&chapterId=${chapterId}`
    ),
  deleteSection: (sectionId: string) => axiosInstance.delete(deleteProjectUrl + sectionId),
  getSectionById: (sectionId: string) => axiosInstance.get<ISection>(getSectionByIdUrl + sectionId),
  createSection: (section: ISection) => axiosInstance.post(createSectionUrl, section),
  updateSection: (section: ISection) => axiosInstance.put(updateSectionUrl, section),
};

export default SectionService;
