import { IMessageTemplate, IMessageTemplateList } from 'src/interfaces/message-template.interface';
import axiosInstance from 'src/utils/axios';

const getMessageTemplatesUrl: string = '/MessageTemplate/GetAll';
const getSelectListUrl: string = '/MessageTemplate/getSelectList';
const createMessageTemplateUrl: string = '/MessageTemplate/Create';
const updateMessageTemplateUrl: string = '/MessageTemplate/Update';
const getMessageTemplateByIdUrl: string = '/MessageTemplate/GetById?id=';
const deleteMessageTemplateUrl: string = '/MessageTemplate/Delete?id=';

const MessageTemplateService = {
  getMessageTemplates: (page: number, elementsPerPage: number) =>
    axiosInstance.get<IMessageTemplateList>(
      `${getMessageTemplatesUrl}?page=${page}&pageSize=${elementsPerPage}`
    ),

  getSelectList: () => {
    return axiosInstance.get<IMessageTemplate>(getSelectListUrl);
  },

  createMessageTemplate: (messageTemplate: IMessageTemplate) => {
    return axiosInstance.post(createMessageTemplateUrl, messageTemplate);
  },

  updateMessageTemplate: (messageTemplate: IMessageTemplate) => {
    return axiosInstance.put(updateMessageTemplateUrl, messageTemplate);
  },

  getMessageTemplateById: (messageTemplateId: string) => {
    return axiosInstance.get<IMessageTemplate>(getMessageTemplateByIdUrl + messageTemplateId);
  },

  deleteMessageTemplate: (messageTemplateId: string) => {
    return axiosInstance.delete(deleteMessageTemplateUrl + messageTemplateId);
  },
};

export default MessageTemplateService;
