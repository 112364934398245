import { IAppUser } from './../interfaces/authentication.interface';
import { lskey_user } from './lsKeys';

export const getCurrentUserRoles = () => {
  var currentUserJson = localStorage.getItem(lskey_user);
  if (!currentUserJson) return [];
  var user = JSON.parse(currentUserJson) as IAppUser;
  return user.roles;
};

export const userIsInRole = (accessibleRoles: string[]): boolean => {
  const currentRoles = getCurrentUserRoles();
  return currentRoles.some((x) => accessibleRoles.indexOf(x) > -1);
};
