import {
  Alert,
  Button,
  Card,
  Chip,
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import { Icon } from '@iconify/react';
import plusFill from '@iconify-icons/eva/plus-fill';
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { Link as RouterLink } from 'react-router-dom';

import { PATH_DASHBOARD } from '../../../../routes/paths';
import { useCallback, useEffect, useState } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { customLabelDisplayedRows } from 'src/utils/common';
import { getErrorMessage } from 'src/utils/request';
import { ICampaignList, ICampaign, ICampaignListData } from 'src/interfaces/campaign.interface';
import CampaignService from 'src/services/campaign.service';
import CampaignListHead from './components/CampaignListHead';
import CampaignMoreMenu from './components/CampaignMoreMenu';
import { useSnackbar } from 'notistack';
import eyeFill from '@iconify-icons/eva/eye-fill';
import LoadingData from 'src/components/LoadingData';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'postCount', label: 'Post count (S/P/C)', alignRight: false },
  { id: 'project', label: 'Project', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'options', label: 'Settings', alignRight: true },
];

const generateStatusElement = (status: string) => {
  switch (status) {
    case 'Cancelled':
      return <Chip label={status} color="error" />;
    case 'Processing':
      return <Chip label={status} color="warning" />;
    case 'Completed':
      return <Chip label={status} color="success" />;
    default:
      return <Chip label={status} color="info" />;
  }
};

// ----------------------------------------------------------------------

export default function CampaignListPage() {
  const { enqueueSnackbar } = useSnackbar();
  const [campaigns, setCampaigns] = useState<ICampaignList>();
  const [loadingCampaigns, setLoadingCampaigns] = useState<boolean>(true);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState(0);
  const [requestError, setRequestError] = useState<string>();
  const [selectedCampaign, setSelectedCampaign] = useState<ICampaignListData>();

  const loadCampaigns = useCallback(() => {
    setLoadingCampaigns(true);
    CampaignService.getCampaigns(page, rowsPerPage)
      .then(({ data }) => {
        const order = ['Processing', 'Scheduled', 'Paused', 'Completed', 'Cancelled'];
        let sortedArray = data.data.sort(
          (a, b) => order.indexOf(a.status) - order.indexOf(b.status)
        );
        setCampaigns({ ...data, data: sortedArray });
      })
      .catch((error) => {
        setRequestError(
          getErrorMessage(
            'There was a problem while loading the campaigns. Please notify an administrator.',
            error
          )
        );
      })
      .finally(() => setLoadingCampaigns(false));
  }, [page, rowsPerPage]);

  useEffect(() => {
    loadCampaigns();
  }, [loadCampaigns]);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleOpenDeleteConfirmationModal = (campaign: ICampaignListData) => {
  //   setSelectedCampaign(campaign);
  //   setOpenDeleteConfirmation(true);
  // };

  // const handleCloseDeleteConfirmationModal = () => {
  //   setSelectedCampaign(undefined);
  //   setOpenDeleteConfirmation(false);
  // };

  // const deleteCampaign = () => {
  //   if (!selectedCampaign) return;
  //   setDeletingCampaign(true);
  //   CampaignService.deleteCampaign(selectedCampaign.id || '')
  //     .then(() => {
  //       enqueueSnackbar('The campaign has been deleted succesfully.', {
  //         variant: 'success',
  //       });
  //       if (!campaigns) return;
  //       const currentList = { ...campaigns };
  //       const updatedList = currentList.data.filter((x) => x.id !== selectedCampaign.id);
  //       currentList.data = updatedList;
  //       setCampaigns(currentList);
  //       handleCloseDeleteConfirmationModal();
  //     })
  //     .catch((error) => {
  //       enqueueSnackbar('It was not possible to delete the campaign.', { variant: 'error' });
  //     })
  //     .finally(() => setDeletingCampaign(false));
  // };

  return (
    <Page title="Campaigns">
      <Container>
        <HeaderBreadcrumbs
          heading="Campaign"
          links={[]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.campaign.create}
              startIcon={<Icon icon={plusFill} />}
            >
              New campaign
            </Button>
          }
        />
        {requestError && (
          <Alert style={{ marginBottom: '1em' }} severity="error">
            {requestError}
          </Alert>
        )}
        <LoadingData show={loadingCampaigns} />
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table style={{ marginTop: 15 }}>
                <CampaignListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {campaigns?.data.map((row) => {
                    const {
                      id,
                      name,
                      project,
                      postCount,
                      status,
                      cancelledCount,
                      processingCount,
                      scheduledCount,
                    } = row;
                    return (
                      <TableRow hover key={id}>
                        <TableCell align="left">{name}</TableCell>
                        <TableCell align="left">
                          {postCount} ({scheduledCount}/{processingCount}/{cancelledCount})
                        </TableCell>
                        <TableCell align="left">{project}</TableCell>
                        <TableCell align="left">{generateStatusElement(status)}</TableCell>
                        <TableCell align="right">
                          <IconButton href={`${PATH_DASHBOARD.campaign.root}/edit/${id}`}>
                            <Icon icon={eyeFill} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {campaigns?.data.length === 0 && (
                    <TableRow style={{ height: 53 }}>
                      <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                        We couldn't find any campaign. Please create a new one using the "New
                        campaign" button.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            labelDisplayedRows={customLabelDisplayedRows}
            labelRowsPerPage="Campaigns per page:"
            rowsPerPageOptions={[10, 50, 100]}
            component="div"
            count={campaigns?.total || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
