import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
// material
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Grid,
  Stack,
  TextField,
  Typography,
  Alert,
  Container,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import UserProfileService from 'src/services/user-profile.service';
import LoadingScreen from 'src/components/LoadingScreen';
import { getErrorMessage } from 'src/utils/request';
import {
  IUserProfile,
  IUserProfileFormFields,
  IUserRole,
} from 'src/interfaces/user-profile.interface';
import { updateObject } from 'src/utils/common';
import { PATH_DASHBOARD } from 'src/routes/paths';

// ----------------------------------------------------------------------

type UserProfileFormProps = {
  userProfileId?: string;
};

const formValidationSchema: Yup.SchemaOf<IUserProfileFormFields> = Yup.object().shape({
  name: Yup.string().required('The profile name is required.'),
  roles: Yup.object(),
});

// ----------------------------------------------------------------------

export default function UserProfileForm({ userProfileId }: UserProfileFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingUserProfile, setLoadingUserProfile] = useState<boolean>(false);
  const [loadingUserProfileRoles, setLoadingUserProfileRoles] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [formRoles, setFormRoles] = useState<{ [key: string]: IUserRole[] }>();
  const [requestError, setRequestError] = useState<string>();
  const [formInitialValues, setFormInitialValues] = useState<IUserProfileFormFields>({
    name: '',
  });

  const loadData = useCallback(() => {
    if (userProfileId) {
      loadUserProfile(userProfileId);
    } else {
      loadUserProfileRoles();
    }
  }, [userProfileId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const loadUserProfile = (currentProfileId: string) => {
    setLoadingUserProfile(true);
    UserProfileService.getUserProfileById(currentProfileId)
      .then(({ data }) => {
        setFormInitialValues((prevState) => {
          return {
            ...prevState,
            name: data.name,
          };
        });
        setFormRoles(data.roles);
      })
      .catch((error) => {
        setDisableSubmit(true);
        setRequestError(
          getErrorMessage(
            "There was a problem and we weren't able to load the user profile. Please notify an administrator",
            error
          )
        );
      })
      .finally(() => setLoadingUserProfile(false));
  };

  const loadUserProfileRoles = () => {
    setLoadingUserProfileRoles(true);
    UserProfileService.getUserProfileRoles()
      .then(({ data }) => {
        setFormRoles(data.roles);
      })
      .catch((error) => {
        setDisableSubmit(true);
        setRequestError(
          getErrorMessage(
            "There was a problem and we weren't able to load the user roles. Please notify an administrator",
            error
          )
        );
      })
      .finally(() => setLoadingUserProfileRoles(false));
  };

  const onSubmit = (
    values: IUserProfileFormFields,
    formikHelpers: FormikHelpers<IUserProfileFormFields>
  ) => {
    if (!userProfileId) createUserProfile(values, formikHelpers);
    else updateUserProfile(values, formikHelpers);
  };

  const createUserProfile = (
    values: IUserProfileFormFields,
    formikHelpers: FormikHelpers<IUserProfileFormFields>
  ) => {
    const userProfile: IUserProfile = {
      name: values.name,
      roles: formRoles,
      description: '',
    };

    UserProfileService.createUserProfile(userProfile)
      .then(() => {
        formikHelpers.resetForm();
        enqueueSnackbar('The user profile was created successfully', { variant: 'success' });
        navigate(PATH_DASHBOARD.userProfile.list);
      })
      .catch((error) => {
        setRequestError(
          getErrorMessage(
            "We weren't able to create the user profile. Please notify an administrator",
            error
          )
        );
      })
      .finally(() => formikHelpers.setSubmitting(false));
  };

  const updateUserProfile = (
    values: IUserProfileFormFields,
    formikHelpers: FormikHelpers<IUserProfileFormFields>
  ) => {
    if (!userProfileId) return;

    const userProfile: IUserProfile = {
      id: userProfileId,
      name: values.name,
      description: '',
      roles: formRoles,
    };

    UserProfileService.updateUserProfile(userProfile)
      .then(() => {
        formikHelpers.resetForm();
        enqueueSnackbar('The user profile has been updated successfully.', { variant: 'success' });
        navigate(PATH_DASHBOARD.userProfile.list);
      })
      .catch((error) => {
        setRequestError(
          getErrorMessage(
            "We weren't able to update the user profile. Please notify an administrator",
            error
          )
        );
      })
      .finally(() => formikHelpers.setSubmitting(false));
  };

  const handleRoleChange = (field: string, value: boolean, index: number) => {
    if (!formRoles) return;
    const updatedControls = updateObject(formRoles, {
      [field]: formRoles[field].map((element: any, i: number) =>
        i === index ? Object.assign({}, element, { selected: value }) : element
      ),
    });
    setFormRoles(updatedControls);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    onSubmit,
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
      {loadingUserProfile || loadingUserProfileRoles ? (
        <Container>
          <LoadingScreen
            sx={{
              ...{ width: '50%', margin: '10% auto 0 auto' },
            }}
          />
        </Container>
      ) : (
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        label="Name"
                        {...getFieldProps('name')}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    </Stack>
                    {formRoles && (
                      <Stack>
                        {Object.keys(formRoles).map((key, i) => {
                          return (
                            <Grid key={i} item xs={12} md={12}>
                              <Typography variant="h4">{key}</Typography>
                              {formRoles && (
                                <>
                                  {formRoles[key].map((permission, j) => {
                                    return (
                                      <FormControlLabel
                                        key={permission.id}
                                        control={
                                          <Checkbox
                                            onChange={(event) =>
                                              handleRoleChange(key, event.target.checked, j)
                                            }
                                            checked={permission.selected}
                                          />
                                        }
                                        label={permission.name}
                                      />
                                    );
                                  })}
                                </>
                              )}
                            </Grid>
                          );
                        })}
                      </Stack>
                    )}
                    {requestError && <Alert severity="error">{requestError}</Alert>}
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        disabled={disableSubmit}
                      >
                        {!userProfileId ? 'Create profile' : 'Save changes'}
                      </LoadingButton>
                    </Box>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      )}
    </>
  );
}
