import { m } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container } from '@mui/material';
// components
import Page from './../../components/Page';
import { MotionContainer, varBounce } from './../../components/animate';
// assets
import { PageNotFoundIllustration } from './../../assets';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }: any) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function PrivacyPolicy() {
  return (
    <Page title="Privacy Policy">
      <Container component={MotionContainer}>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <m.div variants={varBounce().in}>
            <Typography variant="h2" paragraph>
              Privacy Policy
            </Typography>
          </m.div>

          <m.div style={{ textAlign: 'left', marginBottom: 30 }} variants={varBounce().in}>
            <Typography variant="h4" paragraph>
              Who we are
            </Typography>
            <Typography gutterBottom>
              Our website address is: https://www.succocloud.com
            </Typography>
            <Typography variant="h4" paragraph>
              Cookies
            </Typography>
            <Typography gutterBottom>
              If you leave a comment on our site you may opt-in to saving your name, email address and website in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will last for one year.
            </Typography>
            <Typography variant="h4" paragraph>
              Embedded content from other websites
            </Typography>
            <Typography gutterBottom>
              Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.
              <br />
              <br />
              These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.
            </Typography>
            <Typography variant="h4" paragraph>
              How long we retain your data
            </Typography>
            <Typography gutterBottom>
              If you leave a comment, the comment and its metadata are retained indefinitely. This is so we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue.
            </Typography>
            <Typography variant="h4" paragraph>
              What rights you have over your data
            </Typography>
            <Typography gutterBottom>
              If you have an account on this site, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.
            </Typography>
          </m.div>
          <Button to="/" size="large" variant="contained" component={RouterLink}>
            Go to home
          </Button>
        </ContentStyle>
      </Container>
    </Page >
  );
}
