import { ISelectList } from 'src/interfaces/common.interface';
import { IProject, IProjectList } from 'src/interfaces/project.interface';
import axiosInstance from 'src/utils/axios';

const getProjectsUrl: string = '/Project/GetAll';
const createProjectUrl: string = '/Project/Create';
const updateProjectUrl: string = '/Project/Update';
const getProjectByIdUrl: string = '/Project/GetById?id=';
const deleteProjectUrl: string = '/Project/Delete?id=';
const getProjectSelectListUrl: string = '/Project/getProjectSelectList';

const ProjectService = {
  getProjects: (page: number, elementsPerPage: number) =>
    axiosInstance.get<IProjectList>(`${getProjectsUrl}?page=${page}&pageSize=${elementsPerPage}`),

  createProject: (project: IProject) =>
    axiosInstance.post(createProjectUrl, project),

  updateProject: (project: IProject) =>
    axiosInstance.put(updateProjectUrl, project),

  getProjectById: (projectId: string) =>
    axiosInstance.get<IProject>(getProjectByIdUrl + projectId),

  deleteProject: (projectId: string) =>
    axiosInstance.delete(deleteProjectUrl + projectId),

  getProjectSelectList: () => axiosInstance.get<ISelectList[]>(getProjectSelectListUrl)
};

export default ProjectService;
