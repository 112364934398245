import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Grid,
  Stack,
  TextField,
  Alert,
  Container,
  InputAdornment,
  Tooltip,
  IconButton,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import LoadingScreen from 'src/components/LoadingScreen';
import { getErrorMessage } from 'src/utils/request';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { ISection, ISectionFormFields } from 'src/interfaces/course.interface';
import { Icon } from '@iconify/react';
import questionMarkCircleFill from '@iconify-icons/eva/question-mark-circle-fill';
import SectionService from 'src/services/section.service';
import { getNextPageDefaultValue } from 'src/utils/common';

// ----------------------------------------------------------------------

type SectionFormProps = {
  chapterId: string;
  projectId: string;
  sectionId?: string;
};

const formValidationSchema: Yup.SchemaOf<ISectionFormFields> = Yup.object().shape({
  sectionName: Yup.string().required('The section name is required.'),
  sectionNumber: Yup.number().required('The section number is required.'),
  paymentRequired: Yup.boolean().required(),
  sectionType: Yup.number().required(),
});

export default function SectionForm({ sectionId, chapterId, projectId }: SectionFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loadingSection, setLoadingSection] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [requestError, setRequestError] = useState<string>();
  const [formInitialValues, setFormInitialValues] = useState<ISectionFormFields>({
    sectionName: '',
    sectionNumber: getNextPageDefaultValue(parseInt(searchParams.get('lastSectionNumber') || '0')),
    paymentRequired: false,
    sectionType: 10,
  });

  const loadSection = useCallback(() => {
    if (!sectionId) return;
    setLoadingSection(true);
    SectionService.getSectionById(sectionId)
      .then(({ data }) => {
        setFormInitialValues((prevState) => ({
          ...prevState,
          sectionName: data.sectionName,
          sectionNumber: data.sectionNumber,
          paymentRequired: data.paymentRequired,
          sectionType: data.sectionType,
        }));
      })
      .catch((error) => {
        setDisableSubmit(true);
        setRequestError(
          getErrorMessage(
            "There was a problem and we weren't able to load the section. Please notify an administrator.",
            error
          )
        );
      })
      .finally(() => setLoadingSection(false));
  }, [sectionId]);

  useEffect(() => {
    loadSection();
  }, [loadSection]);

  const onSubmit = (
    values: ISectionFormFields,
    formikHelpers: FormikHelpers<ISectionFormFields>
  ) => {
    if (!sectionId) createSection(values, formikHelpers);
    else updateSection(values, formikHelpers);
  };

  const createSection = (
    values: ISectionFormFields,
    formikHelpers: FormikHelpers<ISectionFormFields>
  ) => {
    const section: ISection = {
      chapterId,
      sectionName: values.sectionName,
      sectionNumber: values.sectionNumber,
      paymentRequired: values.paymentRequired,
      sectionType: values.sectionType,
    };

    SectionService.createSection(section)
      .then(() => {
        formikHelpers.resetForm();
        enqueueSnackbar('The section has been created successfully.', { variant: 'success' });
        navigate(`${PATH_DASHBOARD.course.root}/${projectId}/chapters/${chapterId}/sections`);
      })
      .catch((error) => {
        setRequestError(getErrorMessage("We weren't able to create the section", error));
      })
      .finally(() => formikHelpers.setSubmitting(false));
  };

  const updateSection = (
    values: ISectionFormFields,
    formikHelpers: FormikHelpers<ISectionFormFields>
  ) => {
    if (!sectionId) return;

    const section: ISection = {
      id: sectionId,
      chapterId,
      sectionName: values.sectionName,
      sectionNumber: values.sectionNumber,
      paymentRequired: values.paymentRequired,
      sectionType: values.sectionType,
    };

    SectionService.updateSection(section)
      .then(() => {
        formikHelpers.resetForm();
        enqueueSnackbar('The section has been updated successfully.', {
          variant: 'success',
        });
        navigate(`${PATH_DASHBOARD.course.root}/${projectId}/chapters/${chapterId}/sections`);
      })
      .catch((error) => {
        setRequestError(getErrorMessage("We weren't able to update the section", error));
      })
      .finally(() => formikHelpers.setSubmitting(false));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    onSubmit,
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
      {loadingSection ? (
        <Container>
          <LoadingScreen
            sx={{
              ...{ width: '50%', margin: '10% auto 0 auto' },
            }}
          />
        </Container>
      ) : (
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        label="Name"
                        {...getFieldProps('sectionName')}
                        error={Boolean(touched.sectionName && errors.sectionName)}
                        helperText={touched.sectionName && errors.sectionName}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="The name of the section.">
                                <IconButton>
                                  <Icon icon={questionMarkCircleFill} width={24} height={24} />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        fullWidth
                        label="Section number"
                        {...getFieldProps('sectionNumber')}
                        error={Boolean(touched.sectionNumber && errors.sectionNumber)}
                        helperText={touched.sectionNumber && errors.sectionNumber}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="The section's number. This field is prefilled with the next available number.">
                                <IconButton>
                                  <Icon icon={questionMarkCircleFill} width={24} height={24} />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        select
                        fullWidth
                        label="Section type"
                        placeholder="Section Type"
                        {...getFieldProps('sectionType')}
                        SelectProps={{ native: true }}
                        error={Boolean(touched.sectionType && errors.sectionType)}
                        helperText={touched.sectionType && errors.sectionType}
                        InputLabelProps={{ shrink: true }}
                      >
                        <option disabled value="">
                          Select the section type...
                        </option>
                        <option value="10">Content</option>
                        <option value="20">Quiz</option>
                      </TextField>
                      <FormControlLabel
                        style={{ width: '100%' }}
                        control={
                          <Checkbox
                            {...getFieldProps('paymentRequired')}
                            checked={formik.values.paymentRequired}
                          />
                        }
                        label="Payment is required to access this section?"
                      />
                    </Stack>
                    {requestError && <Alert severity="error">{requestError}</Alert>}
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        disabled={disableSubmit}
                      >
                        {!sectionId ? 'Create section' : 'Save changes'}
                      </LoadingButton>
                    </Box>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      )}
    </>
  );
}
