// material
import {
  Alert,
  Button,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import { Icon } from '@iconify/react';
import plusFill from '@iconify-icons/eva/plus-fill';
// components
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
// routes
import { Link as RouterLink } from 'react-router-dom';

import { PATH_DASHBOARD } from '../../../routes/paths';
import { useCallback, useEffect, useState } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { customLabelDisplayedRows } from 'src/utils/common';
import { getErrorMessage } from 'src/utils/request';
import { IMessageTemplateList, IMessageTemplate } from 'src/interfaces/message-template.interface';
import MessageTemplateService from 'src/services/message-template.service';
import MessageTemplateListHead from './components/MessageTemplateListHead';
import MessageTemplateMoreMenu from './components/MessageTemplateMoreMenu';
import { useSnackbar } from 'notistack';
import DeleteElementModal from 'src/components/DeleteElementModal';
import LoadingData from 'src/components/LoadingData';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'subject', label: 'Subject', alignRight: false },
  { id: 'options', label: 'Settings', alignRight: true },
];

// ----------------------------------------------------------------------

export default function MessageTemplateListPage() {
  const { enqueueSnackbar } = useSnackbar();
  const [messageTemplates, setMessageTemplates] = useState<IMessageTemplateList>();
  const [loadingMessageTemplates, setLoadingMessageTemplates] = useState<boolean>(true);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState(0);
  const [requestError, setRequestError] = useState<string>();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);
  const [selectedMessageTemplate, setSelectedMessageTemplate] = useState<IMessageTemplate>();
  const [deletingMessageTemplate, setDeletingMessageTemplate] = useState<boolean>(false);

  const loadMessageTemplates = useCallback(() => {
    setLoadingMessageTemplates(true);
    MessageTemplateService.getMessageTemplates(page, rowsPerPage)
      .then(({ data }) => {
        setMessageTemplates(data);
      })
      .catch((error) => {
        setRequestError(
          getErrorMessage(
            'There was a problem while loading the email templates. Please notify an administrator.',
            error
          )
        );
      })
      .finally(() => setLoadingMessageTemplates(false));
  }, [page, rowsPerPage]);

  useEffect(() => {
    loadMessageTemplates();
  }, [loadMessageTemplates]);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDeleteConfirmationModal = (messageTemplate: IMessageTemplate) => {
    setSelectedMessageTemplate(messageTemplate);
    setOpenDeleteConfirmation(true);
  };

  const handleCloseDeleteConfirmationModal = () => {
    setSelectedMessageTemplate(undefined);
    setOpenDeleteConfirmation(false);
  };

  const deleteMessageTemplate = () => {
    if (!selectedMessageTemplate) return;
    setDeletingMessageTemplate(true);
    MessageTemplateService.deleteMessageTemplate(selectedMessageTemplate.id || '')
      .then(() => {
        enqueueSnackbar('The email template has been deleted succesfully.', {
          variant: 'success',
        });
        if (!messageTemplates) return;
        const currentList = { ...messageTemplates };
        const updatedList = currentList.data.filter((x) => x.id !== selectedMessageTemplate.id);
        currentList.data = updatedList;
        setMessageTemplates(currentList);
        handleCloseDeleteConfirmationModal();
      })
      .catch((error) => {
        enqueueSnackbar('It was not possible to delete the email template.', { variant: 'error' });
      })
      .finally(() => setDeletingMessageTemplate(false));
  };

  return (
    <Page title="Email templates">
      <Container>
        <HeaderBreadcrumbs
          heading="Email templates"
          links={[]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.messageTemplate.create}
              startIcon={<Icon icon={plusFill} />}
            >
              New email template
            </Button>
          }
        />
        {requestError && (
          <Alert style={{ marginBottom: '1em' }} severity="error">
            {requestError}
          </Alert>
        )}
        <LoadingData show={loadingMessageTemplates} />
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table style={{ marginTop: 15 }}>
                <MessageTemplateListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {messageTemplates?.data.map((row) => {
                    const { id, name, subject } = row;
                    return (
                      <TableRow hover key={id}>
                        <TableCell align="left">{name}</TableCell>
                        <TableCell align="left">{subject}</TableCell>
                        <TableCell align="right">
                          <MessageTemplateMoreMenu
                            onDelete={() => handleOpenDeleteConfirmationModal(row)}
                            messageTemplateId={id || ''}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {messageTemplates?.data.length === 0 && (
                    <TableRow style={{ height: 53 }}>
                      <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                        We couldn't find any email template. Please create a new one using the "New
                        email template" button.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            labelDisplayedRows={customLabelDisplayedRows}
            labelRowsPerPage="Templates per page:"
            rowsPerPageOptions={[10, 50, 100]}
            component="div"
            count={messageTemplates?.total || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <DeleteElementModal
        openDeleteConfirmation={openDeleteConfirmation}
        handleCloseDeleteConfirmationModal={handleCloseDeleteConfirmationModal}
        selectedElementName={selectedMessageTemplate?.name}
        deletingElement={deletingMessageTemplate}
        deleteElementHandler={deleteMessageTemplate}
      />
    </Page>
  );
}
