import { ISelectList } from 'src/interfaces/common.interface';
import { IProjectList } from 'src/interfaces/project.interface';
import axiosInstance from 'src/utils/axios';

const getCoursesUrl: string = '/Project/GetAll';
const deleteProjectUrl: string = '/Project/Delete?id=';
const importCourseUrl: string = '/Course/importCourse';

const CourseService = {
  getCourses: (page: number, elementsPerPage: number) =>
    axiosInstance.get<IProjectList>(
      `${getCoursesUrl}?page=${page}&pageSize=${elementsPerPage}&type=30`
    ),
  deleteCourse: (projectId: string) => axiosInstance.delete(deleteProjectUrl + projectId),
  importCourse: (body: { projectId: string; content: string }) =>
    axiosInstance.post(importCourseUrl, body),
};

export default CourseService;
