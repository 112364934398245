import { experimentalStyled as styled } from '@mui/material/styles';
import { Box, Card, Stack, Container, Typography, IconButton, Link } from '@mui/material';

import moonFill from '@iconify-icons/eva/moon-fill';
import sunFill from '@iconify-icons/eva/sun-fill';
import { Icon } from '@iconify/react';

import { Link as RouterLink } from 'react-router-dom';

import useSettings from './../../../hooks/useSettings';

import Page from '../../../components/Page';
import Image from '../../../components/Image';
import { APP_NAME } from './../../../app.config';
import LoginForm from './components/LoginForm';
import useResponsive from 'src/hooks/useResponsive';
import Logo from 'src/components/Logo';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

const ThemeButtonStyle = styled(IconButton)(() => ({
  position: 'absolute',
  top: 15,
  right: 15,
  zIndex: 10,
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const { themeMode, onToggleMode } = useSettings();
  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Logo />
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, welcome back
            </Typography>
            <Image
              visibleByDefault
              disabledEffect
              sx={{
                width: '50%',
                alignSelf: 'center',
              }}
              src="/assets/illustrations/illustration_components.png"
              alt="login"
            />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  {`Sign in to ${APP_NAME}`}
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  Enter your details below to log into your account.
                </Typography>
              </Box>
            </Stack>
            <LoginForm />
            <ThemeButtonStyle aria-label="mode" onClick={onToggleMode}>
              <Icon icon={themeMode === 'dark' ? sunFill : moonFill} width={24} height={24} />
            </ThemeButtonStyle>
            <Box sx={{ flexGrow: 1, textAlign: 'center' }} mt={3}>
              <Link component={RouterLink} variant="subtitle2" to='/privacy-policy'>
                Privacy policy
              </Link>
            </Box>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
