import { Backdrop } from '@mui/material';
import LoadingScreen from './LoadingScreen';

type FormProps = {
  show: boolean;
};

export default function LoadingData({ show }: FormProps) {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={show}>
      <LoadingScreen sx={{ backgroundColor: 'transparent', height: 'auto' }} />
    </Backdrop>
  );
}
