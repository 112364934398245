// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/admin';

// ----------------------------------------------------------------------
export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  passwordRecovery: path(ROOTS_AUTH, '/password-recovery'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  dashboard: path(ROOTS_DASHBOARD, '/dashboard'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    edit: path(ROOTS_DASHBOARD, '/user/edit/:userId'),
    create: path(ROOTS_DASHBOARD, '/user/create'),
    userProfileList: path(ROOTS_DASHBOARD, '/user/user-profile/list'),
  },
  userProfile: {
    root: path(ROOTS_DASHBOARD, '/user-profile'),
    list: path(ROOTS_DASHBOARD, '/user-profile/list'),
    create: path(ROOTS_DASHBOARD, '/user-profile/create'),
    edit: path(ROOTS_DASHBOARD, '/user-profile/edit/:userProfileId'),
  },
  project: {
    root: path(ROOTS_DASHBOARD, '/project'),
    list: path(ROOTS_DASHBOARD, '/project/list'),
    edit: path(ROOTS_DASHBOARD, '/project/edit/:projectId'),
    create: path(ROOTS_DASHBOARD, '/project/create'),
  },
  question: {
    root: path(ROOTS_DASHBOARD, '/question'),
    list: path(ROOTS_DASHBOARD, '/question/list'),
    edit: path(ROOTS_DASHBOARD, '/question/edit/:questionId'),
    create: path(ROOTS_DASHBOARD, '/question/create'),
  },
  aiTools: {
    root: path(ROOTS_DASHBOARD, '/ai-tools'),
    postGenerator: path(ROOTS_DASHBOARD, '/ai-tools/post-generator'),
    postGeneratorV2: path(ROOTS_DASHBOARD, '/ai-tools/complete-post-generator'),
    playground: path(ROOTS_DASHBOARD, '/ai-tools/playground'),
    productContentGenerator: path(ROOTS_DASHBOARD, '/ai-tools/product-content-generator'),
    postCampaignPreparation: path(ROOTS_DASHBOARD, '/ai-tools/post-campaign-preparation'),
  },
  campaign: {
    root: path(ROOTS_DASHBOARD, '/campaign'),
    list: path(ROOTS_DASHBOARD, '/campaign/list'),
    edit: path(ROOTS_DASHBOARD, '/campaign/edit/:campaignId'),
    create: path(ROOTS_DASHBOARD, '/campaign/create'),
  },
  messageTemplate: {
    root: path(ROOTS_DASHBOARD, '/message-template'),
    list: path(ROOTS_DASHBOARD, '/message-template/list'),
    create: path(ROOTS_DASHBOARD, '/message-template/create'),
    edit: path(ROOTS_DASHBOARD, '/message-template/edit/:messageTemplateId'),
  },
  emailAccount: {
    root: path(ROOTS_DASHBOARD, '/email-account'),
    list: path(ROOTS_DASHBOARD, '/email-account/list'),
    create: path(ROOTS_DASHBOARD, '/email-account/create'),
    edit: path(ROOTS_DASHBOARD, '/email-account/edit/:emailAccountId'),
  },
  log: {
    root: path(ROOTS_DASHBOARD, '/log'),
    list: path(ROOTS_DASHBOARD, '/log/list'),
  },
  systemInfo: {
    root: path(ROOTS_DASHBOARD, '/system-info'),
  },
  scheduleTask: {
    root: path(ROOTS_DASHBOARD, '/schedule-task'),
  },
  course: {
    root: path(ROOTS_DASHBOARD, '/course'),
    list: path(ROOTS_DASHBOARD, '/course/list'),
    chapterList: path(ROOTS_DASHBOARD, '/course/:projectId/chapters'),
    chapterCreate: path(ROOTS_DASHBOARD, '/course/:projectId/chapters/create'),
  }
};
