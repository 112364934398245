// material
import { Container } from '@mui/material';
// components
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import PageForm from './components/PageForm';
import { useParams } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function PageCreatePage() {
  const { projectId, chapterId, sectionId } = useParams();
  return (
    <Page title="Create page">
      <Container>
        <HeaderBreadcrumbs
          heading="Create page"
          links={[
            { name: 'Courses', href: PATH_DASHBOARD.course.list },
            { name: 'Chapters', href: `${PATH_DASHBOARD.course.root}/${projectId}/chapters` },
            {
              name: 'Sections',
              href: `${PATH_DASHBOARD.course.root}/${projectId}/chapters/${chapterId}/sections`,
            },
            {
              name: 'Pages',
              href: `${PATH_DASHBOARD.course.root}/${projectId}/chapters/${chapterId}/sections/${sectionId}/pages`,
            },
            { name: 'Create page' },
          ]}
        />
        <PageForm projectId={projectId || ''} chapterId={chapterId || ''} sectionId={sectionId || ''} />
      </Container>
    </Page>
  );
}
