import { useState } from 'react';
import { Button, Container } from '@mui/material';
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../routes/paths';
import ProjectForm from './components/ProjectForm';
import { useNavigate, useParams } from 'react-router-dom';

import trash2Fill from '@iconify-icons/eva/trash-2-fill';
import { Icon } from '@iconify/react';
import DeleteElementModal from 'src/components/DeleteElementModal';
import ProjectService from 'src/services/project.service';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

export default function ProjectEditPage() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { projectId } = useParams();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);
  const [deletingProject, setDeletingProject] = useState<boolean>(false);

  const handleCloseDeleteConfirmationModal = () => {
    setOpenDeleteConfirmation(false);
  };

  const handleOpenDeleteConfirmationModal = () => {
    setOpenDeleteConfirmation(true);
  };

  const deleteProject = () => {
    setDeletingProject(true);
    ProjectService.deleteProject(projectId || '')
      .then(() => {
        enqueueSnackbar('The project has been deleted successfully.', {
          variant: 'success',
        });
        handleCloseDeleteConfirmationModal();
        navigate(PATH_DASHBOARD.project.list);
      })
      .catch((error) => {
        enqueueSnackbar("We weren't able to delete the project. Please notify an administrator.", {
          variant: 'error',
        });
      })
      .finally(() => setDeletingProject(false));
  };

  return (
    <Page title="Edit project">
      <Container>
        <HeaderBreadcrumbs
          heading="Edit project"
          links={[
            { name: 'Projects', href: PATH_DASHBOARD.project.list },
            { name: 'Edit project' },
          ]}
          action={
            <Button
              onClick={handleOpenDeleteConfirmationModal}
              color="error"
              variant="contained"
              startIcon={<Icon icon={trash2Fill} />}
            >
              Delete project
            </Button>
          }
        />
        <ProjectForm projectId={projectId} />
      </Container>
      <DeleteElementModal
        openDeleteConfirmation={openDeleteConfirmation}
        handleCloseDeleteConfirmationModal={handleCloseDeleteConfirmationModal}
        deletingElement={deletingProject}
        deleteElementHandler={deleteProject}
      />
    </Page>
  );
}
