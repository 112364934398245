import {
  Alert,
  Box,
  LinearProgress,
  Link,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { ISelectList } from 'src/interfaces/common.interface';
import { IProject } from 'src/interfaces/project.interface';
import WordpressService from 'src/services/wordpress.service';
import { getErrorMessage } from 'src/utils/request';
import { LoadingButton } from '@mui/lab';
import { IPublishPost } from 'src/interfaces/ai.interface';

// ----------------------------------------------------------------------

type WordpressPublishModalProps = {
  openModal: boolean;
  setOpenModal: any;
  title: string;
  content: string;
};

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function WordpressPublishModal({
  openModal,
  setOpenModal,
  title,
  content,
}: WordpressPublishModalProps) {
  const [loadingProjects, setLoadingProjects] = useState<boolean>(false);
  const [loadingCategories, setLoadingCategories] = useState<boolean>(false);
  const [publishingPost, setPublishingPost] = useState<boolean>(false);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [categories, setCategories] = useState<ISelectList[]>([]);
  const [requestError, setRequestError] = useState<string>();
  const [selectedProject, setSelectedProject] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [selectedPostStatus, setSelectedPostStatus] = useState<string>('');
  const [postPublished, setPostPublished] = useState<boolean>(false);

  useEffect(() => {
    loadProjects();
  }, []);

  useEffect(() => {
    if (!selectedProject) return;
    loadCategories();
  }, [selectedProject]);

  const loadProjects = () => {
    if (loadingProjects || publishingPost) return;
    setRequestError('');
    setLoadingProjects(true);
    WordpressService.getWordpressProjects()
      .then(({ data }) => {
        setProjects(data);
      })
      .catch((error) => {
        setRequestError(
          getErrorMessage(
            "We weren't able to load the projects. Please notify an administrator",
            error
          )
        );
      })
      .finally(() => setLoadingProjects(false));
  };

  const loadCategories = () => {
    if (loadingCategories || publishingPost) return;
    setRequestError('');
    setLoadingCategories(true);
    WordpressService.getWordpressCategories(selectedProject)
      .then(({ data }) => {
        setCategories(data);
      })
      .catch((error) => {
        setRequestError(
          getErrorMessage(
            "We weren't able to load the wordpress categories. Please notify an administrator",
            error
          )
        );
      })
      .finally(() => setLoadingCategories(false));
  };

  const publishPost = () => {
    if (publishingPost) return;
    setPublishingPost(true);
    setRequestError('');
    let body: IPublishPost = {
      title,
      content,
      projectId: selectedProject,
      categoryId: selectedCategory,
      postStatus: selectedPostStatus,
    };
    WordpressService.publishToWordpress(body)
      .then(() => setPostPublished(true))
      .catch((error) => {
        setRequestError(
          getErrorMessage(
            "We weren't able to publish the post. Please notify an administrator",
            error
          )
        );
      })
      .finally(() => setPublishingPost(false));
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          {requestError && (
            <Alert severity="error" style={{ marginBottom: '15px' }}>
              {requestError}
            </Alert>
          )}
          {postPublished ? (
            <>
              <Alert severity="success" style={{ marginBottom: '15px' }}>
                The post was {selectedPostStatus === 'draft' ? 'drafted' : 'published'}{' '}
                successfully!
              </Alert>
              <Typography variant="caption" component="p" mt={1} textAlign="center">
                Click <Link onClick={() => setOpenModal(false)}>here</Link> to close this window.
              </Typography>
            </>
          ) : (
            <>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Publish to Wordpress
              </Typography>
              <Typography id="modal-modal-title" variant="caption" component="p" mt={1}>
                If your wordpress project is not listed, make sure it has the wordpress' username
                and password save in the project's configuration page. Click{' '}
                <Link href="/admin/project/list" target="_blank">
                  here
                </Link>{' '}
                to go to the projects, click <Link onClick={loadProjects}>here</Link> to refresh the
                list of projects.
              </Typography>
              {loadingProjects && <LinearProgress style={{ marginTop: '10px' }} />}
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }} mt={2}>
                {!loadingProjects && (
                  <TextField
                    disabled={publishingPost}
                    error={projects.length === 0}
                    select
                    fullWidth
                    label="Project"
                    placeholder="Select project"
                    SelectProps={{ native: true }}
                    InputLabelProps={{ shrink: true }}
                    value={selectedProject}
                    helperText={projects.length === 0 && 'No projects were found'}
                    onChange={(e) => {
                      setSelectedProject(e.target.value);
                    }}
                  >
                    <option value="" disabled>
                      Select project...
                    </option>
                    {projects.map((element) => (
                      <option key={element.id} value={element.id}>
                        {element.name}
                      </option>
                    ))}
                  </TextField>
                )}
              </Stack>
              {loadingCategories && <LinearProgress style={{ marginTop: '10px' }} />}
              {!loadingCategories && selectedProject && (
                <>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }} mt={2}>
                    <TextField
                      disabled={publishingPost}
                      error={categories.length === 0}
                      select
                      fullWidth
                      label="Category"
                      placeholder="Select wordpress category"
                      SelectProps={{ native: true }}
                      InputLabelProps={{ shrink: true }}
                      value={selectedCategory}
                      helperText={categories.length === 0 && 'No categories were found'}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                      <option value="" disabled>
                        Select category...
                      </option>
                      {categories.map((element) => (
                        <option key={element.value} value={element.value}>
                          {element.text}
                        </option>
                      ))}
                    </TextField>
                  </Stack>
                  <Typography id="modal-modal-title" variant="caption" component="p" mt={1}>
                    Click <Link onClick={loadCategories}>here</Link> to reload the categories.
                  </Typography>
                </>
              )}
              {selectedCategory && !loadingCategories && (
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }} mt={2}>
                  <TextField
                    disabled={publishingPost}
                    select
                    fullWidth
                    label="Post status"
                    placeholder="Select the post status"
                    SelectProps={{ native: true }}
                    InputLabelProps={{ shrink: true }}
                    value={selectedPostStatus}
                    onChange={(e) => setSelectedPostStatus(e.target.value)}
                  >
                    <option value="" disabled>
                      Select status...
                    </option>
                    <option value="draft">Draft</option>
                    <option value="publish">Publish</option>
                  </TextField>
                </Stack>
              )}
              {selectedProject &&
                selectedCategory &&
                selectedPostStatus &&
                !loadingProjects &&
                !loadingCategories && (
                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                    <LoadingButton
                      onClick={publishPost}
                      variant="contained"
                      loading={publishingPost}
                    >
                      Publish to Wordpress
                    </LoadingButton>
                  </Box>
                )}
            </>
          )}
        </Box>
      </Modal>
    </>
  );
}
