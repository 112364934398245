import { useState } from 'react';
import { Button, Container } from '@mui/material';
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../routes/paths';
import SectionForm from './components/SectionForm';
import { useNavigate, useParams } from 'react-router-dom';

import trash2Fill from '@iconify-icons/eva/trash-2-fill';
import { Icon } from '@iconify/react';
import DeleteElementModal from 'src/components/DeleteElementModal';
import SectionService from 'src/services/section.service';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

export default function SectionEditPage() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { sectionId, projectId, chapterId } = useParams();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);
  const [deletingSection, setDeletingSection] = useState<boolean>(false);

  const handleCloseDeleteConfirmationModal = () => {
    setOpenDeleteConfirmation(false);
  };

  const handleOpenDeleteConfirmationModal = () => {
    setOpenDeleteConfirmation(true);
  };

  const deleteSection = () => {
    setDeletingSection(true);
    SectionService.deleteSection(sectionId || '')
      .then(() => {
        enqueueSnackbar('The section has been deleted successfully.', {
          variant: 'success',
        });
        handleCloseDeleteConfirmationModal();
        navigate(`${PATH_DASHBOARD.course.root}/${projectId}/chapters/${chapterId}/sections`);
      })
      .catch((error) => {
        enqueueSnackbar("We weren't able to delete the section. Please notify an administrator.", {
          variant: 'error',
        });
      })
      .finally(() => setDeletingSection(false));
  };

  return (
    <Page title="Edit section">
      <Container>
        <HeaderBreadcrumbs
          heading="Edit section"
          links={[
            { name: 'Courses', href: PATH_DASHBOARD.course.list },
            { name: 'Chapters', href: `${PATH_DASHBOARD.course.root}/${projectId}/chapters` },
            { name: 'Edit section' },
          ]}
          action={
            <Button
              onClick={handleOpenDeleteConfirmationModal}
              color="error"
              variant="contained"
              startIcon={<Icon icon={trash2Fill} />}
            >
              Delete section
            </Button>
          }
        />
        <SectionForm
          sectionId={sectionId}
          projectId={projectId || ''}
          chapterId={chapterId || ''}
        />
      </Container>
      <DeleteElementModal
        openDeleteConfirmation={openDeleteConfirmation}
        handleCloseDeleteConfirmationModal={handleCloseDeleteConfirmationModal}
        deletingElement={deletingSection}
        deleteElementHandler={deleteSection}
      />
    </Page>
  );
}
