import { IChapter, IChapterList } from 'src/interfaces/course.interface';
import axiosInstance from 'src/utils/axios';

const getChaptersUrl: string = '/Chapter/GetAll';
const deleteProjectUrl: string = '/Chapter/Delete?id=';
const getChapterByIdUrl: string = '/Chapter/getById?id=';
const createChapterUrl: string = '/Chapter/Create';
const updateChapterUrl: string = '/Chapter/Update';

const ChapterService = {
  getChapters: (page: number, elementsPerPage: number, projectId: string) =>
    axiosInstance.get<IChapterList>(
      `${getChaptersUrl}?page=${page}&pageSize=${elementsPerPage}&projectId=${projectId}`
    ),
  deleteChapter: (chapterId: string) => axiosInstance.delete(deleteProjectUrl + chapterId),
  getChapterById: (chapterId: string) => axiosInstance.get<IChapter>(getChapterByIdUrl + chapterId),
  createChapter: (chapter: IChapter) => axiosInstance.post(createChapterUrl, chapter),
  updateChapter: (chapter: IChapter) => axiosInstance.put(updateChapterUrl, chapter),
};

export default ChapterService;
