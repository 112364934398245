import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Grid,
  Stack,
  TextField,
  Alert,
  Container,
  InputAdornment,
  Tooltip,
  IconButton,
} from '@mui/material';
import LoadingScreen from 'src/components/LoadingScreen';
import { getErrorMessage } from 'src/utils/request';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { IProject, IProjectFormFields } from 'src/interfaces/project.interface';
import { Icon } from '@iconify/react';
import questionMarkCircleFill from '@iconify-icons/eva/question-mark-circle-fill';
import copyOutline from '@iconify-icons/eva/copy-outline';
import ProjectService from 'src/services/project.service';

// ----------------------------------------------------------------------

type ProjectFormProps = {
  projectId?: string;
};

const formValidationSchema: Yup.SchemaOf<IProjectFormFields> = Yup.object().shape({
  name: Yup.string().required('The project name is required.'),
  wordpressUsername: Yup.string().nullable(),
  wordpressPassword: Yup.string().nullable(),
  wordpressMainUrl: Yup.string().nullable(),
  projectType: Yup.number().required('The project type is required.'),
  coursePublicName: Yup.string().nullable(),
  twoLetterLanguageCode: Yup.string().nullable(),
  instructorsName: Yup.string().nullable(),
});

export default function ProjectForm({ projectId }: ProjectFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingProject, setLoadingProject] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [copyTooltipTitle, setCopyTooltipTitle] = useState<string>();
  const [requestError, setRequestError] = useState<string>();
  const [formInitialValues, setFormInitialValues] = useState<IProjectFormFields>({
    name: '',
    wordpressUsername: '',
    wordpressPassword: '',
    wordpressMainUrl: '',
    projectType: 0,
    coursePublicName: '',
    twoLetterLanguageCode: '',
    instructorsName: '',
  });

  const loadProject = useCallback(() => {
    if (!projectId) return;
    setLoadingProject(true);
    ProjectService.getProjectById(projectId)
      .then(({ data }) => {
        setFormInitialValues((prevState) => ({
          ...prevState,
          name: data.name,
          projectType: data.projectType,
          wordpressUsername: data.wordpressUsername || '',
          wordpressPassword: data.wordpressPassword || '',
          wordpressMainUrl: data.mainUrl || '',
          twoLetterLanguageCode: data.twoLetterLanguageCode || '',
          instructorsName: data.instructorsName || '',
          coursePublicName: data.coursePublicName || '',
          saveWordpressCredentials: !(
            !data.wordpressPassword &&
            !data.wordpressUsername &&
            !data.mainUrl
          ),
        }));
      })
      .catch((error) => {
        setDisableSubmit(true);
        setRequestError(
          getErrorMessage(
            "There was a problem and we weren't able to load the project. Please notify an administrator.",
            error
          )
        );
      })
      .finally(() => setLoadingProject(false));
  }, [projectId]);

  useEffect(() => {
    loadProject();
  }, [loadProject]);

  const onSubmit = (
    values: IProjectFormFields,
    formikHelpers: FormikHelpers<IProjectFormFields>
  ) => {
    if (!projectId) createProject(values, formikHelpers);
    else updateProject(values, formikHelpers);
  };

  const createProject = (
    values: IProjectFormFields,
    formikHelpers: FormikHelpers<IProjectFormFields>
  ) => {
    const Project: IProject = {
      name: values.name,
      wordpressUsername:
        values.projectType === 20 && values.wordpressUsername
          ? values.wordpressUsername
          : undefined,
      wordpressPassword:
        values.projectType === 20 && values.wordpressPassword
          ? values.wordpressPassword
          : undefined,
      mainUrl:
        values.projectType === 20 && values.wordpressMainUrl ? values.wordpressMainUrl : undefined,
      projectType: values.projectType,
      twoLetterLanguageCode:
        values.projectType === 30 && values.twoLetterLanguageCode
          ? values.twoLetterLanguageCode
          : undefined,
      instructorsName:
        values.projectType === 30 && values.instructorsName ? values.instructorsName : undefined,
      coursePublicName:
        values.projectType === 30 && values.coursePublicName ? values.coursePublicName : undefined,
    };

    ProjectService.createProject(Project)
      .then(() => {
        formikHelpers.resetForm();
        enqueueSnackbar('The project has been created successfully.', { variant: 'success' });
        navigate(PATH_DASHBOARD.project.list);
      })
      .catch((error) => {
        setRequestError(getErrorMessage("We weren't able to create the project", error));
      })
      .finally(() => formikHelpers.setSubmitting(false));
  };

  const updateProject = (
    values: IProjectFormFields,
    formikHelpers: FormikHelpers<IProjectFormFields>
  ) => {
    if (!projectId) return;

    const Project: IProject = {
      id: projectId,
      name: values.name,
      wordpressUsername:
        values.projectType === 20 && values.wordpressUsername
          ? values.wordpressUsername
          : undefined,
      wordpressPassword:
        values.projectType === 20 && values.wordpressPassword
          ? values.wordpressPassword
          : undefined,
      mainUrl:
        values.projectType === 20 && values.wordpressMainUrl ? values.wordpressMainUrl : undefined,
      projectType: values.projectType,
      twoLetterLanguageCode:
        values.projectType === 30 && values.twoLetterLanguageCode
          ? values.twoLetterLanguageCode
          : undefined,
      instructorsName:
        values.projectType === 30 && values.instructorsName ? values.instructorsName : undefined,
      coursePublicName:
        values.projectType === 30 && values.coursePublicName ? values.coursePublicName : undefined,
    };

    ProjectService.updateProject(Project)
      .then(() => {
        formikHelpers.resetForm();
        enqueueSnackbar('The project has been updated successfully.', {
          variant: 'success',
        });
        navigate(PATH_DASHBOARD.project.list);
      })
      .catch((error) => {
        setRequestError(getErrorMessage("We weren't able to update the project", error));
      })
      .finally(() => formikHelpers.setSubmitting(false));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    onSubmit,
  });

  const copyProjectId = () => {
    var projectIdInput = document.getElementById('projectIdInput') as HTMLInputElement;
    if (!projectIdInput) return;
    projectIdInput.focus();
    projectIdInput.select();

    try {
      var successful = document.execCommand('copy');
      if (successful) showCopyTooltip('Id copied to clipboard!');
      else showCopyTooltip('Error copying the id!');
    } catch (err) {
      console.log('ERROR COPYING PROJECT ID!', err);
      showCopyTooltip('Error copying the id!');
    }
  };

  const showCopyTooltip = (text: string) => {
    setCopyTooltipTitle(text);
    setTimeout(() => {
      setCopyTooltipTitle(undefined);
    }, 3000);
  };

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
      {loadingProject ? (
        <Container>
          <LoadingScreen
            sx={{
              ...{ width: '50%', margin: '10% auto 0 auto' },
            }}
          />
        </Container>
      ) : (
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    {projectId && (
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <TextField
                          fullWidth
                          id="projectIdInput"
                          label="Project's Id"
                          value={projectId}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip
                                  title="Id copied to clipboard!"
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  PopperProps={{
                                    disablePortal: true,
                                  }}
                                  open={!!copyTooltipTitle}
                                >
                                  <IconButton onClick={copyProjectId}>
                                    <Icon icon={copyOutline} width={24} height={24} />
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                    )}
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        label="Name"
                        {...getFieldProps('name')}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="The name of the project.">
                                <IconButton>
                                  <Icon icon={questionMarkCircleFill} width={24} height={24} />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        select
                        fullWidth
                        label="Project type"
                        placeholder="Project type"
                        {...getFieldProps('projectType')}
                        SelectProps={{ native: true }}
                        error={Boolean(touched.projectType && errors.projectType)}
                        helperText={touched.projectType && errors.projectType}
                        InputLabelProps={{ shrink: true }}
                      >
                        <option disabled value="0">
                          Select the projectType...
                        </option>
                        <option value="10">Trivia</option>
                        <option value="20">Wordpress Niche</option>
                        <option value="30">Course</option>
                      </TextField>
                    </Stack>
                    {formik.values.projectType == 20 && (
                      <>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                          <TextField
                            fullWidth
                            label="Username"
                            {...getFieldProps('wordpressUsername')}
                            error={Boolean(touched.wordpressUsername && errors.wordpressUsername)}
                            helperText={touched.wordpressUsername && errors.wordpressUsername}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Tooltip title="The Wordpress' username. It is recommended to set an exclusive wordpress user with editor role to use with the API.">
                                    <IconButton>
                                      <Icon icon={questionMarkCircleFill} width={24} height={24} />
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <TextField
                            fullWidth
                            type="password"
                            label="Password"
                            {...getFieldProps('wordpressPassword')}
                            error={Boolean(touched.wordpressPassword && errors.wordpressPassword)}
                            helperText={touched.wordpressPassword && errors.wordpressPassword}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Tooltip title="The Wordpress' password.">
                                    <IconButton>
                                      <Icon icon={questionMarkCircleFill} width={24} height={24} />
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Stack>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                          <TextField
                            fullWidth
                            label="Main Url"
                            {...getFieldProps('wordpressMainUrl')}
                            error={Boolean(touched.wordpressMainUrl && errors.wordpressMainUrl)}
                            helperText={touched.wordpressMainUrl && errors.wordpressMainUrl}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Tooltip title="The Wordpress' main URL. Example: https://www.myniche.com">
                                    <IconButton>
                                      <Icon icon={questionMarkCircleFill} width={24} height={24} />
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Stack>
                      </>
                    )}
                    {formik.values.projectType == 30 && (
                      <>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                          <TextField
                            fullWidth
                            label="Course public name"
                            {...getFieldProps('coursePublicName')}
                            error={Boolean(touched.coursePublicName && errors.coursePublicName)}
                            helperText={touched.coursePublicName && errors.coursePublicName}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Tooltip title="The course public name. This is the course's name we are going to use when creating the certificate.">
                                    <IconButton>
                                      <Icon icon={questionMarkCircleFill} width={24} height={24} />
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Stack>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                          <TextField
                            fullWidth
                            label="Course instructor(s) name"
                            {...getFieldProps('instructorsName')}
                            error={Boolean(touched.instructorsName && errors.instructorsName)}
                            helperText={touched.instructorsName && errors.instructorsName}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Tooltip title="The name of the instructors. This is the course's instructors name we are going to use when creating the certificate. Leave it blank if don't want to show the instructor(s).">
                                    <IconButton>
                                      <Icon icon={questionMarkCircleFill} width={24} height={24} />
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <TextField
                            select
                            fullWidth
                            label="Course language"
                            placeholder="Course language"
                            {...getFieldProps('twoLetterLanguageCode')}
                            SelectProps={{ native: true }}
                            error={Boolean(
                              touched.twoLetterLanguageCode && errors.twoLetterLanguageCode
                            )}
                            helperText={
                              touched.twoLetterLanguageCode && errors.twoLetterLanguageCode
                            }
                            InputLabelProps={{ shrink: true }}
                          >
                            <option disabled value="">
                              Select the question language...
                            </option>
                            <option value="en">English</option>
                            <option value="es">Spanish</option>
                          </TextField>
                        </Stack>
                      </>
                    )}
                    {requestError && <Alert severity="error">{requestError}</Alert>}
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        disabled={disableSubmit}
                      >
                        {!projectId ? 'Create project' : 'Save changes'}
                      </LoadingButton>
                    </Box>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      )}
    </>
  );
}
