import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Grid,
  Stack,
  TextField,
  Typography,
  Alert,
  Container,
  FormControlLabel,
  InputAdornment,
  Tooltip,
  IconButton,
  Switch,
} from '@mui/material';
import LoadingScreen from 'src/components/LoadingScreen';
import { getErrorMessage } from 'src/utils/request';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { IEmailAccount, IEmailAccountFormFields } from 'src/interfaces/email-account.interface';
import { Icon } from '@iconify/react';
import questionMarkCircleFill from '@iconify-icons/eva/question-mark-circle-fill';
import EmailAccountService from 'src/services/email-account.service';
import eyeFill from '@iconify-icons/eva/eye-fill';
import eyeOffFill from '@iconify-icons/eva/eye-off-fill';

// ----------------------------------------------------------------------

type EmailAccountFormProps = {
  emailAccountId?: string;
};

const formValidationSchema: Yup.SchemaOf<IEmailAccountFormFields> = Yup.object().shape({
  email: Yup.string()
    .email('Email must be a valid email address.')
    .required('Email is required.'),
  displayName: Yup.string().required('Display name is required.'),
  host: Yup.string().required('Host is required.'),
  port: Yup.number().required('Port is required.'),
  username: Yup.string().required('Username is required.'),
  password: Yup.string().required('Password is required.'),
  enableSsl: Yup.boolean().required('Your should indicate if you want to activate SSL.'),
});

export default function EmailAccountForm({ emailAccountId }: EmailAccountFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingEmailAccount, setLoadingEmailAccount] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [requestError, setRequestError] = useState<string>();
  const [showPassword, setShowPassword] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState<IEmailAccountFormFields>({
    email: '',
    displayName: '',
    host: '',
    port: 587,
    username: '',
    password: '',
    enableSsl: false,
  });

  const loadEmailAccount = useCallback(() => {
    if (!emailAccountId) return;
    setLoadingEmailAccount(true);
    EmailAccountService.getEmailAccountById(emailAccountId)
      .then(({ data }) => {
        setFormInitialValues((prevState) => {
          return {
            ...prevState,
            email: data.email,
            displayName: data.displayName,
            host: data.host,
            port: data.port,
            username: data.username,
            password: data.password,
            enableSsl: data.enableSsl,
          };
        });
      })
      .catch((error) => {
        setDisableSubmit(true);
        setRequestError(
          getErrorMessage(
            "There was a problem and we weren't able to load the email account. Please notify an administrator.",
            error
          )
        );
      })
      .finally(() => setLoadingEmailAccount(false));
  }, [emailAccountId]);

  useEffect(() => {
    loadEmailAccount();
  }, [loadEmailAccount]);

  const onSubmit = (
    values: IEmailAccountFormFields,
    formikHelpers: FormikHelpers<IEmailAccountFormFields>
  ) => {
    if (!emailAccountId) createEmailAccount(values, formikHelpers);
    else updateEmailAccount(values, formikHelpers);
  };

  const createEmailAccount = (
    values: IEmailAccountFormFields,
    formikHelpers: FormikHelpers<IEmailAccountFormFields>
  ) => {
    const emailAccount: IEmailAccount = {
      email: values.email,
      displayName: values.displayName,
      host: values.host,
      port: values.port,
      username: values.username,
      password: values.password,
      enableSsl: values.enableSsl,
    };

    EmailAccountService.createEmailAccount(emailAccount)
      .then(() => {
        formikHelpers.resetForm();
        enqueueSnackbar('The email account was created successfully.', { variant: 'success' });
        navigate(PATH_DASHBOARD.emailAccount.list);
      })
      .catch((error) => {
        setRequestError(getErrorMessage("We weren't able to create the email template.", error));
      })
      .finally(() => formikHelpers.setSubmitting(false));
  };

  const updateEmailAccount = (
    values: IEmailAccountFormFields,
    formikHelpers: FormikHelpers<IEmailAccountFormFields>
  ) => {
    if (!emailAccountId) return;

    const emailAccount: IEmailAccount = {
      id: emailAccountId,
      email: values.email,
      displayName: values.displayName,
      host: values.host,
      port: values.port,
      username: values.username,
      password: values.password,
      enableSsl: values.enableSsl,
    };

    EmailAccountService.updateEmailAccount(emailAccount)
      .then(() => {
        formikHelpers.resetForm();
        enqueueSnackbar('The email account updated successfully.', {
          variant: 'success',
        });
        navigate(PATH_DASHBOARD.emailAccount.list);
      })
      .catch((error) => {
        setRequestError(getErrorMessage("We weren't able to update the email template.", error));
      })
      .finally(() => formikHelpers.setSubmitting(false));
  };

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    onSubmit,
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values } = formik;

  return (
    <>
      {loadingEmailAccount ? (
        <Container>
          <LoadingScreen
            sx={{
              ...{ width: '50%', margin: '10% auto 0 auto' },
            }}
          />
        </Container>
      ) : (
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        label="Email address"
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="The email account from which transactional emails will be sent.">
                                <IconButton>
                                  <Icon icon={questionMarkCircleFill} width={24} height={24} />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        fullWidth
                        label="Display name"
                        {...getFieldProps('displayName')}
                        error={Boolean(touched.displayName && errors.displayName)}
                        helperText={touched.displayName && errors.displayName}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="The display name the transactional emails will have.">
                                <IconButton>
                                  <Icon icon={questionMarkCircleFill} width={24} height={24} />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        label="Host"
                        {...getFieldProps('host')}
                        error={Boolean(touched.host && errors.host)}
                        helperText={touched.host && errors.host}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="The host gived by the email service.">
                                <IconButton>
                                  <Icon icon={questionMarkCircleFill} width={24} height={24} />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        fullWidth
                        label="Port"
                        {...getFieldProps('port')}
                        error={Boolean(touched.port && errors.port)}
                        helperText={touched.port && errors.port}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="The port from which the emails will be sent. Normally it is 587.">
                                <IconButton>
                                  <Icon icon={questionMarkCircleFill} width={24} height={24} />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        label="Username"
                        {...getFieldProps('username')}
                        error={Boolean(touched.username && errors.username)}
                        helperText={touched.username && errors.username}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="The username gived by the email service.">
                                <IconButton>
                                  <Icon icon={questionMarkCircleFill} width={24} height={24} />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        label="Password"
                        {...getFieldProps('password')}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleShowPassword}
                                edge="end"
                                sx={{ marginRight: '3px' }}
                              >
                                <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                              </IconButton>
                              <Tooltip title="The password gived by the email service.">
                                <IconButton>
                                  <Icon icon={questionMarkCircleFill} width={24} height={24} />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            labelPlacement="start"
                            control={
                              <Switch {...getFieldProps('enableSsl')} checked={values.enableSsl} />
                            }
                            label={
                              <>
                                <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                                  Activate SSL
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                  Activate this option if you want to use data encryption for
                                  mailing.
                                </Typography>
                              </>
                            }
                            sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                    {requestError && <Alert severity="error">{requestError}</Alert>}
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        disabled={disableSubmit}
                      >
                        {!emailAccountId ? 'Create email account' : 'Save changes'}
                      </LoadingButton>
                    </Box>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      )}
    </>
  );
}
