import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

type MessageTemplateDeleteModalProps = {
  openDeleteConfirmation: boolean;
  handleCloseDeleteConfirmationModal: () => void;
  selectedElementName?: string;
  deletingElement: boolean;
  deleteElementHandler: () => void;
};

export default function DeleteElementModal({
  openDeleteConfirmation,
  handleCloseDeleteConfirmationModal,
  selectedElementName,
  deletingElement,
  deleteElementHandler,
}: MessageTemplateDeleteModalProps) {
  return (
    <Dialog open={openDeleteConfirmation} onClose={handleCloseDeleteConfirmationModal}>
      <DialogTitle>Warning!</DialogTitle>
      <DialogContent style={{ textAlign: 'center', marginTop: '25px' }}>
        <DialogContentText>Are you sure you want to delete this element?</DialogContentText>
        {selectedElementName && (
          <DialogContentText sx={{ margin: '10px 0' }}>
            <strong>{selectedElementName}</strong>
          </DialogContentText>
        )}
        <DialogContentText>Once deleted it cannot be recovered.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDeleteConfirmationModal}>Cancel</Button>
        <LoadingButton onClick={deleteElementHandler} autoFocus loading={deletingElement}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
