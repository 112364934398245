import { IGeneratedPost, IGeneratePost } from 'src/interfaces/ai.interface';
import axiosInstance from 'src/utils/axios';

const getAmazonProductDescriptionUrl: string = '/Ai/GetAmazonProductDescription';
const generatePostUrl: string = '/Ai/GeneratePost';

const AiService = {
  getAmazonProductDescription: (productUrl: string) =>
    axiosInstance.get<string>(`${getAmazonProductDescriptionUrl}?productUrl=${productUrl}`),

  generatePost: (post: IGeneratePost) =>
    axiosInstance.post<IGeneratedPost>(generatePostUrl, post)
};

export default AiService;
