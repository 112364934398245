import * as Yup from 'yup';
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { TextField, Alert, Stack, InputAdornment, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import useAuth from './../../../../hooks/useAuth';
import useIsMountedRef from './../../../../hooks/useIsMountedRef';
import { getErrorMessage, getUrlParamValue } from './../../../../utils/request';
import { useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from './../../../../routes/paths';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify-icons/eva/eye-fill';
import eyeOffFill from '@iconify-icons/eva/eye-off-fill';

// ----------------------------------------------------------------------

type InitialValues = {
  email: string;
  password: string;
  confirmPassword: string;
  code?: string;
};

const ResetPasswordSchema: Yup.SchemaOf<InitialValues> = Yup.object().shape({
  email: Yup.string()
    .email('You must add a valid email address.')
    .required('The email address is required.'),
  password: Yup.string()
    .required('The password is required.')
    .min(6, 'The password mus have at least 6 characters.'),
  confirmPassword: Yup.string()
    .required('The password confirmation is required.')
    .oneOf([Yup.ref('password'), null], 'The passwords must match.'),
  code: Yup.string(),
});

const token = getUrlParamValue('token') || '';

// ----------------------------------------------------------------------

export default function ResetPasswordForm() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { resetPassword } = useAuth();
  const isMountedRef = useIsMountedRef();
  const [requestError, setRequestError] = useState<string>();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const onSubmit = (values: InitialValues, formikHelpers: FormikHelpers<InitialValues>) => {
    resetPassword(values.email, values.password, values.confirmPassword, token)
      .then(() => {
        enqueueSnackbar(
          'Your password was updated successfully. You can sign in again now using your new password.',
          {
            variant: 'success',
          }
        );
        navigate(PATH_DASHBOARD.root);
      })
      .catch((error) => {
        setRequestError(
          getErrorMessage(
            "We weren't able to update your password. Please contact an administrator.",
            error
          )
        );
      })
      .finally(() => {
        if (isMountedRef.current) formikHelpers.setSubmitting(false);
      });
  };

  const formik = useFormik<InitialValues>({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
      code: token,
    },
    validationSchema: ResetPasswordSchema,
    onSubmit,
  });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            {...getFieldProps('email')}
            type="email"
            label="Email address"
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            fullWidth
            {...getFieldProps('password')}
            type={showPassword ? 'text' : 'password'}
            label="New password"
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            {...getFieldProps('confirmPassword')}
            type={showConfirmPassword ? 'text' : 'password'}
            label="Confirm new password"
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowConfirmPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {requestError && <Alert severity="error">{requestError}</Alert>}
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Send
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
