import { IQuestion, IQuestionList } from 'src/interfaces/question.interface';
import axiosInstance from 'src/utils/axios';

const getQuestionsUrl: string = '/Question/GetAll';
const createQuestionUrl: string = '/Question/Create';
const updateQuestionUrl: string = '/Question/Update';
const getQuestionByIdUrl: string = '/Question/GetById?id=';
const deleteQuestionUrl: string = '/Question/Delete?id=';

const QuestionService = {
  getQuestions: (page: number, elementsPerPage: number, language: string, projectId: string, active: number) =>
    axiosInstance.get<IQuestionList>(
      `${getQuestionsUrl}?page=${page}&pageSize=${elementsPerPage}&language=${language}&projectId=${projectId}&active=${active}`
    ),

  createQuestion: (question: FormData) => axiosInstance.post(createQuestionUrl, question),

  updateQuestion: (question: FormData) => axiosInstance.put(updateQuestionUrl, question),

  getQuestionById: (questionId: string) =>
    axiosInstance.get<IQuestion>(getQuestionByIdUrl + questionId),

  deleteQuestion: (questionId: string) => axiosInstance.delete(deleteQuestionUrl + questionId),
};

export default QuestionService;
