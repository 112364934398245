// material
import { Container } from '@mui/material';
// components
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import ProjectForm from './components/ProjectForm';

// ----------------------------------------------------------------------

export default function ProjectCreatePage() {
  return (
    <Page title="Create project">
      <Container>
        <HeaderBreadcrumbs
          heading="Create project"
          links={[
            { name: 'Projects', href: PATH_DASHBOARD.project.list },
            { name: 'Create project' },
          ]}
        />
        <ProjectForm />
      </Container>
    </Page>
  );
}
