import { ISelectList } from 'src/interfaces/common.interface';
import { IUser, IUserFormFields, IUserList } from 'src/interfaces/user.interface';
import axiosInstance from 'src/utils/axios';

const getUsersUrl: string = '/User/getUsers';
const createUserUrl: string = '/User/createUser';
const updateUserUrl: string = '/User/updateUserData';
const getUserByIdUrl: string = '/User/getUserById?id=';
const getUserProfileSelectListUrl: string = '/User/getUserProfileSelectList';

const UserService = {
  getUsers: (page: number, elementsPerPage: number) =>
    axiosInstance.get<IUserList>(`${getUsersUrl}?page=${page}&pageSize=${elementsPerPage}`),

  createUser: (user: IUserFormFields) => {
    const body = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      userProfileId: user.userProfileId,
      password: user.password || '',
      generateRandomPassword: user.generateRandomPassword
    };

    return axiosInstance.post(createUserUrl, body);
  },

  updateUser: (user: IUserFormFields, userId: string) => {
    const body = {
      id: userId,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      userProfileId: user.userProfileId
    };

    return axiosInstance.put(updateUserUrl, body);
  },

  getUserById: (userId: string) => {
    return axiosInstance.get<IUser>(getUserByIdUrl + userId);
  },

  getUserProfileSelectList: () => {
    return axiosInstance.get<ISelectList[]>(getUserProfileSelectListUrl);
  }
};

export default UserService;
