import { ILogList } from 'src/interfaces/log.interface';
import axiosInstance from 'src/utils/axios';

const getLogsUrl: string = '/Log/GetAll';

const LogService = {
  getLogs: (page: number, elementsPerPage: number) =>
    axiosInstance.get<ILogList>(`${getLogsUrl}?page=${page}&pageSize=${elementsPerPage}`)
};

export default LogService;
