import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Icon } from '@iconify/react';
import plusFill from '@iconify-icons/eva/plus-fill';
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { Link as RouterLink } from 'react-router-dom';

import { PATH_DASHBOARD } from '../../../routes/paths';
import { useCallback, useEffect, useState } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { customLabelDisplayedRows } from 'src/utils/common';
import { getErrorMessage } from 'src/utils/request';
import { IQuestionList, IQuestion } from 'src/interfaces/question.interface';
import QuestionService from 'src/services/question.service';
import QuestionListHead from './components/QuestionListHead';
import QuestionMoreMenu from './components/QuestionMoreMenu';
import { useSnackbar } from 'notistack';
import DeleteElementModal from 'src/components/DeleteElementModal';
import LoadingData from 'src/components/LoadingData';
import checkMark from '@iconify-icons/eva/checkmark-square-2-fill';
import closeMark from '@iconify-icons/eva/close-square-fill';
import arrowIosDownwardFill from '@iconify-icons/eva/arrow-ios-downward-fill';
import searchFill from '@iconify-icons/eva/search-fill';
import ProjectService from 'src/services/project.service';
import { ISelectList } from 'src/interfaces/common.interface';
import { API_URL } from 'src/app.config';
import default_image from './../../../assets/default-image.png';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'image', label: 'Image', alignRight: false },
  { id: 'value', label: 'Value', alignRight: false },
  { id: 'project', label: 'Project', alignRight: false },
  { id: 'options', label: 'Options', alignRight: false },
  { id: 'lang', label: 'Language', alignRight: false },
  { id: 'isActive', label: 'Is active', alignRight: false },
  { id: 'settings', label: 'Settings', alignRight: true },
];

// ----------------------------------------------------------------------

export default function QuestionListPage() {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [questions, setQuestions] = useState<IQuestionList>();
  const [loadingQuestions, setLoadingQuestions] = useState<boolean>(true);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState(0);
  const [requestError, setRequestError] = useState<string>();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);
  const [selectedQuestion, setSelectedQuestion] = useState<IQuestion>();
  const [deletingQuestion, setDeletingQuestion] = useState<boolean>(false);
  const [projects, setProjects] = useState<ISelectList[]>([]);

  const [selectedProjectFilter, setSelectedProjectFilter] = useState<string>('');
  const [selectedLangFilter, setSelectedLangFilter] = useState<string>('');
  const [selectedActiveFilter, setSelectedActiveFilter] = useState<number>(-1);

  const loadQuestions = useCallback(
    (projectId: string, lang: string, active: number) => {
      setLoadingQuestions(true);
      QuestionService.getQuestions(page, rowsPerPage, lang, projectId, active)
        .then(({ data }) => {
          setQuestions(data);
        })
        .catch((error) => {
          setRequestError(
            getErrorMessage(
              'There was a problem while loading the questions. Please notify an administrator',
              error
            )
          );
        })
        .finally(() => setLoadingQuestions(false));
    },
    [page, rowsPerPage]
  );

  useEffect(() => {
    loadQuestions(selectedProjectFilter, selectedLangFilter, selectedActiveFilter);
  }, [loadQuestions, selectedProjectFilter, selectedLangFilter, selectedActiveFilter]);

  useEffect(() => {
    getProjects();
  }, []);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDeleteConfirmationModal = (question: IQuestion) => {
    setSelectedQuestion(question);
    setOpenDeleteConfirmation(true);
  };

  const handleCloseDeleteConfirmationModal = () => {
    setSelectedQuestion(undefined);
    setOpenDeleteConfirmation(false);
  };

  const deleteQuestion = () => {
    if (!selectedQuestion) return;
    setDeletingQuestion(true);
    QuestionService.deleteQuestion(selectedQuestion.id || '')
      .then(() => {
        enqueueSnackbar('The question has been deleted succesfully.', {
          variant: 'success',
        });
        if (!questions) return;
        const currentList = { ...questions };
        const updatedList = currentList.data.filter((x) => x.id !== selectedQuestion.id);
        currentList.data = updatedList;
        setQuestions(currentList);
        handleCloseDeleteConfirmationModal();
      })
      .catch((error) => {
        enqueueSnackbar('It was not possible to delete the question.', { variant: 'error' });
      })
      .finally(() => setDeletingQuestion(false));
  };

  const getProjects = () => {
    ProjectService.getProjectSelectList()
      .then(({ data }) => {
        setProjects(data);
      })
      .catch((error) => {
        setRequestError(
          getErrorMessage(
            "We weren't able to load the projects. Please notify an administrator.",
            error
          )
        );
      });
  };

  const handleFilterApplied = () => {
    setPage(0);
  };

  return (
    <Page title="Questions">
      <Container>
        <HeaderBreadcrumbs
          heading="Questions"
          links={[]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.question.create}
              startIcon={<Icon icon={plusFill} />}
            >
              New question
            </Button>
          }
        />
        {requestError && (
          <Alert style={{ marginBottom: '1em' }} severity="error">
            {requestError}
          </Alert>
        )}
        <LoadingData show={loadingQuestions} />
        <Accordion
          style={{
            marginBottom: theme.spacing(2),
            paddingTop: theme.spacing(1),
            borderRadius: 16,
            borderTopColor: 'transparent',
          }}
        >
          <AccordionSummary
            expandIcon={<Icon icon={arrowIosDownwardFill} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              variant="h5"
              gutterBottom
              component="div"
              style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
            >
              <Icon icon={searchFill} style={{ marginRight: theme.spacing(1) }} /> Filter
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
              <TextField
                select
                fullWidth
                label="Project"
                placeholder="Project"
                SelectProps={{ native: true }}
                InputLabelProps={{ shrink: true }}
                value={selectedProjectFilter}
                onChange={(e) => {
                  setSelectedProjectFilter(e.target.value);
                  handleFilterApplied();
                }}
              >
                <option value="">All projects</option>
                {projects.map((element) => (
                  <option key={element.value} value={element.value}>
                    {element.text}
                  </option>
                ))}
              </TextField>
              <TextField
                select
                fullWidth
                label="Language"
                placeholder="Language"
                SelectProps={{ native: true }}
                InputLabelProps={{ shrink: true }}
                value={selectedLangFilter}
                onChange={(e) => {
                  setSelectedLangFilter(e.target.value);
                  handleFilterApplied();
                }}
              >
                <option value="">All languages</option>
                <option value="en">English</option>
                <option value="es">Spanish</option>
              </TextField>
              <TextField
                select
                fullWidth
                label="Active"
                placeholder="Active"
                SelectProps={{ native: true }}
                InputLabelProps={{ shrink: true }}
                value={selectedActiveFilter}
                onChange={(e) => {
                  setSelectedActiveFilter(e.target.value as unknown as number);
                  handleFilterApplied();
                }}
              >
                <option value="-1">All</option>
                <option value="1">True</option>
                <option value="0">False</option>
              </TextField>
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table style={{ marginTop: 15 }}>
                <QuestionListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {questions?.data.map((row) => {
                    const {
                      id,
                      value,
                      projectName,
                      isActive,
                      option1,
                      option2,
                      option3,
                      option4,
                      twoLetterLanguageCode,
                      pictureId,
                    } = row;
                    return (
                      <TableRow hover key={id}>
                        <TableCell align="left">
                          {pictureId ? (
                            <img
                              src={`${API_URL}File/GetPicture?id=${pictureId}`}
                              alt={value}
                              style={{ maxHeight: 75 }}
                            />
                          ) : (
                            <img
                              src={default_image}
                              alt={value}
                              style={{ maxHeight: 75, opacity: 0.5 }}
                            />
                          )}
                        </TableCell>
                        <TableCell align="left">{value}</TableCell>
                        <TableCell align="left">{projectName || ''}</TableCell>
                        <TableCell align="left">
                          <ul>
                            <li>{option1}</li>
                            <li>{option2}</li>
                            <li>{option3}</li>
                            <li>{option4}</li>
                          </ul>
                        </TableCell>
                        <TableCell align="left">{twoLetterLanguageCode}</TableCell>
                        <TableCell align="left">
                          <Icon
                            color={isActive ? theme.palette.success.main : theme.palette.error.main}
                            icon={isActive ? checkMark : closeMark}
                            width={24}
                            height={24}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <QuestionMoreMenu
                            onDelete={() => handleOpenDeleteConfirmationModal(row)}
                            questionId={id || ''}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {questions?.data.length === 0 && (
                    <TableRow style={{ height: 53 }}>
                      <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                        We couldn't find any question. Please create a new one using the "New
                        question" button.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            labelDisplayedRows={customLabelDisplayedRows}
            labelRowsPerPage="Questions per page:"
            rowsPerPageOptions={[10, 50, 100]}
            component="div"
            count={questions?.total || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <DeleteElementModal
        openDeleteConfirmation={openDeleteConfirmation}
        handleCloseDeleteConfirmationModal={handleCloseDeleteConfirmationModal}
        selectedElementName={selectedQuestion?.value}
        deletingElement={deletingQuestion}
        deleteElementHandler={deleteQuestion}
      />
    </Page>
  );
}
