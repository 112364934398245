import {
  Alert,
  Button,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import { Icon } from '@iconify/react';
import plusFill from '@iconify-icons/eva/plus-fill';
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { Link as RouterLink } from 'react-router-dom';

import { PATH_DASHBOARD } from '../../../routes/paths';
import { useCallback, useEffect, useState } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { customLabelDisplayedRows } from 'src/utils/common';
import { getErrorMessage } from 'src/utils/request';
import { IProjectList, IProject } from 'src/interfaces/project.interface';
import ProjectService from 'src/services/project.service';
import ProjectListHead from './components/ProjectListHead';
import ProjectMoreMenu from './components/ProjectMoreMenu';
import { useSnackbar } from 'notistack';
import DeleteElementModal from 'src/components/DeleteElementModal';
import LoadingData from 'src/components/LoadingData';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'id', label: 'Id', alignRight: false },
  { id: 'projectType', label: 'Type', alignRight: false },
  { id: 'options', label: 'Settings', alignRight: true },
];

const parseType = (type: number) => {
  switch (type) {
    case 10:
      return 'Trivia';
    case 20:
      return 'Wordpress Niche';
    case 30:
      return 'Course';
    default:
      return 'Not defined';
  }
};

// ----------------------------------------------------------------------

export default function ProjectListPage() {
  const { enqueueSnackbar } = useSnackbar();
  const [projects, setProjects] = useState<IProjectList>();
  const [loadingProjects, setLoadingProjects] = useState<boolean>(true);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState(0);
  const [requestError, setRequestError] = useState<string>();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);
  const [selectedProject, setSelectedProject] = useState<IProject>();
  const [deletingProject, setDeletingProject] = useState<boolean>(false);

  const loadProjects = useCallback(() => {
    setLoadingProjects(true);
    ProjectService.getProjects(page, rowsPerPage)
      .then(({ data }) => {
        setProjects(data);
      })
      .catch((error) => {
        setRequestError(
          getErrorMessage(
            'There was a problem while loading the projects. Please notify an administrator.',
            error
          )
        );
      })
      .finally(() => setLoadingProjects(false));
  }, [page, rowsPerPage]);

  useEffect(() => {
    loadProjects();
  }, [loadProjects]);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDeleteConfirmationModal = (project: IProject) => {
    setSelectedProject(project);
    setOpenDeleteConfirmation(true);
  };

  const handleCloseDeleteConfirmationModal = () => {
    setSelectedProject(undefined);
    setOpenDeleteConfirmation(false);
  };

  const deleteProject = () => {
    if (!selectedProject) return;
    setDeletingProject(true);
    ProjectService.deleteProject(selectedProject.id || '')
      .then(() => {
        enqueueSnackbar('The project has been deleted succesfully.', {
          variant: 'success',
        });
        if (!projects) return;
        const currentList = { ...projects };
        const updatedList = currentList.data.filter((x) => x.id !== selectedProject.id);
        currentList.data = updatedList;
        setProjects(currentList);
        handleCloseDeleteConfirmationModal();
      })
      .catch((error) => {
        enqueueSnackbar('It was not possible to delete the project.', { variant: 'error' });
      })
      .finally(() => setDeletingProject(false));
  };

  return (
    <Page title="Projects">
      <Container>
        <HeaderBreadcrumbs
          heading="Project"
          links={[]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.project.create}
              startIcon={<Icon icon={plusFill} />}
            >
              New project
            </Button>
          }
        />
        {requestError && (
          <Alert style={{ marginBottom: '1em' }} severity="error">
            {requestError}
          </Alert>
        )}
        <LoadingData show={loadingProjects} />
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table style={{ marginTop: 15 }}>
                <ProjectListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {projects?.data.map((row) => {
                    const { id, name, projectType } = row;
                    return (
                      <TableRow hover key={id}>
                        <TableCell align="left">{name}</TableCell>
                        <TableCell align="left">{id}</TableCell>
                        <TableCell align="left">{parseType(projectType)}</TableCell>
                        <TableCell align="right">
                          <ProjectMoreMenu
                            onDelete={() => handleOpenDeleteConfirmationModal(row)}
                            projectId={id || ''}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {projects?.data.length === 0 && (
                    <TableRow style={{ height: 53 }}>
                      <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                        We couldn't find any project. Please create a new one using the "New
                        project" button.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            labelDisplayedRows={customLabelDisplayedRows}
            labelRowsPerPage="Projects per page:"
            rowsPerPageOptions={[10, 50, 100]}
            component="div"
            count={projects?.total || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <DeleteElementModal
        openDeleteConfirmation={openDeleteConfirmation}
        handleCloseDeleteConfirmationModal={handleCloseDeleteConfirmationModal}
        selectedElementName={selectedProject?.name}
        deletingElement={deletingProject}
        deleteElementHandler={deleteProject}
      />
    </Page>
  );
}
