import { useEffect, useState } from 'react';
import { Alert, Container } from '@mui/material';
import { SITE_URL } from 'src/app.config';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import LoadingScreen from 'src/components/LoadingScreen';
import Page from 'src/components/Page';
import { getErrorMessage } from 'src/utils/request';
import ScheduleTaskService from 'src/services/schedule-task.service';

export default function ScheduleTaskPage() {
  const [iframeUrl, setIframeUrl] = useState<string>('');
  const [requestError, setRequestError] = useState<string>();
  const [loadingData, setLoadingData] = useState<boolean>(true);

  useEffect(() => {
    ScheduleTaskService.getPermission()
      .then(() => {
        setIframeUrl(SITE_URL + '/hangfire');
      })
      .catch((error) => {
        setRequestError(
          getErrorMessage(
            'There was a problem loading the schedule task. Please contact an administrator.',
            error
          )
        );
      })
      .finally(() => setLoadingData(false));
  }, []);

  return (
    <Page title="Schedule task">
      <Container>
        <HeaderBreadcrumbs heading="Schedule task" links={[]} />
        {requestError && (
          <Alert style={{ marginBottom: '1em' }} severity="error">
            {requestError}
          </Alert>
        )}
        {loadingData && (
          <Container>
            <LoadingScreen
              sx={{
                ...{ width: '50%', margin: '10% auto 0 auto' },
              }}
            />
          </Container>
        )}
        {iframeUrl && (
          <iframe
            style={{ borderWidth: '0' }}
            width="100%"
            height="816px"
            title="Schedule Task"
            src={iframeUrl}
          ></iframe>
        )}
      </Container>
    </Page>
  );
}
