// material
import { Container } from '@mui/material';
// components
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
import CampaignForm from './components/CampaignForm';

// ----------------------------------------------------------------------

export default function CampaignCreatePage() {
  return (
    <Page title="Create campaign">
      <Container>
        <HeaderBreadcrumbs
          heading="Create campaign"
          links={[
            { name: 'Campaigns', href: PATH_DASHBOARD.campaign.list },
            { name: 'Create campaign' },
          ]}
        />
        <CampaignForm />
      </Container>
    </Page>
  );
}
