import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Card,
  Container,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import arrowDownFill from '@iconify-icons/eva/arrow-down-fill';
import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import BadWordsModal from './components/BadWordsModal';
import badWordsFile from './assets/bad-words.txt';
import badTitleWordsFile from './assets/bad-title-words.txt';
import { LoadingButton } from '@mui/lab';
import CampaignService from 'src/services/campaign.service';
import { getErrorMessage } from 'src/utils/request';
import BadTitleWordsModal from './components/BadTitleWordsModal';

// ----------------------------------------------------------------------

const getTitleArray = (titles: string) => {
  return titles.split('\n');
};

const escapeRegExp = (stringToGoIntoTheRegex: string) => {
  return stringToGoIntoTheRegex.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
};

// ----------------------------------------------------------------------

export default function PostCampaignPreparationPage() {
  const theme = useTheme();
  const [error, setError] = useState<string>('');
  const [titlesList, setTitlesList] = useState<string>('');
  const [inputMessage, setInputMessage] = useState<string>('');
  const [instructionsExpanded, setInstructionsExpanded] = useState<boolean>(false);
  const [openEditBadWordsModal, setOpenEditBadWordsModal] = useState<boolean>(false);
  const [openEditBadTitleWordsModal, setOpenEditBadTitleWordsModal] = useState<boolean>(false);
  const [badWords, setBadWords] = useState<string[]>([]);
  const [badTitleWords, setBadTitleWords] = useState<string[]>([]);
  const [customWord, setCustomWord] = useState<string>('');
  const [generatingCategories, setGeneratingCategories] = useState<boolean>(false);
  const [rewritingTitles, setRewritingTitles] = useState<boolean>(false);
  const [assigningCategories, setAssigningCategories] = useState<boolean>(false);
  const [categories, setCategories] = useState<string>('');
  const [replaceFrom, setReplaceFrom] = useState<string>('');
  const [replaceTo, setReplaceTo] = useState<string>('');

  useEffect(() => {
    loadBadWords();
    loadBadTitleWords();
  }, []);

  const loadBadWords = () => {
    fetch(badWordsFile)
      .then((r) => r.text())
      .then((text) => {
        setBadWords(text.replace(/\r/g, '').split('\n'));
      })
      .catch((error) => console.log('ERROR LOADING BAD WORDS FILE', error));
  };

  const loadBadTitleWords = () => {
    fetch(badTitleWordsFile)
      .then((r) => r.text())
      .then((text) => {
        setBadTitleWords(
          text
            .replace(/\r/g, '')
            .split('\n')
            .sort((a, b) => b.length - a.length)
        );
      })
      .catch((error) => console.log('ERROR LOADING BAD TITLE WORDS FILE', error));
  };

  const handleInputChange = (value: string) => {
    setTitlesList(value);
    setInputMessage(value.split('\n').length.toString() + ' lines.');
  };

  const removeEmptyLines = () => {
    if (!titlesList) return;
    let titleArray = getTitleArray(titlesList);
    var filtered = titleArray.filter((x) => x.length !== 0);
    saveFiltered(filtered, titleArray);
  };

  const removeDuplicates = () => {
    if (!titlesList) return;
    let titleArray = getTitleArray(titlesList);
    var filtered = titleArray.filter((item, pos) => titleArray.indexOf(item) == pos);
    saveFiltered(filtered, titleArray);
  };

  const saveFiltered = (filtered: string[], titleArray: string[], extraText: string = '') => {
    setTitlesList(filtered.join('\n'));
    setInputMessage(
      `${titleArray.length - filtered.length} lines removed. ${
        filtered.length
      } lines total. ${extraText}`
    );
  };

  const removeBadWords = () => {
    if (!badWords || !titlesList) return;
    let titleArray = getTitleArray(titlesList);
    var filtered = titleArray.filter((x) => {
      let wordArray = x.split(' ').map((y) => y.toLowerCase());
      return !wordArray.some((y) => badWords.indexOf(y) > -1);
    });
    saveFiltered(filtered, titleArray);
  };

  const removeBadTitleWords = () => {
    if (!badTitleWords || !titlesList) return;
    let titleArray = getTitleArray(titlesList);
    let changeCount = 0;

    let filtered = titleArray.map((x) => {
      // Everythin equal
      let result = x.toLowerCase();
      // Removing dash
      result = result.replace(new RegExp(escapeRegExp(' - '), 'g'), ': ');
      result = result.replace(new RegExp(escapeRegExp(' – '), 'g'), ': ');
      // Remove leading and trailing quotes
      result = result.replace(/^"(.*)"$/, '$1');
      // Remove end dot
      result =
        result.charAt(result.length - 1) === '.' ? result.substring(0, result.length - 1) : result;
      // Remove words from bad title words list
      badTitleWords.forEach((badTitleWord) => {
        let re = new RegExp(escapeRegExp(badTitleWord), 'g');
        result = result.replace(re, '');
      });
      let hasSpaMark = result.charAt(0) == '¿' || result.charAt(0) == '¡';
      // Make sure first letter is uppercase
      if (hasSpaMark) result = result.charAt(0) + result.charAt(1).toUpperCase() + result.slice(2);
      else result = result.charAt(0).toUpperCase() + result.slice(1);

      // Uppercase after specific character
      let charsToUppercaseAfter = ['?', ':'];
      for (let i = 0; i < charsToUppercaseAfter.length; i++) {
        let charIndex = result.indexOf(charsToUppercaseAfter[i]);
        if (charIndex > -1 && charIndex < result.length - 1)
          result =
            result.slice(0, charIndex + 2) +
            result.charAt(charIndex + 2).toUpperCase() +
            result.slice(charIndex + 3);
      }

      if (x !== result) changeCount++;

      return result;
    });

    saveFiltered(filtered, titleArray, changeCount + ' lines updated.');
  };

  const removeCustomWord = () => {
    if (!customWord || !titlesList) return;
    let titleArray = getTitleArray(titlesList);
    var filtered = titleArray.filter(
      (x) => x.toLowerCase().indexOf(customWord.toLowerCase()) === -1
    );
    saveFiltered(filtered, titleArray);
  };

  const rewriteTitles = () => {
    if (rewritingTitles || !titlesList) return;
    setRewritingTitles(true);
    setError('');
    CampaignService.rewriteTitles(titlesList.split('\n'))
      .then(({ data }) => {
        saveFiltered(data.result.split('\n'), titlesList.split('\n'), `Request cost ${data.cost}`);
      })
      .catch((error) => {
        setError(getErrorMessage("We weren't able to rewrite the titles", error));
      })
      .finally(() => setRewritingTitles(false));
  };

  const replaceText = () => {
    if (!titlesList) return;
    let titleArray = getTitleArray(titlesList);
    let changeCount = 0;
    let parsedArray = titleArray.map((x) => {
      let re = new RegExp(escapeRegExp(replaceFrom), 'g');
      let result = x.replace(re, replaceTo);
      if (result !== x) changeCount++;
      return result;
    });
    saveFiltered(parsedArray, titleArray, changeCount + ' lines updated.');
  };

  const generateCategoryIdeas = () => {
    if (generatingCategories || !titlesList) return;
    setGeneratingCategories(true);
    setError('');
    CampaignService.getCategoryIdeas(titlesList.split('\n'))
      .then(({ data }) => {
        setCategories(data.replace(/\n/g, ','));
      })
      .catch((error) => {
        setError(getErrorMessage("We weren't able to get the categories", error));
      })
      .finally(() => setGeneratingCategories(false));
  };

  const assignCategoriesToTitles = () => {
    if (assigningCategories || !titlesList) return;
    setAssigningCategories(true);
    setError('');
    CampaignService.assignCategoriesToTitles(titlesList.split('\n'), categories.split('\n'))
      .then(({ data }) => {
        saveFiltered(data.result.split('\n'), titlesList.split('\n'), `Request cost ${data.cost}`);
      })
      .catch((error) => {
        setError(getErrorMessage("We weren't able to assign the categories", error));
      })
      .finally(() => setAssigningCategories(false));
  };

  return (
    <Page title="Campaign preparation tool">
      <Container>
        <HeaderBreadcrumbs heading="Campaign preparation" links={[]} />
        {error && (
          <Alert style={{ marginBottom: '1em' }} severity="error">
            {error}
          </Alert>
        )}
        <Typography variant="body2" paragraph>
          This tool allows you to prepare the list of titles for an automated post campaign. Follow
          the steps to get a valid and optimized list of titles before creating a campaign.
        </Typography>
        <Accordion
          onChange={() => setInstructionsExpanded((prev) => !prev)}
          expanded={instructionsExpanded}
          style={{
            marginBottom: theme.spacing(2),
            paddingTop: theme.spacing(1),
            borderRadius: 16,
          }}
        >
          <AccordionSummary
            expandIcon={<Icon icon={arrowDownFill} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
            >
              Instructions
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack>
              <Typography variant="h6" paragraph mb={0}>
                Step 1. Configure your post
              </Typography>
              <Typography variant="body2" paragraph mt={0}>
                Use a prevent canibalization tool to remove keyword alike titles from your list of
                keywords. The following Google Colab is working for accomplish this:{' '}
                <Link
                  target="_blank"
                  href="https://colab.research.google.com/drive/1bE5Hp4zeNV_TqYE7SxBmi8kXzmQ5calw#scrollTo=W7h5cg5sUa9P"
                >
                  Elimina KEYs intención de busqueda similar y Categoriza
                </Link>
              </Typography>
            </Stack>
            <Stack>
              <Typography variant="h6" paragraph mb={0}>
                Step 2. Clean keyword/title list
              </Typography>
              <Typography variant="body2" paragraph mt={0}>
                Paste the keyword/title list into the input and use the different buttons to clean
                the data.
              </Typography>
            </Stack>
            <Stack>
              <Typography variant="h6" paragraph mb={0}>
                Step 3. Assign a category to each title
              </Typography>
              <Typography variant="body2" paragraph mt={0}>
                Paste the list in a Google Spreadsheet and use the Sheet GPT extension to assign a
                category to each title with the formula: =GPT_CLASSIFY(A2,
                "Comma,sepparated,categories")
              </Typography>
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Card sx={{ p: 3 }}>
          {inputMessage && (
            <Alert style={{ marginBottom: '2em' }} severity="info">
              {inputMessage}
            </Alert>
          )}
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} justifyContent="space-between">
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }} width="100%">
              <TextField
                disabled={rewritingTitles || generatingCategories}
                fullWidth
                multiline
                rows={40}
                label="Titles list"
                value={titlesList}
                onChange={(e) => handleInputChange(e.target.value)}
                helperText={inputMessage}
              />
            </Stack>
            <Stack direction="column" spacing={3} alignItems="start" width="30%">
              <Button fullWidth variant="contained" size="small" onClick={removeEmptyLines}>
                1. Remove empty lines
              </Button>
              <Button fullWidth variant="contained" size="small" onClick={removeDuplicates}>
                2. Remove duplicated lines
              </Button>
              <Stack direction="column">
                <Typography variant="caption" paragraph textAlign="left">
                  3. Remove keywords/titles which have at least one word from the list of undesired
                  words. You can see the list{' '}
                  <Link onClick={() => setOpenEditBadWordsModal(true)}>here</Link>.
                </Typography>
                <Button fullWidth variant="contained" size="small" onClick={removeBadWords}>
                  3. Remove bad words
                </Button>
              </Stack>
              <Stack direction="column">
                <Typography variant="caption" paragraph textAlign="left">
                  4. Remove keywords/titles which have the text in the input. This is not case
                  sensitive.
                </Typography>
                <TextField
                  style={{ marginBottom: 10 }}
                  fullWidth
                  label="Remove lines containing..."
                  value={customWord}
                  onChange={(e) => setCustomWord(e.target.value)}
                />
                <Button fullWidth variant="contained" size="small" onClick={removeCustomWord}>
                  4. Remove custom word
                </Button>
              </Stack>
              <Stack direction="column">
                <Typography variant="caption" paragraph textAlign="left">
                  5. Replace a text with another text. This is case sensitive.
                </Typography>
                <TextField
                  style={{ marginBottom: 10 }}
                  fullWidth
                  label="Find..."
                  value={replaceFrom}
                  onChange={(e) => setReplaceFrom(e.target.value)}
                />
                <TextField
                  style={{ marginBottom: 10 }}
                  fullWidth
                  label="Replace..."
                  value={replaceTo}
                  onChange={(e) => setReplaceTo(e.target.value)}
                />
                <Button fullWidth variant="contained" size="small" onClick={replaceText}>
                  5. Replace text
                </Button>
              </Stack>
              <Stack direction="column">
                <Typography variant="caption" paragraph textAlign="left"></Typography>
                <Stack direction="column">
                  <Typography variant="caption" paragraph textAlign="left">
                    6. Clean AI generated titles. This will remove quotes, ending points and text
                    from the list of undesired title words. You can see the list{' '}
                    <Link onClick={() => setOpenEditBadTitleWordsModal(true)}>here</Link>.
                  </Typography>
                  <Button fullWidth variant="contained" size="small" onClick={removeBadTitleWords}>
                    6. Clean titles
                  </Button>
                </Stack>
              </Stack>
              <Stack direction="column">
                <Typography variant="caption" paragraph textAlign="left">
                  7. If necessary, you can get category ideas from the AI.
                </Typography>
                <TextField
                  style={{ marginBottom: 10 }}
                  fullWidth
                  multiline
                  rows={5}
                  label="List of categories"
                  value={categories}
                  onChange={(e) => setCategories(e.target.value)}
                />
                <LoadingButton
                  fullWidth
                  variant="contained"
                  size="small"
                  onClick={generateCategoryIdeas}
                  style={{ marginBottom: 5 }}
                  loading={generatingCategories}
                >
                  7. Generate category ideas
                </LoadingButton>
                {/* <LoadingButton
                  loading={assigningCategories}
                  fullWidth
                  variant="contained"
                  size="small"
                  onClick={assignCategoriesToTitles}
                >
                  6.2. Assign categories
                </LoadingButton> */}
              </Stack>
            </Stack>
          </Stack>
        </Card>
      </Container>
      <BadWordsModal
        badWords={badWords.join('\r\n')}
        openModal={openEditBadWordsModal}
        setOpenModal={setOpenEditBadWordsModal}
      />
      <BadTitleWordsModal
        badTitleWords={badTitleWords.join('\r\n')}
        openModal={openEditBadTitleWordsModal}
        setOpenModal={setOpenEditBadTitleWordsModal}
      />
    </Page>
  );
}
