import { LabelDisplayedRowsArgs } from '@mui/material';

export const customLabelDisplayedRows = ({ from, to, count }: LabelDisplayedRowsArgs) => {
  return `${from}-${to} from ${count !== -1 ? count : `more than ${to}`}`;
};

export const updateObject = (oldObject: any, updatedProperties: any) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const getNextPageDefaultValue = (int: number) => {
  return Math.floor(int / 10) * 10 + 10;
};
