import { IUserProfile, IUserProfileList } from 'src/interfaces/user-profile.interface';
import axiosInstance from 'src/utils/axios';

const getUserProfilesUrl: string = '/UserProfile/getUserProfiles';
const getUserProfileByIdUrl: string = '/UserProfile/getUserProfileById?id=';
const getUserProfileRolesUrl: string = '/UserProfile/getUserProfileRoles';
const createUserProfileUrl: string = '/UserProfile/createUserProfile';
const updateUserProfileUrl: string = '/UserProfile/updateUserProfile';

const UserProfileService = {
  getUserProfiles: (page: number, elementsPerPage: number) =>
    axiosInstance.get<IUserProfileList>(
      `${getUserProfilesUrl}?page=${page}&pageSize=${elementsPerPage}`
    ),

  getUserProfileById: (userProfileId: string) => {
    return axiosInstance.get<IUserProfile>(getUserProfileByIdUrl + userProfileId);
  },

  getUserProfileRoles: () => {
    return axiosInstance.get<IUserProfile>(getUserProfileRolesUrl);
  },

  createUserProfile: (newProfile: IUserProfile) => {
    return axiosInstance.post(createUserProfileUrl, newProfile);
  },

  updateUserProfile: (userProfile: IUserProfile) => {
    return axiosInstance.put(updateUserProfileUrl, userProfile);
  }
};

export default UserProfileService;
