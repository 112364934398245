import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Grid,
  Stack,
  TextField,
  Alert,
  Container,
  InputAdornment,
  Tooltip,
  IconButton,
} from '@mui/material';
import LoadingScreen from 'src/components/LoadingScreen';
import { getErrorMessage } from 'src/utils/request';
import { PATH_DASHBOARD } from 'src/routes/paths';
import {
  IMessageTemplate,
  IMessageTemplateFormFields,
} from 'src/interfaces/message-template.interface';
import { Icon } from '@iconify/react';
import questionMarkCircleFill from '@iconify-icons/eva/question-mark-circle-fill';
import MessageTemplateService from 'src/services/message-template.service';
import { ISelectList } from 'src/interfaces/common.interface';

// ----------------------------------------------------------------------

type MessageTemplateFormProps = {
  messageTemplateId?: string;
};

const formValidationSchema: Yup.SchemaOf<IMessageTemplateFormFields> = Yup.object().shape({
  name: Yup.string().required('The name is required.'),
  typeId: Yup.number().required('The template type is required.'),
  subject: Yup.string().required('The subject is required.'),
  emailAccountId: Yup.string().required('The email account is required.'),
  body: Yup.string().required('The body is required.'),
});

// ----------------------------------------------------------------------

export default function MessageTemplateForm({ messageTemplateId }: MessageTemplateFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingMessageTemplate, setLoadingMessageTemplate] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [requestError, setRequestError] = useState<string>();
  const [emailAccounts, setEmailAccounts] = useState<ISelectList[]>([]);
  const [templateTypes, setTemplateTypes] = useState<ISelectList[]>([]);
  const [formInitialValues, setFormInitialValues] = useState<IMessageTemplateFormFields>({
    name: '',
    typeId: 0,
    subject: '',
    emailAccountId: '',
    body: '',
  });

  const loadMessageTemplate = useCallback(() => {
    if (!messageTemplateId) return;
    setLoadingMessageTemplate(true);
    MessageTemplateService.getMessageTemplateById(messageTemplateId)
      .then(({ data }) => {
        setFormInitialValues((prevState) => {
          return {
            ...prevState,
            name: data.name,
            body: data.body,
            emailAccountId: data.emailAccountId,
            typeId: data.typeId,
            subject: data.subject,
          };
        });
        setTemplateTypes(data.templateTypes || []);
        setEmailAccounts(data.emailAccounts || []);
      })
      .catch((error) => {
        setDisableSubmit(true);
        setRequestError(
          getErrorMessage(
            "There was a problem and we weren't able to load the message template. Please notify an administrator.",
            error
          )
        );
      })
      .finally(() => setLoadingMessageTemplate(false));
  }, [messageTemplateId]);

  const loadTypesAndAccount = useCallback(() => {
    if (!messageTemplateId) return;
    MessageTemplateService.getSelectList()
      .then(({ data }) => {
        setTemplateTypes(data.templateTypes || []);
        setEmailAccounts(data.emailAccounts || []);
      })
      .catch((error) => {
        setDisableSubmit(true);
        setRequestError(
          getErrorMessage(
            "There was a problem and we weren't able to load the email accounts and template types. Please notify an administrator.",
            error
          )
        );
      });
  }, [messageTemplateId]);

  useEffect(() => {
    loadMessageTemplate();
    loadTypesAndAccount();
  }, [loadMessageTemplate, loadTypesAndAccount]);

  const onSubmit = (
    values: IMessageTemplateFormFields,
    formikHelpers: FormikHelpers<IMessageTemplateFormFields>
  ) => {
    if (!messageTemplateId) createMessageTemplate(values, formikHelpers);
    else updateMessageTemplate(values, formikHelpers);
  };

  const createMessageTemplate = (
    values: IMessageTemplateFormFields,
    formikHelpers: FormikHelpers<IMessageTemplateFormFields>
  ) => {
    const messageTemplate: IMessageTemplate = {
      name: values.name,
      emailAccountId: values.emailAccountId,
      typeId: values.typeId,
      body: values.body,
      subject: values.subject,
    };
    MessageTemplateService.createMessageTemplate(messageTemplate)
      .then(() => {
        formikHelpers.resetForm();
        enqueueSnackbar('The email template was created successfully.', { variant: 'success' });
        navigate(PATH_DASHBOARD.messageTemplate.list);
      })
      .catch((error) => {
        setRequestError(getErrorMessage("We weren't able to create the email template", error));
      })
      .finally(() => formikHelpers.setSubmitting(false));
  };

  const updateMessageTemplate = (
    values: IMessageTemplateFormFields,
    formikHelpers: FormikHelpers<IMessageTemplateFormFields>
  ) => {
    if (!messageTemplateId) return;

    const messageTemplate: IMessageTemplate = {
      id: messageTemplateId,
      name: values.name,
      emailAccountId: values.emailAccountId,
      typeId: values.typeId,
      body: values.body,
      subject: values.subject,
    };

    MessageTemplateService.updateMessageTemplate(messageTemplate)
      .then(() => {
        formikHelpers.resetForm();
        enqueueSnackbar('The email template was updated successfully.', {
          variant: 'success',
        });
        navigate(PATH_DASHBOARD.messageTemplate.list);
      })
      .catch((error) => {
        setRequestError(getErrorMessage("We weren't able to update the email template", error));
      })
      .finally(() => formikHelpers.setSubmitting(false));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    onSubmit,
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
      {loadingMessageTemplate ? (
        <Container>
          <LoadingScreen
            sx={{
              ...{ width: '50%', margin: '10% auto 0 auto' },
            }}
          />
        </Container>
      ) : (
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        label="Name"
                        {...getFieldProps('name')}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="Write the template name. We used the name to identify the template internally.">
                                <IconButton>
                                  <Icon icon={questionMarkCircleFill} width={24} height={24} />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        select
                        fullWidth
                        label="Email account"
                        placeholder="Email account"
                        {...getFieldProps('emailAccountId')}
                        SelectProps={{ native: true }}
                        error={Boolean(touched.emailAccountId && errors.emailAccountId)}
                        helperText={touched.emailAccountId && errors.emailAccountId}
                        InputLabelProps={{ shrink: true }}
                      >
                        <option disabled value="">
                          Select the email account...
                        </option>
                        {emailAccounts.map((element, index) => (
                          <option key={element.value} value={element.value}>
                            {element.text}
                          </option>
                        ))}
                      </TextField>
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        label="Subject"
                        {...getFieldProps('subject')}
                        error={Boolean(touched.subject && errors.subject)}
                        helperText={touched.subject && errors.subject}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="Write the email subject.">
                                <IconButton>
                                  <Icon icon={questionMarkCircleFill} width={24} height={24} />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        select
                        fullWidth
                        label="Template type"
                        placeholder="Template type"
                        {...getFieldProps('typeId')}
                        SelectProps={{ native: true }}
                        error={Boolean(touched.typeId && errors.typeId)}
                        helperText={touched.typeId && errors.typeId}
                        InputLabelProps={{ shrink: true }}
                      >
                        <option disabled value={0}>
                          Select the template type...
                        </option>
                        {templateTypes.map((element, index) => (
                          <option key={element.value} value={element.value}>
                            {element.text}
                          </option>
                        ))}
                      </TextField>
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        multiline
                        maxRows={9999}
                        label="Body (HTML)"
                        {...getFieldProps('body')}
                        error={Boolean(touched.body && errors.body)}
                        helperText={touched.body && errors.body}
                      />
                    </Stack>
                    {requestError && <Alert severity="error">{requestError}</Alert>}
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        disabled={disableSubmit}
                      >
                        {!messageTemplateId ? 'Create email template' : 'Save changes'}
                      </LoadingButton>
                    </Box>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      )}
    </>
  );
}
