// material
import { Alert, Card, Checkbox, Container, FormControlLabel, Grid, Stack, TextField, Typography } from '@mui/material';
// components
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
// routes
import { useEffect, useState } from 'react';

import { Configuration, CreateCompletionResponse, OpenAIApi } from "openai";
import { LoadingButton } from '@mui/lab';
import AiService from 'src/services/ai.service';

// ----------------------------------------------------------------------

const aiConfiguration = new Configuration({
    //organization: "org-g96GtQS8F7Ss6GoWDBAYqaRb", // jileananez
    //apiKey: "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", // jileananez
    organization: "org-Ieu7yblLvm22QPJSVj13VTru", // cmartinez
    apiKey: "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", // cmartinez
});
const openai = new OpenAIApi(aiConfiguration);
let openAiCompletationConfig = {
    model: 'text-davinci-003',
    temperature: 0.8,
    frequency_penalty: 0.5,
    presence_penalty: 0.5,
}

const getGenerateProductDescriptionPrompt = (productFeatures: string) => {
    return `Generate a product description. 
    The text should be in third person. 
    The text should have at least three paragraphs. 
    The product has the following features (the features are separated with a semicolon): 
    ${productFeatures}`;
}

export default function ProductContentGeneratorPage() {
    const [generatingResult, setGeneratingResult] = useState<boolean>(false);
    const [requestError, setRequestError] = useState<string>();
    const [productUrl, setProductUrl] = useState<string>('');
    const [result, setResult] = useState<string>('');

    useEffect(() => console.log('openAiCompletationConfig', openAiCompletationConfig), []);

    const extractProductData = async () => {
        if (generatingResult || !productUrl) return;
        setGeneratingResult(true);
        try {
            let productDescriptionResult = await AiService.getAmazonProductDescription(productUrl);
            generateResult(productDescriptionResult.data);
        } catch (error) {
            console.log('ERROR GETTING DESCRIPTION FROM PRODUCT', error);
            setRequestError('Error getting description from product.');
            setGeneratingResult(false);
        }
    };

    const generateResult = (productDescription: string) => {
        openai.createCompletion({
            ...openAiCompletationConfig,
            max_tokens: 2500,
            prompt: getGenerateProductDescriptionPrompt(productDescription)
        }).then(({ data }) => {
            console.log("========== GENERATING RESULT ==========");
            console.log(data);
            console.log("========== GENERATING RESULT ==========");
            if (data && data.choices.length > 0) {
                let reqResult = data.choices[0].text || "";
                if (reqResult) {
                    setResult(reqResult.replace("\n\n", ""));
                    console.log("========== FINAL RESULT ==========");
                    console.log(reqResult);
                    console.log("========== FINAL RESULT ==========");
                }
            }
        })
            .catch((error) => {
                setRequestError("ERROR GENERATING RESULT");
                console.log("ERROR GENERATING RESULT:", JSON.stringify(error));
            })
            .finally(() => setGeneratingResult(false));
    };

    return (
        <Page title="ProductContentGenerator">
            <Container>
                <HeaderBreadcrumbs heading="Ai Product Content Generator" links={[]} />
                {requestError && (
                    <Alert style={{ marginBottom: '1em' }} severity="error">
                        {requestError}
                    </Alert>
                )}
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Card sx={{ p: 3 }}>
                            <Typography variant="body2" paragraph>
                                This tool allows you to generate content from an Amazon product using artifitial intelligence. This cost money, so use it with caution.
                            </Typography>
                            <Stack>
                                <Typography variant="h6" paragraph mt={2} mb={0}>
                                    Enter the amazon's product url.
                                </Typography>
                                <Typography variant="caption" paragraph>
                                    Ex. https://www.amazon.com/ELITE-Bowling-us_Footwear_Size_System-Numeric-Nu...
                                </Typography>
                                <Stack
                                    direction={{ xs: 'column', sm: 'row' }}
                                    spacing={{ xs: 3, sm: 2 }}
                                >
                                    <TextField
                                        multiline
                                        onChange={(e) => setProductUrl(e.target.value)}
                                        fullWidth
                                        label="Product Url"
                                    />
                                </Stack>
                            </Stack>
                            <Stack>
                                <LoadingButton
                                    disabled={!productUrl || productUrl.length < 4}
                                    style={{ width: '30%', marginTop: '2em' }}
                                    onClick={extractProductData}
                                    variant="contained"
                                    loading={generatingResult}>
                                    Submit
                                </LoadingButton>
                            </Stack>
                            {(result && !generatingResult) &&
                                <Stack borderTop={1} mt={4}>
                                    <Typography variant="h4" paragraph mt={2}>
                                        Congratulations! Here is your result:
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        multiline
                                        maxRows={9999}
                                        label="Result (HTML)"
                                        value={result}
                                    />
                                </Stack>
                            }
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
