import { useState } from 'react';
import { Button, Container } from '@mui/material';
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../routes/paths';
import MessageTemplateForm from './components/MessageTemplateForm';
import { useNavigate, useParams } from 'react-router-dom';

import trash2Fill from '@iconify-icons/eva/trash-2-fill';
import { Icon } from '@iconify/react';
import DeleteElementModal from 'src/components/DeleteElementModal';
import MessageTemplateService from 'src/services/message-template.service';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

export default function MessageTemplateEditPage() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { messageTemplateId } = useParams();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);
  const [deletingMessageTemplate, setDeletingMessageTemplate] = useState<boolean>(false);

  const handleCloseDeleteConfirmationModal = () => {
    setOpenDeleteConfirmation(false);
  };

  const handleOpenDeleteConfirmationModal = () => {
    setOpenDeleteConfirmation(true);
  };

  const deleteMessageTemplate = () => {
    setDeletingMessageTemplate(true);
    MessageTemplateService.deleteMessageTemplate(messageTemplateId || '')
      .then(() => {
        enqueueSnackbar('The email template has been deleted successfully.', {
          variant: 'success',
        });
        handleCloseDeleteConfirmationModal();
        navigate(PATH_DASHBOARD.messageTemplate.list);
      })
      .catch((error) => {
        enqueueSnackbar(
          "We weren't able to delete the email template. Please notify an administrator.",
          { variant: 'error' }
        );
      })
      .finally(() => setDeletingMessageTemplate(false));
  };

  return (
    <Page title="Edit email template">
      <Container>
        <HeaderBreadcrumbs
          heading="Edit email template"
          links={[
            { name: 'Email templates', href: PATH_DASHBOARD.messageTemplate.list },
            { name: 'Edit email template' },
          ]}
          action={
            <Button
              onClick={handleOpenDeleteConfirmationModal}
              color="error"
              variant="contained"
              startIcon={<Icon icon={trash2Fill} />}
            >
              Delete email template
            </Button>
          }
        />
        <MessageTemplateForm messageTemplateId={messageTemplateId} />
      </Container>
      <DeleteElementModal
        openDeleteConfirmation={openDeleteConfirmation}
        handleCloseDeleteConfirmationModal={handleCloseDeleteConfirmationModal}
        deletingElement={deletingMessageTemplate}
        deleteElementHandler={deleteMessageTemplate}
      />
    </Page>
  );
}
