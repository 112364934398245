import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, Button } from '@mui/material';
// components
import MenuPopover from '../../../components/MenuPopover';
import useAuth from 'src/hooks/useAuth';
import moonFill from '@iconify-icons/eva/moon-fill';
import sunFill from '@iconify-icons/eva/sun-fill';
import { Icon } from '@iconify/react';
import useSettings from 'src/hooks/useSettings';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
  {
    label: 'Profile',
    linkTo: '/',
  },
  {
    label: 'Settings',
    linkTo: '/',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const { user, logout } = useAuth();
  const { themeMode, onToggleMode } = useSettings();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <Button
        color="inherit"
        onClick={handleOpen}
        startIcon={<Avatar alt="My Avatar" src="/static/mock-images/avatars/avatar_default.jpg" />}
        sx={{
          px: 1.5,
          borderRadius: 1.5,
          ...(open && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
          Hello {user?.firstName}!
        </Typography>
      </Button>

      <MenuPopover open={Boolean(open)} onClose={handleClose} anchorEl={open} sx={{ width: 220 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user?.firstName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        <MenuItem onClick={onToggleMode} sx={{ typography: 'body2', py: 1, px: 2.5 }}>
          <Box
            component={Icon}
            icon={themeMode === 'dark' ? sunFill : moonFill}
            sx={{
              mr: 2,
              width: 24,
              height: 24,
            }}
          />
          {themeMode === 'dark' ? 'Light mode' : 'Dark mode'}
        </MenuItem>

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={logout}>
            Log out
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
