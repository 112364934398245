import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Grid,
  Stack,
  TextField,
  Typography,
  Alert,
  Container,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import UserService from 'src/services/user.service';

import LoadingScreen from 'src/components/LoadingScreen';
import { getErrorMessage } from 'src/utils/request';
import { IUserFormFields } from 'src/interfaces/user.interface';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { ISelectList } from 'src/interfaces/common.interface';

// ----------------------------------------------------------------------

type UserFormProps = {
  userId?: string;
};

const formValidationSchema: Yup.SchemaOf<IUserFormFields> = Yup.object().shape({
  firstName: Yup.string().required('The first name is required.'),
  lastName: Yup.string().required('The last name is required.'),
  email: Yup.string()
    .required('The email address is required.')
    .email('Please use a valid email address.'),
  userProfileId: Yup.string().required('The user profile is required.'),
  isActive: Yup.boolean().required(),
  generateRandomPassword: Yup.boolean().required(),
  password: Yup.string().min(6, 'The password must have at least 6 characters.'),
});

export default function UserForm({ userId }: UserFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [userProfiles, setUserProfiles] = useState<ISelectList[]>([]);
  const [loadingUser, setLoadingUser] = useState<boolean>(false);
  const [requestError, setRequestError] = useState<string>();
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [formInitialValues, setFormInitialValues] = useState<IUserFormFields>({
    firstName: '',
    lastName: '',
    email: '',
    userProfileId: '',
    isActive: true,
    generateRandomPassword: false,
    password: '',
  });

  const onSubmit = (values: IUserFormFields, formikHelpers: FormikHelpers<IUserFormFields>) => {
    if (!userId) createUser(values, formikHelpers);
    else updateUser(values, formikHelpers);
  };

  const getUserById = useCallback(() => {
    if (!userId) return;
    setLoadingUser(true);
    UserService.getUserById(userId)
      .then(({ data }) => {
        setFormInitialValues((prevState) => {
          return {
            ...prevState,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            userProfileId: data.userProfileId,
          };
        });
      })
      .catch((error) => {
        setDisableSubmit(true);
        setRequestError(
          getErrorMessage(
            "There was a problem and we weren't able to load the user. Please notify an administrator",
            error
          )
        );
      })
      .finally(() => setLoadingUser(false));
  }, [userId]);

  useEffect(() => {
    getUserById();
  }, [getUserById]);

  useEffect(() => {
    UserService.getUserProfileSelectList()
      .then(({ data }) => {
        setUserProfiles(data);
      })
      .catch((error) => {
        setDisableSubmit(true);
        setRequestError(
          getErrorMessage(
            "We weren't able to load the user profiles. Please notify an administrator.",
            error
          )
        );
      });
  }, []);

  const createUser = (values: IUserFormFields, formikHelpers: FormikHelpers<IUserFormFields>) => {
    UserService.createUser(values)
      .then(() => {
        formikHelpers.resetForm();
        enqueueSnackbar('The user has been created succesfully.', { variant: 'success' });
        navigate(PATH_DASHBOARD.user.list);
      })
      .catch((error) => {
        setRequestError(
          getErrorMessage(
            "We weren't able to create the user. Please notify an administrator",
            error
          )
        );
      })
      .finally(() => formikHelpers.setSubmitting(false));
  };

  const updateUser = (values: IUserFormFields, formikHelpers: FormikHelpers<IUserFormFields>) => {
    if (!userId) return;
    UserService.updateUser(values, userId)
      .then(() => {
        formikHelpers.resetForm();
        enqueueSnackbar('The user has been updated succesfully.', { variant: 'success' });
        navigate(PATH_DASHBOARD.user.list);
      })
      .catch((error) => {
        setRequestError(
          getErrorMessage(
            "We weren't able to update the user. Please notify an administrator",
            error
          )
        );
      })
      .finally(() => formikHelpers.setSubmitting(false));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    onSubmit,
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
      {loadingUser ? (
        <Container>
          <LoadingScreen
            sx={{
              ...{ width: '50%', margin: '10% auto 0 auto' },
            }}
          />
        </Container>
      ) : (
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        label="First name"
                        {...getFieldProps('firstName')}
                        error={Boolean(touched.firstName && errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                      />
                      <TextField
                        fullWidth
                        label="Last name"
                        {...getFieldProps('lastName')}
                        error={Boolean(touched.lastName && errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        label="Email"
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                      <TextField
                        select
                        fullWidth
                        label="User profile"
                        placeholder="User profile"
                        {...getFieldProps('userProfileId')}
                        SelectProps={{ native: true }}
                        error={Boolean(touched.userProfileId && errors.userProfileId)}
                        helperText={touched.userProfileId && errors.userProfileId}
                        InputLabelProps={{ shrink: true }}
                      >
                        <option disabled value="">
                          Select the user profile...
                        </option>
                        {userProfiles.map((element) => (
                          <option key={element.value} value={element.value}>
                            {element.text}
                          </option>
                        ))}
                      </TextField>
                    </Stack>
                    {!userId && (
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <FormControlLabel
                          style={{ width: '100%' }}
                          control={
                            <Checkbox
                              {...getFieldProps('generateRandomPassword')}
                              checked={formik.values.generateRandomPassword}
                            />
                          }
                          label="Generate random password"
                        />
                        {!formik.values.generateRandomPassword ? (
                          <TextField
                            fullWidth
                            label="Password"
                            {...getFieldProps('password')}
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                          />
                        ) : (
                          <div style={{ textAlign: 'right', width: '100%' }}>
                            <Typography variant="caption">
                              * The random password will be sent via email.
                            </Typography>
                          </div>
                        )}
                      </Stack>
                    )}
                    {requestError && <Alert severity="error">{requestError}</Alert>}
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                      <LoadingButton
                        disabled={disableSubmit}
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                      >
                        {!userId ? 'Create user' : 'Save changes'}
                      </LoadingButton>
                    </Box>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      )}
    </>
  );
}
