import {
  Alert,
  Button,
  Card,
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import { Icon } from '@iconify/react';
import plusFill from '@iconify-icons/eva/plus-fill';
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { PATH_DASHBOARD } from '../../../routes/paths';
import { useCallback, useEffect, useState } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { customLabelDisplayedRows } from 'src/utils/common';
import { getErrorMessage } from 'src/utils/request';
import PageService from 'src/services/page.service';
import ListHead from './components/ListHead';
import { useSnackbar } from 'notistack';
import DeleteElementModal from 'src/components/DeleteElementModal';
import LoadingData from 'src/components/LoadingData';
import editOutline from '@iconify-icons/eva/edit-outline';
import bookOpenOutline from '@iconify-icons/eva/book-open-outline';
import { IPage, IPageList } from 'src/interfaces/course.interface';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'pageNumber', label: 'Number', alignRight: false },
  { id: 'content', label: 'Content', alignRight: false },
  { id: 'options', label: 'Settings', alignRight: true },
];

// ----------------------------------------------------------------------

export default function PageListPage() {
  const { enqueueSnackbar } = useSnackbar();
  const { projectId, chapterId, sectionId } = useParams();
  const [pages, setPages] = useState<IPageList>();
  const [loadingPages, setLoadingPages] = useState<boolean>(true);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState(0);
  const [requestError, setRequestError] = useState<string>();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);
  const [selectedPage, setSelectedPage] = useState<IPage>();
  const [deletingPage, setDeletingPage] = useState<boolean>(false);

  const loadPages = useCallback(() => {
    setLoadingPages(true);
    PageService.getPages(page, rowsPerPage, sectionId || '')
      .then(({ data }) => {
        setPages(data);
      })
      .catch((error) => {
        setRequestError(
          getErrorMessage(
            'There was a problem while loading the pages. Please notify an administrator.',
            error
          )
        );
      })
      .finally(() => setLoadingPages(false));
  }, [page, rowsPerPage]);

  useEffect(() => {
    loadPages();
  }, [loadPages]);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDeleteConfirmationModal = (page: IPage) => {
    setSelectedPage(page);
    setOpenDeleteConfirmation(true);
  };

  const handleCloseDeleteConfirmationModal = () => {
    setSelectedPage(undefined);
    setOpenDeleteConfirmation(false);
  };

  const deletePage = () => {
    if (!selectedPage) return;
    setDeletingPage(true);
    PageService.deletePage(selectedPage.id || '')
      .then(() => {
        enqueueSnackbar('The page has been deleted succesfully.', {
          variant: 'success',
        });
        if (!pages) return;
        const currentList = { ...pages };
        const updatedList = currentList.data.filter((x) => x.id !== selectedPage.id);
        currentList.data = updatedList;
        setPages(currentList);
        handleCloseDeleteConfirmationModal();
      })
      .catch((error) => {
        enqueueSnackbar('It was not possible to delete the page.', { variant: 'error' });
      })
      .finally(() => setDeletingPage(false));
  };

  return (
    <Page title="Pages">
      <Container>
        <HeaderBreadcrumbs
          heading="Pages"
          links={[
            { name: 'Courses', href: PATH_DASHBOARD.course.list },
            { name: 'Chapters', href: `${PATH_DASHBOARD.course.root}/${projectId}/chapters` },
            {
              name: 'Sections',
              href: `${PATH_DASHBOARD.course.root}/${projectId}/chapters/${chapterId}/sections`,
            },
            { name: 'Pages' },
          ]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={`${PATH_DASHBOARD.course.root}/${projectId}/chapters/${chapterId}/sections/${sectionId}/pages/create?lastPageNumber=${pages?.lastPageNumber}`}
              startIcon={<Icon icon={plusFill} />}
            >
              New page
            </Button>
          }
        />
        {requestError && (
          <Alert style={{ marginBottom: '1em' }} severity="error">
            {requestError}
          </Alert>
        )}
        <LoadingData show={loadingPages} />
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table style={{ marginTop: 15 }}>
                <ListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {pages?.data.map((row) => {
                    const { id, pageNumber, content, question } = row;
                    return (
                      <TableRow hover key={id}>
                        <TableCell align="left">{pageNumber}</TableCell>
                        <TableCell align="left">
                          {content ? content.substring(0, 120) + '...' : question}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            title="Edit page"
                            href={`${PATH_DASHBOARD.course.root}/${projectId}/chapters/${chapterId}/sections/${sectionId}/pages/edit/${id}`}
                          >
                            <Icon icon={editOutline} width={24} height={24} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {pages?.data.length === 0 && (
                    <TableRow style={{ height: 53 }}>
                      <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                        We couldn't find any page. Please create a new one using the "New page"
                        button.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            labelDisplayedRows={customLabelDisplayedRows}
            labelRowsPerPage="Pages per page:"
            rowsPerPageOptions={[10, 50, 100]}
            component="div"
            count={pages?.total || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <DeleteElementModal
        openDeleteConfirmation={openDeleteConfirmation}
        handleCloseDeleteConfirmationModal={handleCloseDeleteConfirmationModal}
        selectedElementName=""
        deletingElement={deletingPage}
        deleteElementHandler={deletePage}
      />
    </Page>
  );
}
