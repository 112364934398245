import { useState } from 'react';
import { Button, Container } from '@mui/material';
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import CampaignForm from './components/CampaignForm';
import { useNavigate, useParams } from 'react-router-dom';

import trash2Fill from '@iconify-icons/eva/trash-2-fill';
import { Icon } from '@iconify/react';
import DeleteElementModal from 'src/components/DeleteElementModal';
import CampaignService from 'src/services/campaign.service';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

export default function CampaignEditPage() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { campaignId } = useParams();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);
  const [deletingCampaign, setDeletingCampaign] = useState<boolean>(false);

  const handleCloseDeleteConfirmationModal = () => {
    setOpenDeleteConfirmation(false);
  };

  const handleOpenDeleteConfirmationModal = () => {
    setOpenDeleteConfirmation(true);
  };

  const deleteCampaign = () => {
    setDeletingCampaign(true);
    CampaignService.deleteCampaign(campaignId || '')
      .then(() => {
        enqueueSnackbar('The campaign has been deleted successfully.', {
          variant: 'success',
        });
        handleCloseDeleteConfirmationModal();
        navigate(PATH_DASHBOARD.campaign.list);
      })
      .catch((error) => {
        enqueueSnackbar("We weren't able to delete the campaign. Please notify an administrator.", {
          variant: 'error',
        });
      })
      .finally(() => setDeletingCampaign(false));
  };

  return (
    <Page title="Edit campaign">
      <Container>
        <HeaderBreadcrumbs
          heading="Edit campaign"
          links={[
            { name: 'Campaigns', href: PATH_DASHBOARD.campaign.list },
            { name: 'Edit campaign' },
          ]}
        />
        <CampaignForm campaignId={campaignId} />
      </Container>
    </Page>
  );
}
