import NotistackProvider from './components/NotistackProvider';
import LoadingScreen from './components/LoadingScreen';
import useAuth from './hooks/useAuth';

// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import GlobalStyles from './theme/globalStyles';

// ----------------------------------------------------------------------

export default function App() {

  const { isInitialized } = useAuth();

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <NotistackProvider>
          <GlobalStyles />
          <ScrollToTop />
          {isInitialized ? <Router /> : <LoadingScreen />}
        </NotistackProvider>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
