import {
  Alert,
  Button,
  Card,
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import { Icon } from '@iconify/react';
import plusFill from '@iconify-icons/eva/plus-fill';
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { PATH_DASHBOARD } from '../../../routes/paths';
import { useCallback, useEffect, useState } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { customLabelDisplayedRows } from 'src/utils/common';
import { getErrorMessage } from 'src/utils/request';
import ChapterService from 'src/services/chapter.service';
import ListHead from './components/ListHead';
import { useSnackbar } from 'notistack';
import DeleteElementModal from 'src/components/DeleteElementModal';
import LoadingData from 'src/components/LoadingData';
import editOutline from '@iconify-icons/eva/edit-outline';
import bookOpenOutline from '@iconify-icons/eva/book-open-outline';
import { IChapter, IChapterList } from 'src/interfaces/course.interface';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'chapterNumber', label: 'Number', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'paymentRequired', label: 'Payment required', alignRight: false },
  { id: 'options', label: 'Settings', alignRight: true },
];

// ----------------------------------------------------------------------

export default function ChapterListPage() {
  const { enqueueSnackbar } = useSnackbar();
  const { projectId } = useParams();
  const [chapters, setChapters] = useState<IChapterList>();
  const [loadingChapters, setLoadingChapters] = useState<boolean>(true);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState(0);
  const [requestError, setRequestError] = useState<string>();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);
  const [selectedChapter, setSelectedChapter] = useState<IChapter>();
  const [deletingChapter, setDeletingChapter] = useState<boolean>(false);

  const loadChapters = useCallback(() => {
    setLoadingChapters(true);
    ChapterService.getChapters(page, rowsPerPage, projectId || '')
      .then(({ data }) => {
        setChapters(data);
      })
      .catch((error) => {
        setRequestError(
          getErrorMessage(
            'There was a problem while loading the chapters. Please notify an administrator.',
            error
          )
        );
      })
      .finally(() => setLoadingChapters(false));
  }, [page, rowsPerPage]);

  useEffect(() => {
    loadChapters();
  }, [loadChapters]);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDeleteConfirmationModal = (chapter: IChapter) => {
    setSelectedChapter(chapter);
    setOpenDeleteConfirmation(true);
  };

  const handleCloseDeleteConfirmationModal = () => {
    setSelectedChapter(undefined);
    setOpenDeleteConfirmation(false);
  };

  const deleteChapter = () => {
    if (!selectedChapter) return;
    setDeletingChapter(true);
    ChapterService.deleteChapter(selectedChapter.id || '')
      .then(() => {
        enqueueSnackbar('The chapter has been deleted succesfully.', {
          variant: 'success',
        });
        if (!chapters) return;
        const currentList = { ...chapters };
        const updatedList = currentList.data.filter((x) => x.id !== selectedChapter.id);
        currentList.data = updatedList;
        setChapters(currentList);
        handleCloseDeleteConfirmationModal();
      })
      .catch((error) => {
        enqueueSnackbar('It was not possible to delete the chapter.', { variant: 'error' });
      })
      .finally(() => setDeletingChapter(false));
  };

  return (
    <Page title="Chapters">
      <Container>
        <HeaderBreadcrumbs
          heading="Chapters"
          links={[{ name: 'Courses', href: PATH_DASHBOARD.course.list }, { name: 'Chapters' }]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={`${PATH_DASHBOARD.course.root}/${projectId}/chapters/create?lastChapterNumber=${chapters?.lastPageNumber}`}
              startIcon={<Icon icon={plusFill} />}
            >
              New chapter
            </Button>
          }
        />
        {requestError && (
          <Alert style={{ marginBottom: '1em' }} severity="error">
            {requestError}
          </Alert>
        )}
        <LoadingData show={loadingChapters} />
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table style={{ marginTop: 15 }}>
                <ListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {chapters?.data.map((row) => {
                    const { id, chapterNumber, paymentRequired, chapterName } = row;
                    return (
                      <TableRow hover key={id}>
                        <TableCell align="left">{chapterNumber}</TableCell>
                        <TableCell align="left">{chapterName}</TableCell>
                        <TableCell align="left">{paymentRequired ? 'Yes' : 'No'}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            title="Edit chapter"
                            href={`${PATH_DASHBOARD.course.root}/${projectId}/chapters/edit/${id}`}
                          >
                            <Icon icon={editOutline} width={24} height={24} />
                          </IconButton>
                          <IconButton
                            title="View sections"
                            href={`${PATH_DASHBOARD.course.root}/${projectId}/chapters/${id}/sections`}
                          >
                            <Icon icon={bookOpenOutline} width={24} height={24} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {chapters?.data.length === 0 && (
                    <TableRow style={{ height: 53 }}>
                      <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                        We couldn't find any chapter. Please create a new one using the "New
                        chapter" button.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            labelDisplayedRows={customLabelDisplayedRows}
            labelRowsPerPage="Chapters per page:"
            rowsPerPageOptions={[10, 50, 100]}
            component="div"
            count={chapters?.total || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <DeleteElementModal
        openDeleteConfirmation={openDeleteConfirmation}
        handleCloseDeleteConfirmationModal={handleCloseDeleteConfirmationModal}
        selectedElementName={selectedChapter?.chapterName}
        deletingElement={deletingChapter}
        deleteElementHandler={deleteChapter}
      />
    </Page>
  );
}
