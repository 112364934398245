import * as Yup from 'yup';
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
// material
import { TextField, Alert, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import useAuth from './../../../../hooks/useAuth';
import useIsMountedRef from './../../../../hooks/useIsMountedRef';
import { getErrorMessage } from './../../../../utils/request';

// ----------------------------------------------------------------------

type InitialValues = {
  email: string;
};

type PasswordRecoveryFormProps = {
  onSent: VoidFunction;
  onGetEmail: (value: string) => void;
};

// ----------------------------------------------------------------------

const PasswordRecoverySchema: Yup.SchemaOf<InitialValues> = Yup.object().shape({
  email: Yup.string()
    .email('You must add a valid email address.')
    .required('The email address is required.')
});

export default function PasswordRecoveryForm({ onSent, onGetEmail }: PasswordRecoveryFormProps) {
  const { passwordRecovery } = useAuth();
  const isMountedRef = useIsMountedRef();
  const [requestError, setRequestError] = useState<string>();

  const onSubmit = (values: InitialValues, formikHelpers: FormikHelpers<InitialValues>) => {
    passwordRecovery(values.email)
      .then(() => {
        onSent();
        onGetEmail(formik.values.email);
      })
      .catch((error) => {
        setRequestError(getErrorMessage('We weren\'t able to recover your password. Please contact an administrator.', error));
      })
      .finally(() => {
        if (isMountedRef.current) formikHelpers.setSubmitting(false);
      });
  };

  const formik = useFormik<InitialValues>({
    initialValues: {
      email: ''
    },
    validationSchema: PasswordRecoverySchema,
    onSubmit
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            {...getFieldProps('email')}
            type="email"
            label="Email address"
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          {requestError && <Alert severity="error">{requestError}</Alert>}
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Send
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
