import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';

/// Guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard, { APP_ROLES } from '../guards/RoleBasedGuard';

/// Shared
import LoadingScreen from '../components/LoadingScreen';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';

/// Auth
import Login from '../pages/auth/login/Login';
import PasswordRecoveryPage from '../pages/auth/password-recovery/PasswordRecovery';
import ResetPasswordPage from '../pages/auth/reset-password/ResetPassword';

/// Users
import UserListPage from 'src/pages/admin/user/UserList';
import UserCreatePage from 'src/pages/admin/user/UserCreate';
import UserEditPage from 'src/pages/admin/user/UserEdit';
import UserProfileListPage from 'src/pages/admin/user-profile/UserProfileList';
import UserProfileCreatePage from 'src/pages/admin/user-profile/UserProfileCreate';
import UserProfileEditPage from 'src/pages/admin/user-profile/UserProfileEdit';

/// CMS
import MessageTemplateListPage from 'src/pages/admin/message-template/MessageTemplateList';
import MessageTemplateCreatePage from 'src/pages/admin/message-template/MessageTemplateCreate';
import MessageTemplateEditPage from 'src/pages/admin/message-template/MessageTemplateEdit';

/// Settings
import EmailAccountListPage from 'src/pages/admin/email-account/EmailAccountList';
import EmailAccountEditPage from 'src/pages/admin/email-account/EmailAccountEdit';
import EmailAccountCreatePage from 'src/pages/admin/email-account/EmailAccountCreate';

/// System
import SystemInfoPage from 'src/pages/admin/system-info/SystemInfo';
import LogListPage from 'src/pages/admin/log/LogList';
import ScheduleTaskPage from 'src/pages/admin/schedule-task/ScheduleTask';

/// Projects
import ProjectListPage from 'src/pages/admin/projects/ProjectList';
import ProjectCreatePage from 'src/pages/admin/projects/ProjectCreate';
import ProjectEditPage from 'src/pages/admin/projects/ProjectEdit';
import QuestionListPage from 'src/pages/admin/question/QuestionList';
import QuestionCreatePage from 'src/pages/admin/question/QuestionCreate';
import QuestionEditPage from 'src/pages/admin/question/QuestionEdit';
import PostGeneratorPage from 'src/pages/admin/ai-tools/PostGenerator';
import PlaygroundPage from 'src/pages/admin/ai-tools/Playground';
import ProductContentGeneratorPage from 'src/pages/admin/ai-tools/ProductContentGenerator';
import PrivacyPolicy from 'src/pages/shared/PrivacyPolicy';
import PostGeneratorV2Page from 'src/pages/admin/ai-tools/PostGeneratorV2';
import CampaignListPage from 'src/pages/admin/ai-tools/post-campaign/CampaignList';
import CampaignCreatePage from 'src/pages/admin/ai-tools/post-campaign/CampaignCreate';
import CampaignEditPage from 'src/pages/admin/ai-tools/post-campaign/CampaignEdit';
import PostCampaignPreparationPage from 'src/pages/admin/ai-tools/campaign-preparation/PostCampaignPreparation';
import CourseListPage from 'src/pages/admin/courses/CourseList';
import ChapterListPage from 'src/pages/admin/courses/ChapterList';
import ChapterCreatePage from 'src/pages/admin/courses/ChapterCreate';
import ChapterEditPage from 'src/pages/admin/courses/ChapterEdit';
import SectionListPage from 'src/pages/admin/courses/SectionList';
import SectionCreatePage from 'src/pages/admin/courses/SectionCreate';
import SectionEditPage from 'src/pages/admin/courses/SectionEdit';
import PageListPage from 'src/pages/admin/courses/PageList';
import PageCreatePage from 'src/pages/admin/courses/PageCreate';
import PageEditPage from 'src/pages/admin/courses/PageEdit';

/// Trivia

// ----------------------------------------------------------------------

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed',
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Auth routes
    {
      path: 'auth',
      children: [
        { path: 'login', element: <Navigate to="/" replace /> },
        { path: 'password-recovery', element: <PasswordRecoveryPage /> },
        { path: 'reset-password', element: <ResetPasswordPage /> },
      ],
    },
    // Dashboard Routes
    {
      path: 'admin',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '/admin/',
          element: <Navigate to="/admin/dashboard" replace />,
        },
        {
          path: 'dashboard',
          element: <Dashboard />,
        },
        {
          path: 'user',
          children: [
            {
              path: '/admin/user/',
              element: <Navigate to="/user/list" replace />,
            },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.Users]}>
                  <UserListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'create',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.Users]}>
                  <UserCreatePage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'edit/:userId',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.Users]}>
                  <UserEditPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'user-profile',
          children: [
            {
              path: '/admin/user-profile/',
              element: <Navigate to="/user-profile/list" replace />,
            },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.UserProfiles]}>
                  <UserProfileListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'create',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.UserProfiles]}>
                  <UserProfileCreatePage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'edit/:userProfileId',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.UserProfiles]}>
                  <UserProfileEditPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'message-template',
          children: [
            {
              path: '/admin/message-template/',
              element: <Navigate to="/message-template/list" replace />,
            },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.MessageTemplates]}>
                  <MessageTemplateListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'create',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.MessageTemplates]}>
                  <MessageTemplateCreatePage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'edit/:messageTemplateId',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.MessageTemplates]}>
                  <MessageTemplateEditPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'email-account',
          children: [
            {
              path: '/admin/email-account/',
              element: <Navigate to="/email-account/list" replace />,
            },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.EmailAccounts]}>
                  <EmailAccountListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'create',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.EmailAccounts]}>
                  <EmailAccountCreatePage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'edit/:emailAccountId',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.EmailAccounts]}>
                  <EmailAccountEditPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'log',
          children: [
            {
              path: '/admin/log/',
              element: <Navigate to="/log/list" replace />,
            },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.Log]}>
                  <LogListPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'system-info',
          children: [
            {
              path: '/admin/system-info',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.SystemInfo]}>
                  <SystemInfoPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'schedule-task',
          children: [
            {
              path: '/admin/schedule-task',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.ScheduleTasks]}>
                  <ScheduleTaskPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'project',
          children: [
            {
              path: '/admin/project/',
              element: <Navigate to="/project/list" replace />,
            },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.Projects]}>
                  <ProjectListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'create',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.Projects]}>
                  <ProjectCreatePage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'edit/:projectId',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.Projects]}>
                  <ProjectEditPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'question',
          children: [
            {
              path: '/admin/question/',
              element: <Navigate to="/question/list" replace />,
            },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.Questions]}>
                  <QuestionListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'create',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.Questions]}>
                  <QuestionCreatePage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'edit/:questionId',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.Questions]}>
                  <QuestionEditPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'ai-tools',
          children: [
            {
              path: '/admin/ai-tools/post-generator',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.PostGenerator]}>
                  <PostGeneratorPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: '/admin/ai-tools/complete-post-generator',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.PostGenerator]}>
                  <PostGeneratorV2Page />
                </RoleBasedGuard>
              ),
            },
            {
              path: '/admin/ai-tools/playground',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.PostGenerator]}>
                  <PlaygroundPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: '/admin/ai-tools/product-content-generator',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.PostGenerator]}>
                  <ProductContentGeneratorPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: '/admin/ai-tools/post-campaign-preparation',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.PostGenerator]}>
                  <PostCampaignPreparationPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'campaign',
          children: [
            {
              path: '/admin/campaign/',
              element: <Navigate to="/campaign/list" replace />,
            },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.PostGenerator]}>
                  <CampaignListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'create',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.PostGenerator]}>
                  <CampaignCreatePage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'edit/:campaignId',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.PostGenerator]}>
                  <CampaignEditPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'course',
          children: [
            {
              path: '/admin/course/',
              element: <Navigate to="/course/list" replace />,
            },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.Projects]}>
                  <CourseListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: '/admin/course/:projectId/chapters',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.Chapters]}>
                  <ChapterListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: '/admin/course/:projectId/chapters/create',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.Chapters]}>
                  <ChapterCreatePage />
                </RoleBasedGuard>
              ),
            },
            {
              path: '/admin/course/:projectId/chapters/edit/:chapterId',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.Chapters]}>
                  <ChapterEditPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: '/admin/course/:projectId/chapters/:chapterId/sections',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.Sections]}>
                  <SectionListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: '/admin/course/:projectId/chapters/:chapterId/sections/create',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.Sections]}>
                  <SectionCreatePage />
                </RoleBasedGuard>
              ),
            },
            {
              path: '/admin/course/:projectId/chapters/:chapterId/sections/edit/:sectionId',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.Sections]}>
                  <SectionEditPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: '/admin/course/:projectId/chapters/:chapterId/sections/:sectionId/pages',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.Pages]}>
                  <PageListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: '/admin/course/:projectId/chapters/:chapterId/sections/:sectionId/pages/create',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.Pages]}>
                  <PageCreatePage />
                </RoleBasedGuard>
              ),
            },
            {
              path: '/admin/course/:projectId/chapters/:chapterId/sections/:sectionId/pages/edit/:pageId',
              element: (
                <RoleBasedGuard accessibleRoles={[APP_ROLES.Pages]}>
                  <PageEditPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
      ],
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [{ path: 'privacy-policy', element: <PrivacyPolicy /> }],
    },
    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Dashboard
const Dashboard = Loadable(lazy(() => import('../pages/admin/Dashboard')));
const NotFound = Loadable(lazy(() => import('../pages/shared/Page404')));
