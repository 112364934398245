import { Icon } from '@iconify/react';
import { useState, useEffect, useCallback } from 'react';
import plusFill from '@iconify-icons/eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  Container,
  TableContainer,
  TablePagination,
  IconButton,
  TableCell,
  Alert,
} from '@mui/material';
import UserService from 'src/services/user.service';
import { PATH_DASHBOARD } from '../../../routes/paths';
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { customLabelDisplayedRows } from 'src/utils/common';
import editFill from '@iconify-icons/eva/edit-fill';
import { IUserList } from 'src/interfaces/user.interface';
import UserListHead from './components/UserListHead';
import { getErrorMessage } from 'src/utils/request';
import LoadingData from 'src/components/LoadingData';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Full name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'role', label: 'Profile', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function UserList() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [users, setUsers] = useState<IUserList>();
  const [loadingUsers, setLoadingUsers] = useState<boolean>(true);
  const [requestError, setRequestError] = useState<string>();

  const loadUsers = useCallback(() => {
    setLoadingUsers(true);
    UserService.getUsers(page, rowsPerPage)
      .then(({ data }) => {
        setUsers(data);
      })
      .catch((error) => {
        setRequestError(
          getErrorMessage(
            'There was a problem loading the users list. Pleasy notify an administrator.',
            error
          )
        );
      })
      .finally(() => setLoadingUsers(false));
  }, [page, rowsPerPage]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Page title="Users">
      <Container>
        <HeaderBreadcrumbs
          heading="Users"
          links={[]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.user.create}
              startIcon={<Icon icon={plusFill} />}
            >
              New user
            </Button>
          }
        />
        {requestError && (
          <Alert style={{ marginBottom: '1em' }} severity="error">
            {requestError}
          </Alert>
        )}
        <LoadingData show={loadingUsers} />
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table style={{ marginTop: 15 }}>
                <UserListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {users?.data.map((row) => {
                    const { id, firstName, lastName, userProfile, email } = row;
                    return (
                      <TableRow hover key={id}>
                        <TableCell align="left">{`${firstName} ${lastName}`}</TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">{userProfile.name}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            title="Editar"
                            component={RouterLink}
                            to={`${PATH_DASHBOARD.user.root}/edit/${id}`}
                          >
                            <Icon icon={editFill} width={18} height={18} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {users?.total === 0 && (
                    <TableRow style={{ height: 53 }}>
                      <TableCell colSpan={4}>
                        We couldn't find any user. Please create a new one using the "New user"
                        button.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            labelDisplayedRows={customLabelDisplayedRows}
            labelRowsPerPage="Users per page:"
            rowsPerPageOptions={[10, 50, 100]}
            component="div"
            count={users?.total || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
