// components
import { APP_ROLES } from 'src/guards/RoleBasedGuard';
import { PATH_DASHBOARD } from 'src/routes/paths';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  cms: getIcon('ic_cms'),
  settings: getIcon('ic_setting'),
  system: getIcon('ic_system'),
  dashboard: getIcon('ic_dashboard'),
  project: getIcon('ic_booking'),
  trivia: getIcon('ic_analytics'),
  ai: getIcon('ic_kanban'),
  courses: getIcon('ic_blog'),
};

const navConfig = [
  {
    subheader: '',
    items: [
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.dashboard,
        icon: ICONS.dashboard,
        roles: [APP_ROLES.Dashboard],
      },
      {
        title: 'Users',
        path: '',
        icon: ICONS.user,
        roles: [APP_ROLES.Users, APP_ROLES.UserProfiles],
        children: [
          { title: 'Users', path: PATH_DASHBOARD.user.list, roles: [APP_ROLES.Users] },
          {
            title: 'User profiles',
            path: PATH_DASHBOARD.userProfile.list,
            roles: [APP_ROLES.UserProfiles],
          },
        ],
      },
      {
        title: 'Projects',
        path: '',
        icon: ICONS.project,
        roles: [APP_ROLES.Projects],
        children: [
          { title: 'Projects', path: PATH_DASHBOARD.project.list, roles: [APP_ROLES.Projects] },
        ],
      },
      {
        title: 'Trivia',
        path: '',
        icon: ICONS.trivia,
        roles: [APP_ROLES.Questions],
        children: [
          { title: 'Questions', path: PATH_DASHBOARD.question.list, roles: [APP_ROLES.Questions] },
        ],
      },
      {
        title: 'AI Tools',
        path: '',
        icon: ICONS.ai,
        roles: [APP_ROLES.PostGenerator],
        children: [
          {
            title: 'Post generator',
            path: PATH_DASHBOARD.aiTools.postGenerator,
            roles: [APP_ROLES.PostGenerator],
          },
          {
            title: 'Post generator V2',
            path: PATH_DASHBOARD.aiTools.postGeneratorV2,
            roles: [APP_ROLES.PostGenerator],
          },
          {
            title: 'Playground',
            path: PATH_DASHBOARD.aiTools.playground,
            roles: [APP_ROLES.PostGenerator],
          },
          {
            title: 'Product content generator',
            path: PATH_DASHBOARD.aiTools.productContentGenerator,
            roles: [APP_ROLES.PostGenerator],
          },
          {
            title: 'Automatic Post Campaign',
            path: PATH_DASHBOARD.campaign.list,
            roles: [APP_ROLES.PostGenerator],
          },
          {
            title: 'Post campaign preparation',
            path: PATH_DASHBOARD.aiTools.postCampaignPreparation,
            roles: [APP_ROLES.PostGenerator],
          },
        ],
      },
      {
        title: 'Courses',
        path: '',
        icon: ICONS.courses,
        roles: [APP_ROLES.Projects, APP_ROLES.Chapters, APP_ROLES.Sections, APP_ROLES.Pages],
        children: [
          { title: 'Courses', path: PATH_DASHBOARD.course.list, roles: [APP_ROLES.Projects] },
        ],
      },
      {
        title: 'CMS',
        path: '',
        icon: ICONS.cms,
        roles: [APP_ROLES.CmsPages, APP_ROLES.MessageTemplates],
        children: [
          {
            title: 'Email templates',
            path: PATH_DASHBOARD.messageTemplate.list,
            roles: [APP_ROLES.MessageTemplates],
          },
        ],
      },
      {
        title: 'Settings',
        path: '',
        icon: ICONS.settings,
        roles: [APP_ROLES.EmailAccounts],
        children: [
          {
            title: 'Email account',
            path: PATH_DASHBOARD.emailAccount.list,
            roles: [APP_ROLES.EmailAccounts],
          },
        ],
      },
      {
        title: 'System',
        path: '',
        icon: ICONS.system,
        roles: [APP_ROLES.SystemInfo, APP_ROLES.Log, APP_ROLES.ScheduleTasks],
        children: [
          { title: 'Logs', path: PATH_DASHBOARD.log.list, roles: [APP_ROLES.Log] },
          {
            title: 'System info',
            path: PATH_DASHBOARD.systemInfo.root,
            roles: [APP_ROLES.SystemInfo],
          },
          {
            title: 'Schedule tasks',
            path: PATH_DASHBOARD.scheduleTask.root,
            roles: [APP_ROLES.ScheduleTasks],
          },
        ],
      },
    ],
  },
];

export default navConfig;
