import { API_URL } from './../app.config';
import axios, { AxiosError } from 'axios';
import { IAppUser, IToken } from './../interfaces/authentication.interface';
import { lskey_refreshToken, lskey_user } from './lsKeys';

// ----------------------------------------------------------------------

const lskey_token = 'user_token';
const refreshTokenUrl = '/auth/refresh';

const getAuthorizationHeaderValue = (): string => {
  const currentToken = localStorage.getItem(lskey_token);
  if (currentToken === null) return '';
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    console.log('Token: ', currentToken);
  }
  return `Bearer ${currentToken}`;
};

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    accept: 'application/json',
    Authorization: getAuthorizationHeaderValue(),
  },
});

const createAxiosResponseInterceptor = () => {
  axiosInstance.interceptors.request.use((request) => {
    if (request.headers && !request.headers.Authorization) {
      request.headers.Authorization = getAuthorizationHeaderValue();
    }
    return request;
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      const originalRequest = error.config;

      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(JSON.stringify(error));
      }

      const currentUserJson = localStorage.getItem(lskey_user);
      if (
        error.response === undefined ||
        error.response.status !== 401 ||
        currentUserJson === null
      ) {
        return Promise.reject(error);
      }

      const currentUser = JSON.parse(currentUserJson) as IAppUser;

      if (originalRequest.url === refreshTokenUrl && error.response?.status === 401) {
        // AuthService.logOut();
        return Promise.reject(error);
      }

      const body = {
        UserId: currentUser.id,
        RefreshToken: localStorage.getItem(lskey_refreshToken),
        DeviceUuid: 'react',
      };

      return axiosInstance
        .post<IToken>(refreshTokenUrl, body)
        .then((result) => {
          const newToken = result.data.accessToken;
          axiosInstance.defaults.headers.common.Authorization = `Bearer ${newToken}`;
          ///@ts-ignore
          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          localStorage.setItem(lskey_token, newToken);
          return axiosInstance.request(originalRequest);
        })
        .catch((error) => {
          console.log('ERROR REFRESHING', JSON.stringify(error));
          localStorage.removeItem(lskey_user);
          localStorage.removeItem(lskey_token);
          localStorage.removeItem(lskey_refreshToken);
          return Promise.reject(error);
        })
        .finally(createAxiosResponseInterceptor);
    }
  );
};

createAxiosResponseInterceptor();

export default axiosInstance;
