import {
  Alert,
  Button,
  Card,
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import { Icon } from '@iconify/react';
import plusFill from '@iconify-icons/eva/plus-fill';
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { Link as RouterLink } from 'react-router-dom';

import { PATH_DASHBOARD } from '../../../routes/paths';
import { useCallback, useEffect, useState } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { customLabelDisplayedRows } from 'src/utils/common';
import { getErrorMessage } from 'src/utils/request';
import CourseService from 'src/services/course.service';
import CourseListHead from './components/ListHead';
import { useSnackbar } from 'notistack';
import DeleteElementModal from 'src/components/DeleteElementModal';
import LoadingData from 'src/components/LoadingData';
import { IProject, IProjectList } from 'src/interfaces/project.interface';
import editOutline from '@iconify-icons/eva/edit-outline';
import bookOpenOutline from '@iconify-icons/eva/book-open-outline';
import cloudUploadFill from '@iconify-icons/eva/cloud-upload-fill';
import ImportCourseModal from 'src/components/ImportCourseModal';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'id', label: 'Id', alignRight: false },
  { id: 'options', label: 'Settings', alignRight: true },
];

// ----------------------------------------------------------------------

export default function CourseListPage() {
  const { enqueueSnackbar } = useSnackbar();
  const [courses, setCourses] = useState<IProjectList>();
  const [loadingCourses, setLoadingCourses] = useState<boolean>(true);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState(0);
  const [requestError, setRequestError] = useState<string>();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);
  const [selectedCourse, setSelectedCourse] = useState<IProject>();
  const [deletingCourse, setDeletingCourse] = useState<boolean>(false);
  const [importContentProjectId, setImportContentProjectId] = useState<string>('');

  const loadCourses = useCallback(() => {
    setLoadingCourses(true);
    CourseService.getCourses(page, rowsPerPage)
      .then(({ data }) => {
        setCourses(data);
      })
      .catch((error) => {
        setRequestError(
          getErrorMessage(
            'There was a problem while loading the courses. Please notify an administrator.',
            error
          )
        );
      })
      .finally(() => setLoadingCourses(false));
  }, [page, rowsPerPage]);

  useEffect(() => {
    loadCourses();
  }, [loadCourses]);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDeleteConfirmationModal = (course: IProject) => {
    setSelectedCourse(course);
    setOpenDeleteConfirmation(true);
  };

  const handleCloseDeleteConfirmationModal = () => {
    setSelectedCourse(undefined);
    setOpenDeleteConfirmation(false);
  };

  const deleteCourse = () => {
    if (!selectedCourse) return;
    setDeletingCourse(true);
    CourseService.deleteCourse(selectedCourse.id || '')
      .then(() => {
        enqueueSnackbar('The course has been deleted succesfully.', {
          variant: 'success',
        });
        if (!courses) return;
        const currentList = { ...courses };
        const updatedList = currentList.data.filter((x) => x.id !== selectedCourse.id);
        currentList.data = updatedList;
        setCourses(currentList);
        handleCloseDeleteConfirmationModal();
      })
      .catch((error) => {
        enqueueSnackbar('It was not possible to delete the course.', { variant: 'error' });
      })
      .finally(() => setDeletingCourse(false));
  };

  return (
    <Page title="Courses">
      <Container>
        <HeaderBreadcrumbs
          heading="Courses"
          links={[]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.project.create}
              startIcon={<Icon icon={plusFill} />}
            >
              New course project
            </Button>
          }
        />
        {requestError && (
          <Alert style={{ marginBottom: '1em' }} severity="error">
            {requestError}
          </Alert>
        )}
        <LoadingData show={loadingCourses} />
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table style={{ marginTop: 15 }}>
                <CourseListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {courses?.data.map((row) => {
                    const { id, name } = row;
                    return (
                      <TableRow hover key={id}>
                        <TableCell align="left">{name}</TableCell>
                        <TableCell align="left">{id}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            title="Edit project"
                            href={`${PATH_DASHBOARD.project.root}/edit/${id}`}
                          >
                            <Icon icon={editOutline} width={24} height={24} />
                          </IconButton>
                          <IconButton
                            title="View chapters"
                            href={`${PATH_DASHBOARD.course.root}/${id}/chapters`}
                          >
                            <Icon icon={bookOpenOutline} width={24} height={24} />
                          </IconButton>
                          <IconButton
                            onClick={() => setImportContentProjectId(id || '')}
                            title="Import content"
                          >
                            <Icon icon={cloudUploadFill} width={24} height={24} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {courses?.data.length === 0 && (
                    <TableRow style={{ height: 53 }}>
                      <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                        We couldn't find any course. Please create a new one using the "New course"
                        button.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            labelDisplayedRows={customLabelDisplayedRows}
            labelRowsPerPage="Courses per page:"
            rowsPerPageOptions={[10, 50, 100]}
            component="div"
            count={courses?.total || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <DeleteElementModal
        openDeleteConfirmation={openDeleteConfirmation}
        handleCloseDeleteConfirmationModal={handleCloseDeleteConfirmationModal}
        selectedElementName={selectedCourse?.name}
        deletingElement={deletingCourse}
        deleteElementHandler={deleteCourse}
      />
      <ImportCourseModal
        projectId={importContentProjectId}
        openImportModal={importContentProjectId ? true : false}
        handleCloseImportModal={() => {
          setImportContentProjectId('');
        }}
      />
    </Page>
  );
}
