// material
import { Container } from '@mui/material';
// components
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import { useParams } from 'react-router-dom';
import UserProfileForm from './components/UserProfileForm';

// ----------------------------------------------------------------------

export default function UserProfileEditPage() {
  const { userProfileId } = useParams();

  return (
    <Page title="Edit user profile">
      <Container>
        <HeaderBreadcrumbs
          heading="Edit user profile"
          links={[
            { name: 'User profiles', href: PATH_DASHBOARD.userProfile.list },
            { name: 'Edit user profile' }
          ]}
        />
        <UserProfileForm userProfileId={userProfileId} />
      </Container>
    </Page>
  );
}
