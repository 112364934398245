// material
import { Container } from '@mui/material';
// components
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import UserForm from './components/UserForm';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------
export default function UserCreatePage() {
  return (
    <Page title="Create new user">
      <Container>
        <HeaderBreadcrumbs
          heading="Create new user"
          links={[{ name: 'Users', href: PATH_DASHBOARD.user.list }, { name: 'Create user' }]}
        />
        <UserForm />
      </Container>
    </Page>
  );
}
