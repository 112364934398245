import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Grid,
  Stack,
  TextField,
  Alert,
  Container,
  InputAdornment,
  Tooltip,
  IconButton,
  FormControlLabel,
  Switch,
  Typography,
  Button,
} from '@mui/material';
import LoadingScreen from 'src/components/LoadingScreen';
import { getErrorMessage } from 'src/utils/request';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { IQuestion, IQuestionFormFields } from 'src/interfaces/question.interface';
import { Icon } from '@iconify/react';
import questionMarkCircleFill from '@iconify-icons/eva/question-mark-circle-fill';
import QuestionService from 'src/services/question.service';
import { ISelectList } from 'src/interfaces/common.interface';
import ProjectService from 'src/services/project.service';
import { API_URL } from 'src/app.config';

// ----------------------------------------------------------------------

type QuestionFormProps = {
  questionId?: string;
};

const formValidationSchema: Yup.SchemaOf<IQuestionFormFields> = Yup.object().shape({
  value: Yup.string().max(100, 'The max length is 100 characters.').required('The question value is required.'),
  option1: Yup.string().max(100, 'The max length is 100 characters.').required('The option 1 (answer) is required.'),
  option2: Yup.string().max(100, 'The max length is 100 characters.').required('The option 2 value is required.'),
  option3: Yup.string().max(100, 'The max length is 100 characters.').required('The option 3 value is required.'),
  option4: Yup.string().max(100, 'The max length is 100 characters.').required('The option 4 value is required.'),
  projectId: Yup.string().required('The project is required.'),
  isActive: Yup.boolean().required(),
  twoLetterLanguageCode: Yup.string().required('The language is required.'),
});

export default function QuestionForm({ questionId }: QuestionFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingQuestion, setLoadingQuestion] = useState<boolean>(false);
  const [projects, setProjects] = useState<ISelectList[]>([]);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [requestError, setRequestError] = useState<string>();
  const [newPicture, setNewPicture] = useState<File>();
  const [currentPictureId, setCurrentPictureId] = useState<string>();
  const [formInitialValues, setFormInitialValues] = useState<IQuestionFormFields>({
    value: '',
    option1: '',
    option2: '',
    option3: '',
    option4: '',
    projectId: '',
    isActive: true,
    twoLetterLanguageCode: 'en',
  });

  useEffect(() => {
    getProjects();
  }, []);

  const loadQuestion = useCallback(() => {
    if (!questionId) return;
    setLoadingQuestion(true);
    QuestionService.getQuestionById(questionId)
      .then(({ data }) => {
        setFormInitialValues((prevState) => ({
          ...prevState,
          value: data.value,
          option1: data.option1,
          option2: data.option2,
          option3: data.option3,
          option4: data.option4,
          projectId: data.projectId,
          twoLetterLanguageCode: data.twoLetterLanguageCode,
          isActive: data.isActive,
        }));
        setCurrentPictureId(data.pictureId);
      })
      .catch((error) => {
        setDisableSubmit(true);
        setRequestError(
          getErrorMessage(
            "There was a problem and we weren't able to load the question. Please notify an administrator.",
            error
          )
        );
      })
      .finally(() => setLoadingQuestion(false));
  }, [questionId]);

  useEffect(() => {
    loadQuestion();
  }, [loadQuestion]);

  const onSubmit = (
    values: IQuestionFormFields,
    formikHelpers: FormikHelpers<IQuestionFormFields>
  ) => {
    if (!questionId) createQuestion(values, formikHelpers);
    else updateQuestion(values, formikHelpers);
  };

  const createQuestion = (
    values: IQuestionFormFields,
    formikHelpers: FormikHelpers<IQuestionFormFields>
  ) => {
    var data = new FormData();
    data.set('value', values.value);
    data.set('option1', values.option1);
    data.set('option2', values.option2);
    data.set('option3', values.option3);
    data.set('option4', values.option4);
    data.set('projectId', values.projectId);
    data.set('isActive', values.isActive.toString());
    data.set('twoLetterLanguageCode', values.twoLetterLanguageCode);
    if (newPicture) data.append('pictureFile', newPicture);

    QuestionService.createQuestion(data)
      .then(() => {
        formikHelpers.resetForm();
        enqueueSnackbar('The question has been created successfully.', { variant: 'success' });
        navigate(PATH_DASHBOARD.question.list);
      })
      .catch((error) => {
        setRequestError(getErrorMessage("We weren't able to create the question.", error));
      })
      .finally(() => formikHelpers.setSubmitting(false));
  };

  const getProjects = () => {
    ProjectService.getProjectSelectList()
      .then(({ data }) => {
        setProjects(data);
      })
      .catch((error) => {
        setDisableSubmit(true);
        setRequestError(
          getErrorMessage(
            "We weren't able to load the projects. Please notify an administrator.",
            error
          )
        );
      });
  };

  const updateQuestion = (
    values: IQuestionFormFields,
    formikHelpers: FormikHelpers<IQuestionFormFields>
  ) => {
    if (!questionId) return;

    var data = new FormData();
    data.set('id', questionId);
    data.set('value', values.value);
    data.set('option1', values.option1);
    data.set('option2', values.option2);
    data.set('option3', values.option3);
    data.set('option4', values.option4);
    data.set('projectId', values.projectId);
    data.set('isActive', values.isActive.toString());
    data.set('twoLetterLanguageCode', values.twoLetterLanguageCode);
    if (newPicture) data.append('pictureFile', newPicture);

    QuestionService.updateQuestion(data)
      .then(() => {
        formikHelpers.resetForm();
        enqueueSnackbar('The question has been updated successfully.', {
          variant: 'success',
        });
        navigate(PATH_DASHBOARD.question.list);
      })
      .catch((error) => {
        setRequestError(getErrorMessage("We weren't able to update the question", error));
      })
      .finally(() => formikHelpers.setSubmitting(false));
  };

  const handleSelectedPicture = (value: any) => {
    if (!value || !value[0]) return;
    setNewPicture(value[0]);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    onSubmit,
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values } = formik;

  return (
    <>
      {loadingQuestion ? (
        <Container>
          <LoadingScreen
            sx={{
              ...{ width: '50%', margin: '10% auto 0 auto' },
            }}
          />
        </Container>
      ) : (
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        label="Value"
                        {...getFieldProps('value')}
                        error={Boolean(touched.value && errors.value)}
                        helperText={touched.value && errors.value}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="The value (text) of the question.">
                                <IconButton>
                                  <Icon icon={questionMarkCircleFill} width={24} height={24} />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        label="Option 1 (answer)"
                        {...getFieldProps('option1')}
                        error={Boolean(touched.option1 && errors.option1)}
                        helperText={touched.option1 && errors.option1}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="An option for the trivia (must be the right answer)">
                                <IconButton>
                                  <Icon icon={questionMarkCircleFill} width={24} height={24} />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        fullWidth
                        label="Option 2"
                        {...getFieldProps('option2')}
                        error={Boolean(touched.option2 && errors.option2)}
                        helperText={touched.option2 && errors.option2}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="An option for the trivia">
                                <IconButton>
                                  <Icon icon={questionMarkCircleFill} width={24} height={24} />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        label="Option 3"
                        {...getFieldProps('option3')}
                        error={Boolean(touched.option3 && errors.option3)}
                        helperText={touched.option3 && errors.option3}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="An option for the trivia">
                                <IconButton>
                                  <Icon icon={questionMarkCircleFill} width={24} height={24} />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        fullWidth
                        label="Option 4"
                        {...getFieldProps('option4')}
                        error={Boolean(touched.option4 && errors.option4)}
                        helperText={touched.option4 && errors.option4}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="An option for the trivia">
                                <IconButton>
                                  <Icon icon={questionMarkCircleFill} width={24} height={24} />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        select
                        fullWidth
                        label="Project"
                        placeholder="Project"
                        {...getFieldProps('projectId')}
                        SelectProps={{ native: true }}
                        error={Boolean(touched.projectId && errors.projectId)}
                        helperText={touched.projectId && errors.projectId}
                        InputLabelProps={{ shrink: true }}
                      >
                        <option disabled value="">
                          Select the project...
                        </option>
                        {projects.map((element) => (
                          <option key={element.value} value={element.value}>
                            {element.text}
                          </option>
                        ))}
                      </TextField>
                      <TextField
                        select
                        fullWidth
                        label="Language"
                        placeholder="Language"
                        {...getFieldProps('twoLetterLanguageCode')}
                        SelectProps={{ native: true }}
                        error={Boolean(
                          touched.twoLetterLanguageCode && errors.twoLetterLanguageCode
                        )}
                        helperText={touched.twoLetterLanguageCode && errors.twoLetterLanguageCode}
                        InputLabelProps={{ shrink: true }}
                      >
                        <option disabled value="">
                          Select the question language...
                        </option>
                        <option value="en">English</option>
                        <option value="es">Spanish</option>
                      </TextField>
                    </Stack>
                    <Stack spacing={2}>
                      <input
                        accept="image/*"
                        id="contained-button-image-file"
                        type="file"
                        onChange={(event) => handleSelectedPicture(event.target.files)}
                        style={{ display: 'none' }}
                      />
                      <Box component="div">
                        <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                          Select the question picture (optional)
                        </Typography>
                        {newPicture ? (
                          <>
                            <img
                              width={100}
                              height={100}
                              src={URL.createObjectURL(newPicture)}
                              alt="question"
                            />
                            <br />
                          </>
                        ) : currentPictureId ? (
                          <>
                            <img
                              width={100}
                              height={100}
                              src={`${API_URL}File/GetPicture?id=${currentPictureId}`}
                              alt="question"
                            />
                            <br />
                          </>
                        ) : null}
                        <label htmlFor="contained-button-image-file">
                          <Button variant="contained" component="span">
                            Select picture
                          </Button>
                        </label>
                        <Typography variant="caption" sx={{ mt: 0.5 }}>
                          <br />
                          Recommended size: 500x300 pixels
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            labelPlacement="start"
                            control={
                              <Switch {...getFieldProps('isActive')} checked={values.isActive} />
                            }
                            label={
                              <>
                                <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                                  Is active?
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                  If the question is not active users wouldn't be able to see it and
                                  answer it.
                                </Typography>
                              </>
                            }
                            sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                    {requestError && <Alert severity="error">{requestError}</Alert>}
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        disabled={disableSubmit}
                      >
                        {!questionId ? 'Create question' : 'Save changes'}
                      </LoadingButton>
                    </Box>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      )}
    </>
  );
}
