import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Grid,
  Stack,
  TextField,
  Container,
  InputAdornment,
  Tooltip,
  IconButton,
  FormControlLabel,
  Checkbox,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Alert,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Accordion,
  useTheme,
  AccordionSummary,
  AccordionDetails,
  TablePagination,
} from '@mui/material';
import LoadingScreen from 'src/components/LoadingScreen';
import { getErrorMessage } from 'src/utils/request';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { ICampaign } from 'src/interfaces/campaign.interface';
import { Icon } from '@iconify/react';
import questionMarkCircleFill from '@iconify-icons/eva/question-mark-circle-fill';
import arrowRightFill from '@iconify-icons/eva/arrow-right-fill';
import arrowDownFill from '@iconify-icons/eva/arrow-down-fill';
import closeCircleFill from '@iconify-icons/eva/close-circle-fill';
import refreshFill from '@iconify-icons/eva/refresh-fill';
import trash2Fill from '@iconify-icons/eva/trash-2-fill';
import searchFill from '@iconify-icons/eva/search-fill';
import pauseCircleFill from '@iconify-icons/eva/pause-circle-fill';
import playCircleFill from '@iconify-icons/eva/play-circle-fill';
import CampaignService from 'src/services/campaign.service';
import { IProject } from 'src/interfaces/project.interface';
import WordpressService from 'src/services/wordpress.service';
import Scrollbar from 'src/components/Scrollbar';
import CampaignListHead from './CampaignListHead';
import { IAutomaticPost } from 'src/interfaces/automatic-post.interface';
import { fDateTime } from 'src/utils/formatTime';
import React from 'react';
import { customLabelDisplayedRows } from 'src/utils/common';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'expand', label: '', alignRight: false },
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'category', label: 'Category', alignRight: false },
  { id: 'cost', label: 'Cost', alignRight: false },
  { id: 'dontProcessBeforeDateUtc', label: 'Processing date', alignRight: false },
  { id: 'currentStatusTypeName', label: 'Status', alignRight: false },
  { id: 'cancelPost', label: 'Options', alignRight: false },
];

const generateStatusElement = (status: string) => {
  switch (status) {
    case 'Cancelled':
      return <Chip label={status} color="error" />;
    case 'Processing':
      return <Chip label={status} color="warning" />;
    case 'Completed':
      return <Chip label={status} color="success" />;
    default:
      return <Chip label={status} color="info" />;
  }
};

type CampaignFormProps = {
  campaignId?: string;
};

export default function CampaignForm({ campaignId }: CampaignFormProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const [loadingCampaign, setLoadingCampaign] = useState<boolean>(false);
  const [loadingProjects, setLoadingProjects] = useState<boolean>(false);
  const [massivelyEdit, setMasivellyEdit] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [requestError, setRequestError] = useState<string>();
  const [projects, setProjects] = useState<IProject[]>([]);
  const [automaticPosts, setAutomaticPosts] = useState<IAutomaticPost[]>([]);
  const [filteredAutomaticPosts, setFilteredAutomaticPosts] = useState<IAutomaticPost[]>([]);
  const [paginatedAutomaticPosts, setPaginatedAutomaticPosts] = useState<IAutomaticPost[]>([]);
  const [canMassiveUpdate, setCanMassiveUpdate] = useState<boolean>();
  const [retryingCancelled, setRetryingCancelled] = useState<boolean>(false);
  const [resumingPaused, setResumingPaused] = useState<boolean>(false);
  const [cancellingCampaign, setCancellingCampaign] = useState<boolean>(false);
  const [pausingCampaign, setPausingCampaign] = useState<boolean>(false);
  const [cancelledPostCount, setCancelledPostCount] = useState<number>(0);
  const [scheduledPostCount, setScheduledPostCount] = useState<number>(0);
  const [processingPostCount, setProcessingPostCount] = useState<number>(0);
  const [completedPostCount, setCompletedPostCount] = useState<number>(0);
  const [pausedPostCount, setPausedPostCount] = useState<number>(0);
  const [openedRows, setOpenedRows] = useState<string[]>([]);
  const [cancellingPost, setCancellingPost] = useState<boolean>(false);
  const [cancelPostId, setCancelPostId] = useState<string>('');
  const [pausingPost, setPausingPost] = useState<boolean>(false);
  const [pausePostId, setPausePostId] = useState<string>('');
  const [resumingPost, setResumingPost] = useState<boolean>(false);
  const [resumePostId, setResumePostId] = useState<string>('');
  const [retryingPost, setRetryingPost] = useState<boolean>(false);
  const [deletingPost, setDeletingPost] = useState<boolean>(false);
  const [retryPostId, setRetryPostId] = useState<string>('');
  const [deletePostId, setDeletePostId] = useState<string>('');
  const [rowsPerPage, setRowsPerPage] = useState<number>(100);
  const [page, setPage] = useState(0);

  const [selectedStatusFilter, setSelectedStatusFilter] = useState<string>('');

  // Form
  const [campaignName, setCampaignName] = useState<string>('');
  const [selectedLang, setSelectedLang] = useState<string>('en');
  const [includeIntroduction, setIncludeIntroduction] = useState<boolean>(true);
  const [includeConclusion, setIncludeConclusion] = useState<boolean>(true);
  const [includeVideo, setIncludeVideo] = useState<boolean>(true);
  const [rewriteSubtitles, setRewriteSubtitles] = useState<boolean>(true);
  const [numberOfSubtitles, setNumberOfSubtitles] = useState<number>(2);
  const [numberOfImages, setNumberOfImages] = useState<number>(2);
  const [aiModel, setAiModel] = useState<number>(10);
  const [selectedProjectId, setSelectedProjectId] = useState<string>('');
  const [selectedPostStatus, setSelectedPostStatus] = useState<string>('publish');
  const [minutesBetweenPublication, setMinutesBetweenPublication] = useState<number>(60);
  const [titlesListRaw, setTitlesListRaw] = useState<string>('');

  const loadCampaign = useCallback(() => {
    if (!campaignId) return;
    setLoadingCampaign(true);
    CampaignService.getCampaignById(campaignId)
      .then(({ data }) => {
        setCampaignName(data.name);
        setSelectedLang(data.twoLetterLanguageCode);
        setIncludeConclusion(data.includeConclusion);
        setIncludeIntroduction(data.includeIntroduction);
        setIncludeVideo(data.includeVideo);
        setRewriteSubtitles(data.rewriteSubtitles);
        setNumberOfSubtitles(data.numberOfSubtitles);
        setNumberOfImages(data.numberOfImages);
        setAiModel(data.aiModel);
        setSelectedProjectId(data.projectId);
        setSelectedPostStatus(data.postStatus);
        setMinutesBetweenPublication(data.minutesBetweenPublication || 5);
        setAutomaticPosts(data.automaticPosts || []);
        setFilteredAutomaticPosts(data.automaticPosts || []);
        if (data.automaticPosts) {
          let statusTypes = data.automaticPosts.map((x) => x.currentStatusType);
          setCanMassiveUpdate([10, 50, 60].some((x) => statusTypes.includes(x)));
          setCancelledPostCount(statusTypes.filter((x) => x == 40).length);
          setScheduledPostCount(statusTypes.filter((x) => x == 10 || x == 50).length);
          setProcessingPostCount(statusTypes.filter((x) => x == 20).length);
          setCompletedPostCount(statusTypes.filter((x) => x == 30).length);
          setPausedPostCount(statusTypes.filter((x) => x == 60).length);
        }

        handleSelectedFilter();
        setPaginatedData(data.automaticPosts || []);
      })
      .catch((error) => {
        setDisableSubmit(true);
        setRequestError(
          getErrorMessage(
            "There was a problem and we weren't able to load the campaign. Please notify an administrator.",
            error
          )
        );
      })
      .finally(() => setLoadingCampaign(false));
  }, [campaignId]);

  useEffect(() => {
    loadCampaign();
  }, [loadCampaign]);

  useEffect(() => {
    loadProjects();
  }, []);

  useEffect(() => {
    setPaginatedData(filteredAutomaticPosts);
  }, [page, filteredAutomaticPosts, rowsPerPage]);

  useEffect(() => {
    handleSelectedFilter();
  }, [selectedStatusFilter]);

  const loadProjects = () => {
    if (submitting || loadingProjects) return;
    setLoadingProjects(true);
    setRequestError('');
    WordpressService.getWordpressProjects()
      .then(({ data }) => {
        setProjects(data);
      })
      .catch((error) => {
        setRequestError(
          getErrorMessage(
            "We weren't able to load the projects. Please notify an administrator",
            error
          )
        );
      })
      .finally(() => setLoadingProjects(false));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const submit = () => {
    setRequestError('');
    setSubmitting(true);
    if (!campaignId) createCampaign();
    else updateCampaign();
  };

  const createCampaign = () => {
    const campaign: ICampaign = {
      name: campaignName,
      includeIntroduction,
      includeConclusion,
      includeVideo,
      numberOfImages,
      numberOfSubtitles,
      twoLetterLanguageCode: selectedLang,
      rewriteSubtitles,
      projectId: selectedProjectId,
      listOfTitlesWithCategory: titlesListRaw.split('\n').filter((x) => x.length > 0),
      minutesBetweenPublication,
      massiveUpdatePosts: false,
      postStatus: selectedPostStatus,
      aiModel,
    };
    let invalidTitle = campaign.listOfTitlesWithCategory.filter((x) => x.split(';').length < 3);
    if (invalidTitle.length > 1) {
      setRequestError(
        'Some of the titles are invalid (title with less than 2 semicolons detected)'
      );
      return;
    }
    CampaignService.createCampaign(campaign)
      .then(() => {
        enqueueSnackbar('The campaign has been created successfully.', { variant: 'success' });
        navigate(PATH_DASHBOARD.campaign.list);
      })
      .catch((error) => {
        setRequestError(
          getErrorMessage(
            "We weren't able to create the campaign. Verify project credentials.",
            error
          )
        );
      })
      .finally(() => setSubmitting(false));
  };

  const updateCampaign = () => {
    if (!campaignId || submitting) return;
    const campaign: ICampaign = {
      id: campaignId,
      name: campaignName,
      includeIntroduction,
      includeConclusion,
      includeVideo,
      numberOfImages,
      numberOfSubtitles,
      twoLetterLanguageCode: selectedLang,
      rewriteSubtitles,
      projectId: selectedProjectId,
      listOfTitlesWithCategory: titlesListRaw.split('\n').filter((x) => x.length > 0),
      minutesBetweenPublication,
      massiveUpdatePosts: massivelyEdit,
      postStatus: selectedPostStatus,
      aiModel,
    };

    CampaignService.updateCampaign(campaign)
      .then(() => {
        enqueueSnackbar('The campaign has been updated successfully.', {
          variant: 'success',
        });
        navigate(PATH_DASHBOARD.campaign.list);
      })
      .catch((error) => {
        setRequestError(getErrorMessage("We weren't able to update the campaign", error));
      })
      .finally(() => setSubmitting(false));
  };

  const retryCancelledPosts = () => {
    if (retryingCancelled || cancelledPostCount == 0 || !campaignId) return;
    setRetryingCancelled(true);
    CampaignService.retryAllCancelledPosts(campaignId)
      .then(() => loadCampaign())
      .catch((error) => {
        setRequestError(getErrorMessage("We weren't able to update the campaign", error));
      })
      .finally(() => setRetryingCancelled(false));
  };

  const resumePausedPosts = () => {
    if (resumingPaused || pausedPostCount == 0 || !campaignId) return;
    setResumingPaused(true);
    CampaignService.resumeAllPausedPosts(campaignId)
      .then(() => loadCampaign())
      .catch((error) => {
        setRequestError(getErrorMessage("We weren't able to resume the campaign", error));
      })
      .finally(() => setResumingPaused(false));
  };

  const cancelCampaign = () => {
    if (cancellingCampaign || (scheduledPostCount == 0 && pausedPostCount == 0) || !campaignId)
      return;
    setCancellingCampaign(true);
    CampaignService.cancelCampaign(campaignId)
      .then(() => loadCampaign())
      .catch((error) => {
        setRequestError(getErrorMessage("We weren't able to cancel the campaign", error));
      })
      .finally(() => setCancellingCampaign(false));
  };

  const pauseCampaign = () => {
    if (pausingCampaign || scheduledPostCount == 0 || !campaignId) return;
    setPausingCampaign(true);
    CampaignService.pauseCampaign(campaignId)
      .then(() => loadCampaign())
      .catch((error) => {
        setRequestError(getErrorMessage("We weren't able to pause the campaign", error));
      })
      .finally(() => setPausingCampaign(false));
  };

  const handleOpenedRow = (id: string) => {
    let index = openedRows.indexOf(id);
    let updatedRows = [...openedRows];
    if (index > -1) updatedRows.splice(index, 1);
    else updatedRows.push(id);
    setOpenedRows(updatedRows);
  };

  const handleCancelPost = () => {
    if (cancellingPost || !cancelPostId) return;
    setCancellingPost(true);
    CampaignService.cancelPost(cancelPostId)
      .then(() => {
        setCancelPostId('');
        loadCampaign();
      })
      .catch((error) =>
        setRequestError(getErrorMessage("We weren't able to cancel the post", error))
      )
      .finally(() => setCancellingPost(false));
  };

  const handleRetryPost = () => {
    if (retryingPost || !retryPostId) return;
    setRetryingPost(true);
    CampaignService.retryCancelledPost(retryPostId)
      .then(() => {
        setRetryPostId('');
        loadCampaign();
      })
      .catch((error) =>
        setRequestError(getErrorMessage("We weren't able to retry the post", error))
      )
      .finally(() => setRetryingPost(false));
  };

  const handleDeletePost = () => {
    if (deletingPost || !deletePostId) return;
    setDeletingPost(true);
    CampaignService.deletePost(deletePostId)
      .then(() => {
        setDeletePostId('');
        loadCampaign();
      })
      .catch((error) =>
        setRequestError(getErrorMessage("We weren't able to delete the post", error))
      )
      .finally(() => setDeletingPost(false));
  };

  const handlePausePost = () => {
    if (pausingPost || !pausePostId) return;
    setPausingPost(true);
    CampaignService.pausePost(pausePostId)
      .then(() => {
        setPausePostId('');
        loadCampaign();
      })
      .catch((error) =>
        setRequestError(getErrorMessage("We weren't able to pause the post", error))
      )
      .finally(() => setPausingPost(false));
  };

  const handleResumePost = () => {
    if (resumingPost || !resumePostId) return;
    setResumingPost(true);
    CampaignService.resumePausedPost(resumePostId)
      .then(() => {
        setResumePostId('');
        loadCampaign();
      })
      .catch((error) =>
        setRequestError(getErrorMessage("We weren't able to resume the post", error))
      )
      .finally(() => setResumingPost(false));
  };

  const handleSelectedFilter = () => {
    if (automaticPosts.length == 0) return;
    let updatedData = [...automaticPosts];
    if (selectedStatusFilter == '10')
      updatedData = updatedData.filter(
        (x) => x.currentStatusType.toString() == '10' || x.currentStatusType.toString() == '50'
      );
    else if (selectedStatusFilter)
      updatedData = updatedData.filter(
        (x) => x.currentStatusType.toString() == selectedStatusFilter
      );
    setFilteredAutomaticPosts(updatedData);
    setPage(0);
  };

  const setPaginatedData = (data: IAutomaticPost[]) => {
    let updatedData = data.slice(page * rowsPerPage, rowsPerPage * (page + 1));
    setPaginatedAutomaticPosts(updatedData);
  };

  return (
    <>
      {loadingCampaign ? (
        <Container>
          <LoadingScreen
            sx={{
              ...{ width: '50%', margin: '10% auto 0 auto' },
            }}
          />
        </Container>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    value={campaignName}
                    onChange={(e) => setCampaignName(e.target.value)}
                    fullWidth
                    label="Campaign name"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="The name of the campaign, just to informational purposes.">
                            <IconButton>
                              <Icon icon={questionMarkCircleFill} width={24} height={24} />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    disabled={submitting}
                    error={projects.length === 0}
                    select
                    fullWidth
                    label="Project"
                    placeholder="Select project"
                    SelectProps={{ native: true }}
                    InputLabelProps={{ shrink: true }}
                    value={selectedProjectId}
                    helperText={
                      projects.length === 0 &&
                      'No projects were found. Project must be configured for Wordpress.'
                    }
                    onChange={(e) => {
                      setSelectedProjectId(e.target.value);
                    }}
                  >
                    <option value="" disabled>
                      Select project...
                    </option>
                    {projects.map((element) => (
                      <option key={element.id} value={element.id}>
                        {element.name}
                      </option>
                    ))}
                  </TextField>
                </Stack>
                {campaignId && canMassiveUpdate && (
                  <FormControlLabel
                    label="Massively edit remaining pending post?"
                    control={
                      <Checkbox
                        disabled={submitting}
                        onChange={(event) => setMasivellyEdit(event.target.checked)}
                        checked={massivelyEdit}
                      />
                    }
                  />
                )}
                {massivelyEdit && (
                  <Alert style={{ marginBottom: '1em' }} severity="warning">
                    If you massively update the campaign, all the pending automatic posts will be
                    updated according to the new configuration. Also the scheduled time will be
                    updated, if you don't want to update the scheduled publication time be sure to
                    select "Don't change" in time option. This only affects scheduled, postponed and
                    paused posts.
                  </Alert>
                )}
                {((campaignId && massivelyEdit) || !campaignId) && (
                  <>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        select
                        fullWidth
                        label="Language"
                        placeholder="Language"
                        SelectProps={{ native: true }}
                        InputLabelProps={{ shrink: true }}
                        value={selectedLang}
                        onChange={(e) => {
                          setSelectedLang(e.target.value);
                        }}
                      >
                        <option value="en">English</option>
                        <option value="es">Spanish</option>
                      </TextField>
                      <TextField
                        select
                        fullWidth
                        label="Number of subtitles"
                        placeholder="Number of subtitles"
                        SelectProps={{ native: true }}
                        InputLabelProps={{ shrink: true }}
                        value={numberOfSubtitles}
                        onChange={(e) => {
                          setNumberOfSubtitles(parseInt(e.target.value));
                        }}
                      >
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </TextField>
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        select
                        fullWidth
                        label="Number of images"
                        placeholder="Number of images"
                        SelectProps={{ native: true }}
                        InputLabelProps={{ shrink: true }}
                        value={numberOfImages}
                        onChange={(e) => {
                          setNumberOfImages(parseInt(e.target.value));
                        }}
                      >
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                      </TextField>
                      <TextField
                        select
                        fullWidth
                        label="Ai Model"
                        placeholder="Select Ai Model"
                        SelectProps={{ native: true }}
                        InputLabelProps={{ shrink: true }}
                        value={aiModel}
                        onChange={(e) => {
                          setAiModel(parseInt(e.target.value));
                        }}
                      >
                        <option value="10">ChatGPT</option>
                        <option value="20">GPT4</option>
                      </TextField>
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        select
                        fullWidth
                        label="Time between publications"
                        placeholder="Select how much time between publications"
                        SelectProps={{ native: true }}
                        InputLabelProps={{ shrink: true }}
                        value={minutesBetweenPublication}
                        onChange={(e) => {
                          setMinutesBetweenPublication(parseInt(e.target.value));
                        }}
                      >
                        {massivelyEdit && <option value="-1">Don't change</option>}
                        <option value="1">1 minute</option>
                        <option value="5">5 minutes</option>
                        <option value="15">15 minutes</option>
                        <option value="30">30 minutes</option>
                        <option value="60">1 hour</option>
                        <option value="90">1.5 hours</option>
                        <option value="120">2 hours</option>
                        <option value="180">3 hours</option>
                        <option value="300">5 hours</option>
                        <option value="600">10 hours</option>
                        <option value="900">15 hours</option>
                        <option value="1200">20 hours</option>
                        <option value="1440">24 hours</option>
                      </TextField>
                      <TextField
                        disabled={submitting}
                        select
                        fullWidth
                        label="Posts status"
                        placeholder="Select the wordpress status of the posts"
                        SelectProps={{ native: true }}
                        InputLabelProps={{ shrink: true }}
                        value={selectedPostStatus}
                        onChange={(e) => setSelectedPostStatus(e.target.value)}
                      >
                        <option value="" disabled>
                          Select status...
                        </option>
                        <option value="draft">Draft</option>
                        <option value="publish">Publish</option>
                      </TextField>
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <FormControlLabel
                        label="Include introduction?"
                        control={
                          <Checkbox
                            disabled={submitting}
                            onChange={(event) => setIncludeIntroduction(event.target.checked)}
                            checked={includeIntroduction}
                          />
                        }
                      />
                      <FormControlLabel
                        label="Include conclusion?"
                        control={
                          <Checkbox
                            disabled={submitting}
                            onChange={(event) => setIncludeConclusion(event.target.checked)}
                            checked={includeConclusion}
                          />
                        }
                      />
                      <FormControlLabel
                        label="Include video?"
                        control={
                          <Checkbox
                            disabled={submitting}
                            onChange={(event) => setIncludeVideo(event.target.checked)}
                            checked={includeVideo}
                          />
                        }
                      />
                      {numberOfSubtitles > 0 && (
                        <FormControlLabel
                          label="Rewrite subtitles?"
                          control={
                            <Checkbox
                              disabled={submitting}
                              onChange={(event) => setRewriteSubtitles(event.target.checked)}
                              checked={rewriteSubtitles}
                            />
                          }
                        />
                      )}
                    </Stack>
                  </>
                )}
                {requestError && <Alert severity="error">{requestError}</Alert>}
                {campaignId && (
                  <>
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'right' }}>
                      <LoadingButton
                        style={{ width: '30%', marginTop: 15 }}
                        onClick={submit}
                        variant="contained"
                        disabled={
                          disableSubmit ||
                          submitting ||
                          (!titlesListRaw && !campaignId) ||
                          !campaignName ||
                          !selectedProjectId
                        }
                        loading={submitting}
                      >
                        Update campaign
                      </LoadingButton>
                    </Box>
                    <Box sx={{ mt: 1, display: 'flex', justifyContent: 'right' }}>
                      <LoadingButton
                        style={{ width: '30%' }}
                        onClick={cancelCampaign}
                        variant="contained"
                        disabled={
                          cancellingCampaign || (scheduledPostCount == 0 && pausedPostCount == 0)
                        }
                        loading={cancellingCampaign}
                        color="error"
                      >
                        Cancel campaign ({scheduledPostCount + pausedPostCount} post pending)
                      </LoadingButton>
                    </Box>
                    <Box sx={{ mt: 1, display: 'flex', justifyContent: 'right' }}>
                      <LoadingButton
                        style={{ width: '30%' }}
                        onClick={pauseCampaign}
                        variant="contained"
                        disabled={pausingCampaign || scheduledPostCount == 0}
                        loading={pausingCampaign}
                        color="warning"
                      >
                        Pause campaign ({scheduledPostCount} post pending)
                      </LoadingButton>
                    </Box>
                  </>
                )}
                {campaignId ? (
                  <Card>
                    <Typography variant="h6" paragraph mb={2} ml={2} mt={2}>
                      List of posts ({automaticPosts.length})
                    </Typography>
                    <Accordion
                      expanded
                      style={{
                        marginBottom: theme.spacing(2),
                        paddingTop: theme.spacing(1),
                        borderRadius: 16,
                        backgroundColor: 'Highlight',
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<Icon icon={arrowDownFill} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography
                          variant="h6"
                          gutterBottom
                          component="div"
                          style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
                        >
                          <Icon icon={searchFill} style={{ marginRight: theme.spacing(1) }} />{' '}
                          Filter
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                          <TextField
                            select
                            fullWidth
                            label="Status"
                            placeholder="Select status"
                            SelectProps={{ native: true }}
                            InputLabelProps={{ shrink: true }}
                            value={selectedStatusFilter}
                            onChange={(e) => {
                              setSelectedStatusFilter(e.target.value);
                            }}
                          >
                            <option value="">All</option>
                            <option value="10">Scheduled ({scheduledPostCount})</option>
                            <option value="20">Processing ({processingPostCount})</option>
                            <option value="60">Paused ({pausedPostCount})</option>
                            <option value="40">Canceled ({cancelledPostCount})</option>
                            <option value="30">Completed ({completedPostCount})</option>
                          </TextField>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                    <Scrollbar>
                      <TableContainer sx={{ minWidth: 800 }}>
                        <Table style={{ marginTop: 15 }}>
                          <CampaignListHead headLabel={TABLE_HEAD} />
                          <TableBody>
                            {paginatedAutomaticPosts?.map((row) => {
                              const {
                                id,
                                title,
                                category,
                                cost,
                                dontProcessBeforeDateUtc,
                                currentStatusTypeName,
                                currentStatusType,
                                log,
                              } = row;
                              return (
                                <React.Fragment key={id}>
                                  <TableRow hover>
                                    <TableCell>
                                      <IconButton onClick={() => handleOpenedRow(id)}>
                                        <Icon
                                          icon={
                                            openedRows.indexOf(id) > -1
                                              ? arrowDownFill
                                              : arrowRightFill
                                          }
                                          width={24}
                                          height={24}
                                        />
                                      </IconButton>
                                    </TableCell>
                                    <TableCell align="left">{title}</TableCell>
                                    <TableCell align="left">{category}</TableCell>
                                    <TableCell align="left">{cost}</TableCell>
                                    <TableCell align="left">
                                      {fDateTime(dontProcessBeforeDateUtc)}
                                    </TableCell>
                                    <TableCell align="left">
                                      {generateStatusElement(currentStatusTypeName)}
                                    </TableCell>
                                    <TableCell align="right">
                                      {currentStatusType !== 30 && currentStatusType !== 40 && (
                                        <IconButton
                                          title="Cancel post"
                                          color="error"
                                          onClick={() => setCancelPostId(id)}
                                        >
                                          <Icon icon={closeCircleFill} width={24} height={24} />
                                        </IconButton>
                                      )}
                                      {currentStatusType !== 30 &&
                                        currentStatusType !== 40 &&
                                        currentStatusType !== 60 && (
                                          <IconButton
                                            title="Pause post"
                                            color="warning"
                                            onClick={() => setPausePostId(id)}
                                          >
                                            <Icon icon={pauseCircleFill} width={24} height={24} />
                                          </IconButton>
                                        )}
                                      {currentStatusType == 60 && (
                                        <IconButton
                                          title="Resume post"
                                          color="warning"
                                          onClick={() => setResumePostId(id)}
                                        >
                                          <Icon icon={playCircleFill} width={24} height={24} />
                                        </IconButton>
                                      )}
                                      {currentStatusType === 40 && (
                                        <>
                                          <IconButton
                                            title="Retry post"
                                            color="warning"
                                            onClick={() => setRetryPostId(id)}
                                          >
                                            <Icon icon={refreshFill} width={24} height={24} />
                                          </IconButton>
                                          <IconButton
                                            title="Delete post"
                                            color="warning"
                                            onClick={() => setDeletePostId(id)}
                                          >
                                            <Icon icon={trash2Fill} width={24} height={24} />
                                          </IconButton>
                                        </>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      style={{ paddingBottom: 0, paddingTop: 0 }}
                                      colSpan={6}
                                    >
                                      <Collapse
                                        in={openedRows.indexOf(id) > -1}
                                        timeout="auto"
                                        unmountOnExit
                                      >
                                        <Box sx={{ margin: 1 }}>
                                          <Typography variant="h6" gutterBottom component="div">
                                            Log
                                          </Typography>
                                          <Typography
                                            dangerouslySetInnerHTML={{
                                              __html: log.replace(/\n/g, '<br />'),
                                            }}
                                            variant="caption"
                                            gutterBottom
                                            component="p"
                                          ></Typography>
                                        </Box>
                                      </Collapse>
                                    </TableCell>
                                  </TableRow>
                                </React.Fragment>
                              );
                            })}
                            {automaticPosts?.length === 0 && (
                              <TableRow style={{ height: 53 }}>
                                <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                                  This campaign doesn't have any post
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {cancelledPostCount > 0 && (
                        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'right' }}>
                          <LoadingButton
                            style={{ width: '30%', marginTop: 15 }}
                            onClick={retryCancelledPosts}
                            variant="contained"
                            disabled={retryingCancelled}
                            loading={retryingCancelled}
                            color="error"
                          >
                            Retry cancelled posts ({cancelledPostCount})
                          </LoadingButton>
                        </Box>
                      )}
                      {pausedPostCount > 0 && (
                        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'right' }}>
                          <LoadingButton
                            style={{ width: '30%', marginTop: 15 }}
                            onClick={resumePausedPosts}
                            variant="contained"
                            disabled={resumingPaused}
                            loading={resumingPaused}
                            color="warning"
                          >
                            Resume paused posts ({pausedPostCount})
                          </LoadingButton>
                        </Box>
                      )}
                    </Scrollbar>
                    <TablePagination
                      labelDisplayedRows={customLabelDisplayedRows}
                      labelRowsPerPage="Post per page:"
                      rowsPerPageOptions={[10, 50, 100]}
                      component="div"
                      count={filteredAutomaticPosts?.length || 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={(e, page) => setPage(page)}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Card>
                ) : (
                  <>
                    <Typography variant="h6" paragraph mb={0}>
                      List of titles and categories
                    </Typography>
                    <Typography variant="body2" paragraph>
                      Paste here the list of titles, main keyword and categories. The title, main
                      keyword and the category must be separated by a semicolon (;). It must be one
                      title per line. The main keyword is optional but recommended. If the category
                      doesn't exist in your wordpress site, It will be created. If no category is
                      specified, the post will be published/drafted without a category. Each line
                      must include both semicolons even if they are empty.
                      <br />
                      <br />
                      Example:
                      <br />
                      {'  '}How to train a dog;train a dog;Training
                      <br />
                      {'  '}What's the difference between a desktop and a laptop;difference between
                      desktop and laptio;Technology
                      <br />
                      {'  '}How to install Windows 12;;Tutorial
                    </Typography>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        multiline
                        maxRows={9999}
                        label="Titles list"
                        value={titlesListRaw}
                        onChange={(e) => setTitlesListRaw(e.target.value)}
                      />
                    </Stack>
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'right' }}>
                      <LoadingButton
                        style={{ width: '30%', marginTop: 15 }}
                        onClick={submit}
                        variant="contained"
                        disabled={
                          disableSubmit ||
                          submitting ||
                          (!titlesListRaw && !campaignId) ||
                          !campaignName ||
                          !selectedProjectId
                        }
                        loading={submitting}
                      >
                        Create campaign
                      </LoadingButton>
                    </Box>
                  </>
                )}
              </Stack>
            </Card>
          </Grid>
          <Dialog open={cancelPostId !== ''} onClose={() => setCancelPostId('')}>
            <DialogTitle>Warning!</DialogTitle>
            <DialogContent style={{ textAlign: 'center', marginTop: '25px' }}>
              <DialogContentText>Are you sure you want to cancel this post?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button disabled={cancellingPost} onClick={() => setCancelPostId('')}>
                Cancel
              </Button>
              <LoadingButton onClick={handleCancelPost} autoFocus loading={cancellingPost}>
                Confirm
              </LoadingButton>
            </DialogActions>
          </Dialog>
          <Dialog open={retryPostId !== ''} onClose={() => setRetryPostId('')}>
            <DialogTitle>Warning!</DialogTitle>
            <DialogContent style={{ textAlign: 'center', marginTop: '25px' }}>
              <DialogContentText>Are you sure you want to retry this post?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button disabled={retryingPost} onClick={() => setRetryPostId('')}>
                Cancel
              </Button>
              <LoadingButton onClick={handleRetryPost} autoFocus loading={retryingPost}>
                Confirm
              </LoadingButton>
            </DialogActions>
          </Dialog>
          <Dialog open={deletePostId !== ''} onClose={() => setDeletePostId('')}>
            <DialogTitle>Warning!</DialogTitle>
            <DialogContent style={{ textAlign: 'center', marginTop: '25px' }}>
              <DialogContentText>Are you sure you want to delete this post?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button disabled={deletingPost} onClick={() => setDeletePostId('')}>
                Cancel
              </Button>
              <LoadingButton onClick={handleDeletePost} autoFocus loading={deletingPost}>
                Confirm
              </LoadingButton>
            </DialogActions>
          </Dialog>
          <Dialog open={pausePostId !== ''} onClose={() => setPausePostId('')}>
            <DialogTitle>Warning!</DialogTitle>
            <DialogContent style={{ textAlign: 'center', marginTop: '25px' }}>
              <DialogContentText>Are you sure you want to pause this post?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button disabled={pausingPost} onClick={() => setPausePostId('')}>
                Cancel
              </Button>
              <LoadingButton onClick={handlePausePost} autoFocus loading={pausingPost}>
                Confirm
              </LoadingButton>
            </DialogActions>
          </Dialog>
          <Dialog open={resumePostId !== ''} onClose={() => setResumePostId('')}>
            <DialogTitle>Warning!</DialogTitle>
            <DialogContent style={{ textAlign: 'center', marginTop: '25px' }}>
              <DialogContentText>Are you sure you want to resume this post?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button disabled={resumingPost} onClick={() => setResumePostId('')}>
                Cancel
              </Button>
              <LoadingButton onClick={handleResumePost} autoFocus loading={resumingPost}>
                Confirm
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </Grid>
      )}
    </>
  );
}
