// material
import { Container } from '@mui/material';
// components
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import UserForm from './components/UserForm';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import { useParams } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function UserEditPage() {
  const { userId } = useParams();

  return (
    <Page title="Edit user">
      <Container>
        <HeaderBreadcrumbs
          heading="Edit user"
          links={[{ name: 'Users', href: PATH_DASHBOARD.user.list }, { name: 'Edit user' }]}
        />
        <UserForm userId={userId} />
      </Container>
    </Page>
  );
}
