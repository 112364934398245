import { Box, Modal, Stack, TextField, Typography } from '@mui/material';

// ----------------------------------------------------------------------

type BadTitleWordsModalProps = {
  openModal: boolean;
  setOpenModal: any;
  badTitleWords: string;
};

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BadTitleWordsModal({
  openModal,
  setOpenModal,
  badTitleWords,
}: BadTitleWordsModalProps) {
  return (
    <>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Bad title words
          </Typography>
          <Typography id="modal-modal-title" variant="caption" component="p" mt={1}>
            List of words considered as "bad" for titles. The preparation tool will
            remove all phrases in this list. You can't edit this file
            from here.
          </Typography>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }} mt={2}>
            <TextField fullWidth multiline rows={10} label="Bad title words" value={badTitleWords} />
          </Stack>
        </Box>
      </Modal>
    </>
  );
}
