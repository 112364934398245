import { createContext, ReactNode, useEffect, useReducer } from 'react';
import { lskey_refreshToken, lskey_token, lskey_user } from '../utils/lsKeys';
import { isValidToken, setSession, decodeToken } from '../utils/jwt';
import { ActionMap, AuthUser } from './../types/authentication';
import { IAppUser, IAuthState, IJWTContext, IToken } from './../interfaces/authentication.interface';
import axiosInstance from '../utils/axios';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER'
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: IAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const JWTReducer = (state: IAuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };

    default:
      return state;
  }
};

const AuthContext = createContext<IJWTContext | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const refreshToken = window.localStorage.getItem(lskey_refreshToken);
        const accessToken = window.localStorage.getItem(lskey_token);
        if (accessToken && refreshToken && isValidToken(refreshToken)) {
          setSession(accessToken);

          const user = await axiosInstance.get<IAppUser>('/Auth/GetAccountData');
          localStorage.setItem(lskey_user, JSON.stringify(user.data));
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user: user.data
            }
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string, remember: boolean) => {
    const body = {
      email: email,
      password: password,
      rememberMe: remember,
      device: {
        uuid: 'react'
      }
    };
    const response = await axiosInstance.post<IToken>('/auth/login', body);
    setSession(response.data.accessToken);
    const user: IAppUser = decodeToken(response.data.accessToken);
    localStorage.setItem(lskey_refreshToken, remember ? response.data.refreshToken : '');
    localStorage.setItem(lskey_user, JSON.stringify(user));
    dispatch({
      type: Types.Login,
      payload: {
        user
      }
    });
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    const response = await axiosInstance.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: Types.Register,
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  const passwordRecovery = (email: string) => {
    return axiosInstance.post('/auth/passwordrecovery', { email: email });
  };

  const resetPassword = (
    email: string,
    password: string,
    confirmPassword: string,
    code: string
  ) => {
    return axiosInstance.post('/auth/resetPassword', {
      email: email,
      password: password,
      confirmPassword: confirmPassword,
      code: code
    });
  };

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        passwordRecovery,
        resetPassword,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
