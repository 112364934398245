import { IPage, IPageList } from 'src/interfaces/course.interface';
import axiosInstance from 'src/utils/axios';

const getPagesUrl: string = '/Page/GetAll';
const deleteProjectUrl: string = '/Page/Delete?id=';
const getPageByIdUrl: string = '/Page/getById?id=';
const createPageUrl: string = '/Page/Create';
const updatePageUrl: string = '/Page/Update';
const uploadPictureUrl: string = '/Page/uploadPicture';

const PageService = {
  getPages: (page: number, elementsPerPage: number, sectionId: string) =>
    axiosInstance.get<IPageList>(
      `${getPagesUrl}?page=${page}&pageSize=${elementsPerPage}&sectionId=${sectionId}`
    ),
  deletePage: (pageId: string) => axiosInstance.delete(deleteProjectUrl + pageId),
  getPageById: (pageId: string) => axiosInstance.get<IPage>(getPageByIdUrl + pageId),
  createPage: (page: FormData) => axiosInstance.post(createPageUrl, page),
  updatePage: (page: FormData) => axiosInstance.put(updatePageUrl, page),
  uploadPicture: (form: FormData) => axiosInstance.post<string>(uploadPictureUrl, form),
};

export default PageService;
