import { useState } from 'react';
import { Button, Container } from '@mui/material';
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../routes/paths';
import QuestionForm from './components/QuestionForm';
import { useNavigate, useParams } from 'react-router-dom';

import trash2Fill from '@iconify-icons/eva/trash-2-fill';
import { Icon } from '@iconify/react';
import DeleteElementModal from 'src/components/DeleteElementModal';
import QuestionService from 'src/services/question.service';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

export default function QuestionEditPage() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { questionId } = useParams();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);
  const [deletingQuestion, setDeletingQuestion] = useState<boolean>(false);

  const handleCloseDeleteConfirmationModal = () => {
    setOpenDeleteConfirmation(false);
  };

  const handleOpenDeleteConfirmationModal = () => {
    setOpenDeleteConfirmation(true);
  };

  const deleteQuestion = () => {
    setDeletingQuestion(true);
    QuestionService.deleteQuestion(questionId || '')
      .then(() => {
        enqueueSnackbar('The question has been deleted successfully.', {
          variant: 'success',
        });
        handleCloseDeleteConfirmationModal();
        navigate(PATH_DASHBOARD.question.list);
      })
      .catch((error) => {
        enqueueSnackbar("We weren't able to delete the question. Please notify an administrator.", {
          variant: 'error',
        });
      })
      .finally(() => setDeletingQuestion(false));
  };

  return (
    <Page title="Edit question">
      <Container>
        <HeaderBreadcrumbs
          heading="Edit question"
          links={[
            { name: 'Questions', href: PATH_DASHBOARD.question.list },
            { name: 'Edit question' },
          ]}
          action={
            <Button
              onClick={handleOpenDeleteConfirmationModal}
              color="error"
              variant="contained"
              startIcon={<Icon icon={trash2Fill} />}
            >
              Delete question
            </Button>
          }
        />
        <QuestionForm questionId={questionId} />
      </Container>
      <DeleteElementModal
        openDeleteConfirmation={openDeleteConfirmation}
        handleCloseDeleteConfirmationModal={handleCloseDeleteConfirmationModal}
        deletingElement={deletingQuestion}
        deleteElementHandler={deleteQuestion}
      />
    </Page>
  );
}
