import { ISelectList } from 'src/interfaces/common.interface';
import { ICampaign, ICampaignList } from 'src/interfaces/campaign.interface';
import axiosInstance from 'src/utils/axios';
import { IGeneratedPost } from 'src/interfaces/ai.interface';

const getCampaignsUrl: string = '/AutomaticPostCampaign/GetAll';
const createCampaignUrl: string = '/AutomaticPostCampaign/Create';
const updateCampaignUrl: string = '/AutomaticPostCampaign/Update';
const getCampaignByIdUrl: string = '/AutomaticPostCampaign/GetById?id=';
const deleteCampaignUrl: string = '/AutomaticPostCampaign/Delete?id=';
const getCampaignSelectListUrl: string = '/AutomaticPostCampaign/getCampaignSelectList';
const retryAllCancelledPostsUrl: string = '/AutomaticPostCampaign/retryAllCancelledPosts?id=';
const cancelPostUrl: string = '/AutomaticPostCampaign/cancelPost?id=';
const retryCancelledPostUrl: string = '/AutomaticPostCampaign/retryCancelledPost?id=';
const deletePostUrl: string = '/AutomaticPostCampaign/deletePost?id=';
const cancelCampaignUrl: string = '/AutomaticPostCampaign/cancelCampaign?id=';
const pausePostUrl: string = '/AutomaticPostCampaign/pausePost?id=';
const resumePausedPostUrl: string = '/AutomaticPostCampaign/resumePausedPost?id=';
const pauseCampaignUrl: string = '/AutomaticPostCampaign/pauseCampaign?id=';
const resumeAllPausedPostsUrl: string = '/AutomaticPostCampaign/resumeAllPausedPosts?id=';
const rewriteTitlesUrl: string = '/AutomaticPostCampaign/rewriteTitles';
const getCategoryIdeasUrl: string = '/AutomaticPostCampaign/getCategoryIdeas';
const assignCategoriesToTitlesUrl: string = '/AutomaticPostCampaign/assignCategoriesToTitles';

const CampaignService = {
  getCampaigns: (page: number, elementsPerPage: number) =>
    axiosInstance.get<ICampaignList>(`${getCampaignsUrl}?page=${page}&pageSize=${elementsPerPage}`),

  createCampaign: (campaign: ICampaign) => axiosInstance.post(createCampaignUrl, campaign),

  updateCampaign: (campaign: ICampaign) => axiosInstance.put(updateCampaignUrl, campaign),

  getCampaignById: (campaignId: string) =>
    axiosInstance.get<ICampaign>(getCampaignByIdUrl + campaignId),

  deleteCampaign: (campaignId: string) => axiosInstance.delete(deleteCampaignUrl + campaignId),

  getCampaignSelectList: () => axiosInstance.get<ISelectList[]>(getCampaignSelectListUrl),

  retryAllCancelledPosts: (campaignId: string) =>
    axiosInstance.post(retryAllCancelledPostsUrl + campaignId),

  resumeAllPausedPosts: (campaignId: string) =>
    axiosInstance.post(resumeAllPausedPostsUrl + campaignId),

  cancelCampaign: (campaignId: string) => axiosInstance.get(cancelCampaignUrl + campaignId),

  pauseCampaign: (campaignId: string) => axiosInstance.get(pauseCampaignUrl + campaignId),

  cancelPost: (postId: string) => axiosInstance.get(cancelPostUrl + postId),

  retryCancelledPost: (postId: string) => axiosInstance.get(retryCancelledPostUrl + postId),

  deletePost: (postId: string) => axiosInstance.delete(deletePostUrl + postId),

  pausePost: (postId: string) => axiosInstance.get(pausePostUrl + postId),

  resumePausedPost: (postId: string) => axiosInstance.get(resumePausedPostUrl + postId),

  rewriteTitles: (titles: string[]) => axiosInstance.post<IGeneratedPost>(rewriteTitlesUrl, titles),

  getCategoryIdeas: (titles: string[]) => axiosInstance.post<string>(getCategoryIdeasUrl, titles),

  assignCategoriesToTitles: (titles: string[], categories: string[]) =>
    axiosInstance.post<IGeneratedPost>(assignCategoriesToTitlesUrl, { titles, categories }),
};

export default CampaignService;
