import jwtDecode from 'jwt-decode';
import axiosInstance from './axios';
import { AuthUser } from '../types/authentication';
import { lskey_token } from './lsKeys';

// ----------------------------------------------------------------------

const isValidToken = (refreshToken: string) => {
  if (!refreshToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(refreshToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem(lskey_token, accessToken);
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem(lskey_token);
    delete axiosInstance.defaults.headers.common.Authorization;
  }
};

const decodeToken = (accessToken: string) => {
  const claims = jwtDecode(accessToken) as any;

  const user: AuthUser = {
    firstName: claims.firstName,
    lastName: claims.lastName,
    email: claims.sub,
    id: claims.user_id,
    roles: claims.roles.split(',')
  };

  return user;
};

export { isValidToken, setSession, decodeToken };
